import { useMemo } from "react"

export const useContainerBackgroundAttachment = () => {

    return useMemo(() => {
        return [
            { value: 'scroll', label: 'Scroll' },
            { value: 'fixed', label: 'Fixé' },
            { value: 'local', label: 'Locale' },
            { value: 'initial', label: 'Initial' },
            { value: 'inherit', label: 'Hérité' },
            { value: 'unset', label: 'Non défini' },
        ]
    }, [])
}

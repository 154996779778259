import {makeStyles, useTheme} from "@mui/styles";
import {Box, Grid, GridProps, Stack, StackProps, Theme, Typography, useMediaQuery} from "@mui/material";
import {useResponsiveContainerSize} from "../../../hooks/design/useResponsiveContainerSize";
import {usePixelToRem} from "../../../hooks/design/usePixelToRem";
import {COLORS} from "../../../config/Colors";
import MobileContainer from "../../home/components/MobileContainer";
import {useTranslations} from "@ephatta/components";
import {ReactElement} from "react";
import {ResponsiveLogoAsTitle} from "../../../components/icons/logo/responsive-logo";
import { CustomStandardButton } from "../../../components/standard/button/CustomStandardButton";
import LinkCustomed from "../../../components/standard/link/LinkCustomed";
import { ArrowLeft, ArrowLeftWithNoTail } from "../../../components/icons/basic/arrows";


type UserContainerRightLeftProps = {
    gridLeftProps?: GridProps,
    stackLeftProps?: StackProps,
    showWelcomeText?: boolean,
    title?: string,
    description?: ReactElement,
    bottomText?: string,
    children?: ReactElement,
    previousPath?: string,
    showBackButton?: boolean
}

const UserContainerRightLeft = (props: UserContainerRightLeftProps) => {
    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isLowerThan1273px = useMediaQuery("(max-width:1273px)");
    // ===== END MEDIA QUERY =====

    const { fullHeightMinusHeader } = useResponsiveContainerSize();
    const { pixelToRem } = usePixelToRem();
    const classes = useStyle()

    const [
        WELCOME_TEXT,
        WELCOME_TEXT_DESCRIPTION,
        BACK_BUTTON_LABEL
    ] = useTranslations(i18nKeys)


    return (
        <>
            <>
                <Box
                >
                    <Grid
                        direction={'row'}
                        alignItems={'stretch'}
                        justifyContent={'space-between'}
                        container
                    >
                        {
                            !isSmallDown &&
                            <Grid item lg={6} md={6} sm={2} xs={0} sx={{
                                background: 'url("/assets/img/user/left-bg.png")',
                                height: fullHeightMinusHeader,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}/>
                        }

                        <Grid lg={6}
                              md={6}
                              sm={10}
                              container
                              justifyContent={'center'}
                              alignItems={'flex-start'}
                              sx={{
                                  // minHeight: fullHeightMinusHeader,
                                  // height: isMediumDown ? 'auto' : fullHeightMinusHeader,
                                  height: isSmallDown ? 'auto' : fullHeightMinusHeader,
                                  overflowY: 'auto',
                              }}
                              position="relative"
                              {...props.gridLeftProps}
                        >
                            {
                                !isMediumDown && props.showBackButton &&
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: pixelToRem(21.5),
                                        left: pixelToRem(22.8),
                                    }}
                                >
                                    <LinkCustomed
                                        to={props.previousPath || '#'}
                                    >
                                        <CustomStandardButton
                                            height={{desktop: pixelToRem(40), mobile: pixelToRem(40)}}
                                            width={{desktop: pixelToRem(152), mobile: pixelToRem(152)}}
                                            startIcon={{
                                                svgIcon: <ArrowLeft/>,
                                                width: {desktop: pixelToRem(22.22), mobile: pixelToRem(22.22)},
                                                height: {desktop: pixelToRem(22.22), mobile: pixelToRem(22.22)},
                                                iconSpacing: {desktop: pixelToRem(23.89), mobile: pixelToRem(23.89)},
                                            }}
                                            buttonColor={{
                                                textColor: {
                                                    normal: COLORS.chinaRose,
                                                    hover: COLORS.white,
                                                },
                                                bg: {
                                                    normal: COLORS.white,
                                                    hover: COLORS.chinaRose,
                                                },
                                                borderedOnNormal: true,
                                            }}
                                            fontSize={{desktop: pixelToRem(16), mobile: pixelToRem(16)}}
                                            fontWeight={{desktop: '700', mobile: '700'}}
                                            textTransform={'none'}
                                        >
                                            { BACK_BUTTON_LABEL }
                                        </CustomStandardButton>
                                    </LinkCustomed>
                                </Box>
                            }
                             {
                                isMediumDown && props.showBackButton &&
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: pixelToRem(12.59),
                                        left: pixelToRem(12),
                                        zIndex: 999
                                    }}
                                >
                                    <LinkCustomed to={props.previousPath || '#'}>
                                        <ArrowLeftWithNoTail/>
                                    </LinkCustomed>
                                </Box>
                            }
                            <Grid item
                                  lg={isLowerThan1273px ? 8 : 7} md={10} sm={11} xs={12}
                                  paddingTop={ isSmallDown ? pixelToRem(14.79) : pixelToRem(33.67)}
                                  paddingBottom={isSmallDown ? pixelToRem(44) : pixelToRem(62)}
                                  position="relative"
                            >
                                <MobileContainer>
                                    <Box >
                                        <Stack
                                            direction={'column'}
                                            spacing={isSmallDown ? pixelToRem(25) : pixelToRem(30)}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            {...props.stackLeftProps}
                                        >
                                            {/*    Logo   */}
                                            <Box mt={props.showBackButton ? 3.40 : 2}>
                                                <ResponsiveLogoAsTitle/>
                                            </Box>
                                            {/*  Welcome Text  */}
                                            {
                                                props.showWelcomeText &&
                                                <Box>
                                                    <Stack
                                                        direction={'column'}
                                                        spacing={isSmallDown ? pixelToRem(9) : pixelToRem(15)}
                                                    >
                                                        <Typography
                                                            fontFamily={'Ubuntu'}
                                                            fontSize={isSmallDown ? pixelToRem(14) : pixelToRem(16)}
                                                            fontWeight={'500'}
                                                            textTransform={'initial'}
                                                            align={'center'}
                                                        >
                                                            { props.title || WELCOME_TEXT }
                                                        </Typography>
                                                        <Typography
                                                            variant={isSmallDown ? 'body2' : 'body1'}
                                                            align={'center'}
                                                            color={COLORS.philippineGray}
                                                            lineHeight={pixelToRem(18)}
                                                            sx={{
                                                                maxWidth: isSmallDown ? (props.description ? undefined : pixelToRem(250)) : undefined,
                                                            }}
                                                        >
                                                            { props.description || WELCOME_TEXT_DESCRIPTION }
                                                        </Typography>
                                                    </Stack>
                                                </Box>

                                            }
                                            { props.children }
                                        </Stack>
                                    </Box>
                                </MobileContainer>
                                
                            </Grid>
                            <Box
                                marginTop={isSmallDown ? pixelToRem(25) : pixelToRem(30)}
                                className={classes.bottomText}
                            >
                                <Typography
                                    variant={isSmallDown ? 'body2' : 'body1'}
                                    fontWeight={'bold'}
                                    color={COLORS.gray}
                                    lineHeight={isSmallDown ? pixelToRem(18) : pixelToRem(18)}
                                >
                                    { props.bottomText }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
        </>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    bottomText:{
        width:'100%',
        paddingLeft: '0.938rem',
        marginBottom: '0.625rem',
        [theme.breakpoints.down('sm')]:{
            paddingRight: '0.938rem'
        },
    }
}))

UserContainerRightLeft.defaultProps = {
    showWelcomeText: true,
}

const i18nKeys = [
    "Login.Page.Title.Welcome.In.CabinetSecret",
    "Login.Page.Title.Welcome.In.CabinetSecret.Description",
    "Client.ContainerWithBackButton.Back.Button"
]


export default UserContainerRightLeft;



import React from 'react'
import { usePixelToRem } from '../../../hooks/design/usePixelToRem';
import { CustomStandardButton } from './CustomStandardButton'
import {COLORS} from '../../../config/Colors'
import { ArrowLeft } from '../../icons/basic/arrows';

const FallbackButton = ({...props}) => {
    const { pixelToRem } = usePixelToRem();
  return (
    <CustomStandardButton
                        height={{desktop: pixelToRem(40), mobile: 'auto'}}
                        width={{desktop: pixelToRem(152), mobile: 'auto'}}
                        variant={'contained'}
                        fontSize={{desktop: pixelToRem(16), mobile: pixelToRem(12)}}
                        startIcon={{
                            iconSpacing: {desktop: pixelToRem(15.89), mobile: pixelToRem(12)},
                            svgIcon: <ArrowLeft/>,
                            height: {desktop: pixelToRem(22.22), mobile: pixelToRem(17)},
                            width: {desktop: pixelToRem(22.22), mobile: pixelToRem(20)}
                        }}
                        buttonColor={{
                            textColor: {
                                normal: COLORS.chinaRose,
                                hover: COLORS.white,
                            },
                            bg: {
                                normal: COLORS.white,
                                hover: COLORS.chinaRose,
                            },
                            borderedOnHover: true,
                            borderedOnNormal: true
                        }}
                        fontWeight={{
                            desktop:'600',
                            mobile:'600'
                        }}
                        {...props}
                        sx={{
                            textTransform:'normal',
                            ...props.sx
                        }}
                        
                    >
                        {props.label || "Retour"}
     </CustomStandardButton>
  )
}

export default FallbackButton
import { TCustomerUpdate } from "@ephatta/services"
import { useEphattaMutation } from "hooks/utils"
import { useCustomerServices } from "./useCustomerServices"


export const useCustomerUpdate = () => {
    const services = useCustomerServices()
    return useEphattaMutation(
        ({payload, userId}: useCustomerUpdateTypes) => services.update(payload, userId)
    )
}

type useCustomerUpdateTypes = {
    payload: TCustomerUpdate, userId:string
}
import {COLORS} from "../../../config/Colors";
import {CustomStandardButton, CustomStandardButtonProps} from "../../../components/standard/button/CustomStandardButton";
import {usePixelToRem} from "../../../hooks/design/usePixelToRem";

const GoldenLargeButton = (props: Partial<CustomStandardButtonProps>) => {

    const { pixelToRem } = usePixelToRem();

    return (
        <CustomStandardButton
            height={{
                desktop: pixelToRem(48),
                mobile: pixelToRem(37),
            }}
            buttonColor={{
                textColor: {
                    normal: COLORS.white,
                    hover: COLORS.aztecGold
                },
                bg: {
                    normal: COLORS.aztecGold,
                    hover: COLORS.white
                },
                borderedOnHover: true
            }}
            {...props}
            fontWeight={{ desktop: '400', mobile: '400'}}
            fontSize={{ desktop: pixelToRem(16), mobile: pixelToRem(14)}}
            variant={'contained'}
        >
            { props.children }
        </CustomStandardButton>
    )
};

export default GoldenLargeButton;
import { useDebounce } from "@ephatta/components"
import { Box, IconButton, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export const MainLock = (props: Props) => {

    const classes = useStyle()
    const [zoom, setZoom] = useState(false)
    const timeoutRef = useRef<NodeJS.Timeout>()

    const timeout = useMemo(() => parseInt(process.env.REACT_APP_MAIN_LOCK_ZOOM_IN_TIMEOUT || '3000'), [])

    useEffect(() => {
        timeoutRef.current = setTimeout(() => zoomIn(), timeout)
    }, [])

    useEffect(() => {
        if (zoom) {
            setTimeout(() => props.onZoomInFinished?.(), zoomInTransition)
        }
    }, [zoom])

    const zoomIn = useCallback(() => {
        setZoom(true)
        clearTimeout(timeoutRef.current)
    }, [setZoom])

    return (
        <Box className={`${classes.mainLockContainer} ${zoom && classes.mainLockContainerZoomIn}`}>
            <img src="/assets/img/main-lock-bg.jpg" />
            <IconButton className={classes.mainLockContainerZoomInButton} onClick={() => zoomIn()}>
                <img src="/assets/img/logo-lock.svg"  />
            </IconButton>
        </Box>
    )
}

const zoomInTransition = 1000

type Props = {
    onZoomInFinished: () => void
}

const useStyle = makeStyles((theme: Theme) => ({
    mainLockContainer: {
        position: "fixed",
        top: "0", 
        left: "0", 
        right: "0",
        bottom: '0',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > img': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    mainLockContainerZoomInButton: {
        '&.MuiIconButton-root': {
            margin: '60px 0 0 60px'
        }
    },
    mainLockContainerZoomIn: {
        '& img': {
            transition: `all ${zoomInTransition}ms`,
            opacity: '0',
            transform: 'scale(20)',
            zIndex: '-1'
        }
    }
}))

import _ from "lodash";
import { useRef } from "react";

export const useDomSelection = () => {

    const range = useRef<Range | null>()

    const saveSelection = () => {
        let _range: Range | null = null
        if (window.getSelection) {
            const sel = window.getSelection();
            if (sel?.getRangeAt && sel?.rangeCount) {
                _range = sel.getRangeAt(0);
            }
        }
        range.current = _range
    }
    
    const restoreSelection = () => {
        if (range.current) {
            if (window.getSelection) {
                const sel = window.getSelection();
                sel?.removeAllRanges();
                sel?.addRange(range.current);
            } 

        }
    }

    const hasSelection = () => {
        if (window.getSelection) {
            const sel = window.getSelection();
            return !_.isEmpty(sel?.toString())
        }
        return false
    }

    return {
        saveSelection,
        restoreSelection,
        hasSelection
    }
}

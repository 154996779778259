import { Button, Popper, PopperProps, useTheme } from "@mui/material"
import { SketchPicker, SketchPickerProps } from "react-color"
import React, { useMemo, useState } from "react"

export const ColorPicker = ({ PopperProps, ...props }: ColorPickerProps) => {

    const theme = useTheme()
    const pallete: Array<string> = useMemo(() => {
        return [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.error.main,
            theme.palette.success.main,
            theme.palette.info.main,
            theme.palette.text.primary,
            theme.palette.text.secondary,
            theme.palette.text.disabled,
            theme.palette.primary.light,
            theme.palette.secondary.light,
            theme.palette.error.light,
            theme.palette.success.light,
            theme.palette.info.light,
        ]
    }, [])

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.target as HTMLButtonElement);
        setOpen((v) => !v);
    }

    return (
        <>
            {
                props.button && React.cloneElement(
                    props.button,
                    {
                        style: { 
                            backgroundColor: props.color?.toString() 
                        },
                        onClick: handleClick
                    }
                )
            }

            {
                !props.button &&
                <Button style={{ backgroundColor: props.color?.toString() }} onClick={handleClick}>
                    Choisir une couleur
                </Button>
            }
            
            
            
                <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 999 }}  {...PopperProps}>
                    <SketchPicker 
                        presetColors={pallete}
                        {...props}
                        />
                </Popper>
           
        </>

    )
}

export type ColorPickerProps = SketchPickerProps & {
    PopperProps?: PopperProps
    button?: React.ReactElement
}

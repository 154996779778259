import { useMemo } from "react"

export const useContainerBackgroundSize = () => {

    return useMemo(() => {
        return [
            { value: 'auto', label: 'Automatique' },
            { value: 'cover', label: 'Couvert' },
            { value: 'contain', label: 'Contenu' },
            { value: 'initial', label: 'Initiale' },
            { value: 'inherit', label: 'Hérité' },
            { value: 'unset', label: 'Non défini' },
        ]
    }, [])
}

import { YouTube } from "@mui/icons-material"
import React, { useMemo } from "react"
import { VideoYoutube } from "../components/elements/VideoYoutube"
import { ToolboxItemProps } from "./useToolbox"

export const useToolboxVideos = () => {

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Youtube',
            icon: <YouTube />,
            element: VideoYoutube,
        },
    ]), [])
}


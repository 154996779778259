import { AppConfig } from '@ephatta/services'
import { DefaultOptions } from 'react-query'

export const config: ReactAppConfig = {
  stripeKey: process.env.REACT_APP_STRIPE_API_KEY || '',
  apiUrl: process.env.REACT_APP_API_URL || '',
  culture: process.env.REACT_APP_CULTURE || 'fr',
  appName: process.env.REACT_APP_NAME || 'Cabinet-secret',
  docFileUrl: process.env.REACT_APP_DOC_FILE_URL,
  env: process.env.NODE_ENV!,
  logger: {
    level: {
      debug: Boolean(process.env.REACT_APP_LOGGER_LEVEL_DEBUG),
      warning: Boolean(process.env.REACT_APP_LOGGER_LEVEL_WARNING),
      error: Boolean(process.env.REACT_APP_LOGGER_LEVEL_ERROR),
      state: Boolean(process.env.REACT_APP_LOGGER_LEVEL_STATE)
    }
  },
  prod: process.env.NODE_ENV === 'production',
  query: {
    queries: {
      staleTime: (process.env.REACT_APP_STALE_TIME as any) || undefined,
      cacheTime: (process.env.REACT_APP_CACHE_TIME as any) || undefined,
      refetchInterval:
        (process.env.REACT_APP_REFETCH_INTERVAL as any) || undefined,
      refetchOnWindowFocus:
        (process.env.REACT_APP_REFETCH_ON_WINDOW_FOCUS as any) || undefined,
      refetchOnMount:
        (process.env.REACT_APP_REFETCH_ON_MOUNT as any) || undefined
    }
  },
  mangoApiKey: process.env.REACT_APP_MONGOPAY_API_KEY,
  mangoApiUrl: process.env.REACT_APP_MONGOPAY_API_URL,
  mangoClientId: process.env.REACT_APP_MONGOPAY_CLIENT_ID
}

type ReactAppConfig = AppConfig & {
  query: DefaultOptions
  stripeKey: string
}

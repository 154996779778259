import {CabinetSecretLogoWithDefaultText} from "./cabinet-secret-logo-icon";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/styles";

export const ResponsiveLogoAsTitle = () => {
    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    return (
        <CabinetSecretLogoWithDefaultText
            logoType={'light'}
            width={isSmallDown ? 105 : 140 }
            height={isSmallDown ? 107 : 143 }
        />
    )
}
import '@ephatta/components/dist/index.css'
import { Root } from '@ephatta/components'
import Routes from './pages/Routes'
import { Theme } from './config/Theme'
import { useEffect } from "react";
import firebaseApp from './firebase';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const App = () => {
  useEffect(() => {
    const messaging = getMessaging(firebaseApp);
    getToken(messaging, {
      vapidKey:
        "BFz-CK7Iw4szokj_Xm8TboyPH5tG9hIjjN48cFYhxo0vayGn0E_iExTIUtefYD3bEh60rU7wwz4CXi72mdFnX3U",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("Firebase Token", currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // ...
    });
  }, []);

  return (
    <Root
      theme={Theme}
      loginPath="/login"
      initialPath="/home"
      routes={Routes}
      loggedRoutes={loggedRoutes}
      loggedUnAuthorizedRootes={loggedUnAuthorizedRootes} />
  )
}

const loggedRoutes: Array<string> = [
  '/myprofile'
]

const loggedUnAuthorizedRootes = [
  '/login',
  '/forgot-password'
]

export default App;



import React from "react";
import { useNode } from "@craftjs/core";
import { Card as MUICards, CardContent, CardActions } from "@mui/material"
import { CardProperty } from "../property/CardProperty";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container"
import { Text } from "./Text";
import { Button, ButtonDefaultProps, ButtonProps } from "./Button";
import { BreakpointProperty } from "../property/BreakpointProperty";

export const Card = (props: CardProps) => {

    const {
        setProp,
    } = useNode();

    return (
        <Container container={props.container}>
            <MUICards variant="outlined" sx={{ border: 'none' }}>
                <CardContent>
                    <Text draggable={false} text={props.title} variant="h4" fontWeight="bold" onChange={(value) => setProp((props: any) => props.title = value)} />
                    <Text draggable={false} text={props.description} variant="body1" fontSize={14} onChange={(value) => setProp((props: any) => props.description = value)} />
                </CardContent>
                {
                    props.button &&
                    <CardActions>
                        <Button
                            button={props.button}
                            />
                    </CardActions>
                }
            </MUICards>
        </Container>
    )
}

Card.craft = {
    displayName: 'Card',
    props: {
        title: 'Titre',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        button: ButtonDefaultProps,
        container: ContainerDefaultProps
    },
    related: {
        toolbar: CardProperty,
        breakpoint: BreakpointProperty
    },
};

export type CardProps = {
    title: string
    description: string
    button?: ButtonProps
    container?: ContainerProps['container']
}

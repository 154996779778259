import { TCustomerSubscription } from "@ephatta/services"
import { useEphattaMutation } from "hooks/utils"
import { useCustomerServices } from "./useCustomerServices"


export const useCustomerCreateSubscription = () => {
    const services = useCustomerServices()
    return useEphattaMutation(
        (payload: TCustomerSubscription) => services.createSubscription(payload)
    )
}

import { FormatColorText, List as ListIcon, SmartButton, ViewHeadline } from "@mui/icons-material"
import React, { useMemo } from "react"
import { Button } from "../components/elements/Button"
import { List } from "../components/elements/List"
import { Text } from "../components/elements/Text"
import { ToolboxItemProps } from "./useToolbox"

export const useToolbarTitles = () => {

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Titre',
            icon: <FormatColorText />,
            element: Text,
            elementProps: { text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod' }
        },
        {
            label: 'Paragraphe',
            icon: <ViewHeadline />,
            element: Text,
            elementProps: { 
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
                variant: 'body1',
                fontWeight: 'normal'
            }
        },
        {
            label: 'Bouton',
            icon: <SmartButton />,
            element: Button,
            elementProps: { 
                label: 'Bouton',
            }
        },
        {
            label: 'Liste',
            icon: <ListIcon />,
            element: List,
            elementProps: { 
                text: '<li>Lorem ipsum dolor sit amet</li>'
            }
        },
    ]), [])
}


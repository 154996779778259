import { ForceReloadEnv } from './ForceReloadEnvAtom';
import { LoginService, TIdentity } from "@ephatta/services";
import { selector } from "recoil";
import { config } from "../../config";

const loginService = new LoginService(config)

export const Environment = selector<TIdentity | null>({
    key: 'User.Environment',
    get: async ({ get }) => {
        try {
            get(ForceReloadEnv); // make Environment selector dependent
            const env = await loginService.identity()
            return env
        } catch (e) {
            console.log('error', e)
            return null
        }
    }
})

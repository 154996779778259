import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint"
import _ from "lodash"
import { useCallback } from "react"

export const useContainerSwiperChangeHandler = () => {
    const { setValue } = useBreakpoint()

    return useCallback((key: string, value?: any) => {
        setValue((props: any) => {
            _.set(props, `swiper.${key}`, value)
            return props
        })
    }, [setValue])
}

import {Link, LinkProps} from "react-router-dom";

const LinkCustomed = (props: LinkProps) => {
    return (
        <Link
        {...props}
        style={{
            textDecoration: 'none',
            ...props.style            
        }}
    >
        {props.children}
    </Link>)
}

export default LinkCustomed;

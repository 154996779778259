import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { useTextVariants } from "../../hooks/useTextVariants";
import { PropertyItemTitle } from "./PropertyItemTitle"
import { ColorPicker } from "components/form";
import { ContainerProperty, ContainerPropertyProps } from "./ContainerProperty";
import { useTextAligns } from "components/craft-editor/hooks/useTextAligns";
import { PropertyContainer } from "./PropertyContainer";
import { FormatBold, FormatColorText, FormatItalic, FormatUnderlined, Link } from "@mui/icons-material";
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint";
import { LinkDialog, LinkDialogValue } from "../dialog/LinkDialog";
import { useDomSelection } from "components/craft-editor/hooks/useDomSelection";
import { ColorResult } from "react-color";

export const TextProperty = (props: TextPropertyProps) => {

    const variants = useTextVariants()
    const textAligns = useTextAligns()
    const [openLinkDialog, setOpenLinkDialog] = useState(false)
    const [, setRefresh] = useState(false)
    const [color, setColor] = useState(document.queryCommandValue('foreColor'))
    const { getValue, setValue } = useBreakpoint()
    const { saveSelection, restoreSelection, hasSelection } = useDomSelection()
    const [linkValue, setLinkValue] = useState<LinkDialogValue>({ href: '', title: '' })

    useEffect(() => {
        document.addEventListener('selectionchange', refresh)
        return () => {
            document.addEventListener('selectionchange', refresh)
        }
    }, [])

    const refresh = useCallback(() => {
        setColor(document.queryCommandValue('foreColor'))
        const link = document.getSelection()?.anchorNode?.parentNode as HTMLLinkElement
        if (link?.tagName === 'A') {
            setLinkValue({
                href: link.href,
                title: link.title,
                targetBlank: link.target === '_blank'
            })
        } else {
            // setLinkValue({ href: '', title: '', targetBlank: false })
        }
        setRefresh((v) => !v)
    }, [])

    const handleCommandClick = (e: React.MouseEvent<HTMLButtonElement>, command: string, value?: string) => {
        if (command === 'createLink') {
            saveSelection()
            setOpenLinkDialog(true)
            return
        }

        document.execCommand(command, false, value)
        e.preventDefault()
        e.stopPropagation()
    }

    const checkActive = (command: string) => {
        return document.queryCommandState(command)
    } 

    const handleTextChange = useCallback((key: string, value?: any) => {
        setValue((props: any) => {    
            props[key] = value
            return props
        })
        
    }, [])

    const handleLinkSubmit = (value: LinkDialogValue) => {
        // Restore old selection
        restoreSelection()
        setOpenLinkDialog(false)

        // suround link
        document.execCommand('insertHTML', false, `<a 
                href="${value.href}" 
                title="${value.title}" 
                target="${value.targetBlank ? '_blank' : ''}">${document.getSelection()?.toString()}</a>`)
    }

    const handleColorChange = (color: ColorResult, e: React.ChangeEvent<HTMLInputElement>) => {
        setColor(`${color.hex}`)
        if (hasSelection()) {
            handleCommandClick(e as any, 'foreColor', `${color.hex}`)
            return;
        }
        setValue((props: any) => {    
            props['color'] = color.hex
            return props
        })
    }
    
    return (
        <>
            <Box display="flex">
                <IconButton 
                    color={checkActive('bold') ? 'primary' : 'default'} 
                    onMouseDown={(e) => handleCommandClick(e, 'bold')}>
                    <FormatBold />
                </IconButton>

                <IconButton 
                    color={checkActive('italic') ? 'primary' : 'default'} 
                    onMouseDown={(e) => handleCommandClick(e, 'italic')}>
                    <FormatItalic />
                </IconButton>

                <IconButton 
                    color={checkActive('underline') ? 'primary' : 'default'} 
                    onMouseDown={(e) => handleCommandClick(e, 'underline')}>
                    <FormatUnderlined />
                </IconButton>

                <ColorPicker
                    color={color}
                    onChange={handleColorChange}
                    button={(
                        <IconButton>
                            <FormatColorText />
                        </IconButton>
                    )}
                    />

                <IconButton 
                    color={checkActive('createLink') ? 'primary' : 'default'} 
                    onMouseDown={(e) => handleCommandClick(e, 'createLink')}>
                    <Link />
                </IconButton>
            </Box>
            {
                props.hideContainerProperty !== true &&
                <ContainerProperty 
                    hideAlignment
                    hideDirection
                    hideShadow
                    hideSwiper
                    {...props}
                    />
            }

            <PropertyContainer title="Texte">
                <PropertyItemTitle>Variant</PropertyItemTitle>
                <Select
                    fullWidth
                    value={getValue('variant')}
                    onChange={(e) => handleTextChange('variant', e.target.value)}>
                    {
                        variants.map((item, index) => (
                            <MenuItem key={index} value={item.variant}>{ item.label }</MenuItem>
                        ))
                    }
                </Select>

                <PropertyItemTitle>Alignement</PropertyItemTitle>
                <Select
                    fullWidth
                    value={getValue('textAlign')}
                    onChange={(e) => handleTextChange('textAlign', e.target.value)}>
                    {
                        textAligns.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{ item.label }</MenuItem>
                        ))
                    }
                </Select>

                <PropertyItemTitle>Interligne</PropertyItemTitle>
                <TextField 
                    fullWidth 
                    value={getValue('lineHeight')}
                    onChange={(e) => handleTextChange('lineHeight', e.target.value)}
                    />

            </PropertyContainer>

            <LinkDialog
                open={openLinkDialog}
                onClose={() => setOpenLinkDialog(false)}
                value={linkValue}
                onSubmit={handleLinkSubmit}
                />
        </>
        
    )
}

type TextPropertyProps = ContainerPropertyProps & {
    hideContainerProperty?: boolean
}

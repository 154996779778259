import { useFormData } from "hooks"
import { LinkDialogValue } from "../LinkDialog"

export const useLinkFormData = (initialValue: LinkDialogValue) => {

    return useFormData<LinkDialogValue>({
        formData: initialValue,
        required: [
            'href'
        ] ,
    })
}

import { initializeApp } from "firebase/app";
 
const firebaseConfig = {
  apiKey: "AIzaSyC4abjPuXKjNsxyRgqw1hRWlnGxAOXy7ts",
  authDomain: "cabinet-secret.firebaseapp.com",
  projectId: "cabinet-secret",
  storageBucket: "cabinet-secret.appspot.com",
  messagingSenderId: "82519646685",
  appId: "1:82519646685:web:22cbe1f2232890d4dd5634",
  measurementId: "G-Q1JLSMJ7NJ"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
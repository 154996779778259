import { TRegisterRequest } from "@ephatta/services"
import { useEphattaMutation } from "../.."
import { useLoginService } from "./useLoginService"

export const useRegister = () => {
    const loginService = useLoginService()

    return useEphattaMutation((payload: TRegisterRequest) => loginService.register({
        ...payload,
        applicationId: parseInt(process.env.REACT_APP_ID!)
    }))
}

import { useMemo } from "react"

export const useListTypes = () => {

    return useMemo(() => ([
        {
            value: 'ul',
            label: 'Liste à puce',
        },
        {
            value: 'ol',
            label: 'Liste numérotées',
        },
    ]), [])
}

import { useCallback } from "react"

export const useCreateImage = () => {

    const createImage = useCallback((url: string): Promise<HTMLImageElement>  => {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })
    }, [])
    
    return createImage
}

import React from "react";
import { ContainerProperty } from "./ContainerProperty";
import { ButtonProperty } from "./ButtonProperty";

export const CardProperty = () => {

    return (
        <>
            <ContainerProperty hideSwiper />
            
            <ButtonProperty hideContainerProperty />
        </>
    )
}

import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Home = React.lazy(() => import('./Home'))


const Routes: EphattaRouteProps[] = [
    {
        path: '/notification',
        element: <Home/>,
    }
   
]

export default Routes
import { useMemo } from "react"

export const useContainerBackgroundImagePosition = () => {

    return useMemo(() => {
        return [
            { value: 'left top', label: 'Gauche Haut' },
            { value: 'left center', label: 'Gauche Centre' },
            { value: 'left bottom', label: 'Gauche Bas' },
            { value: 'right top', label: 'Droite Haut' },
            { value: 'right center', label: 'Droite Centre' },
            { value: 'right bottom', label: 'Droite Bas' },
            { value: 'center top', label: 'Centre Haut' },
            { value: 'center center', label: 'Centre Centre' },
            { value: 'center bottom', label: 'Centre Bas' },
            { value: 'unset', label: 'Non défini' },
        ]
    }, [])
}












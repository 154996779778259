import {SvgIconProps} from "@mui/material";

export const ArrowRight = (props: SvgIconProps) => (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M1.48096 6.27734H18.1376" stroke="white" stroke-width="1.78464" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3784 11.0364L18.1375 6.27734" stroke="white" stroke-width="1.78464" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3784 1.51855L18.1375 6.27759" stroke="white" stroke-width="1.78464" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export const ArrowLeft = (props: SvgIconProps) => (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 22.6119L13.5942 21.0641L5.142 12.6119H23.1109V10.3896H5.142L13.5709 1.96076L11.9998 0.389648C7.932 4.45743 4.782 7.60743 0.888672 11.5008C5.29534 15.9074 2.18423 12.7963 11.9998 22.6119Z" fill="#AD5068"/>
    </svg>
)

export const ArrowUpWithNoTail = (props: SvgIconProps) => (
    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M20.7472 10.0703L10.9195 1.07031L1.0918 10.0703" stroke="#CA9B52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export const ArrowDownWithNoTail = (props: SvgIconProps) => (
    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M20.7472 1.07031L10.9195 10.0703L1.0918 1.07031" stroke="#CA9B52" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export const ArrowDownBoldedWithNoTail = (props: SvgIconProps) => (
    <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M18.5433 0.825196L10.8933 8.45853L3.24331 0.825197L0.893311 3.1752L10.8933 13.1752L20.8933 3.1752L18.5433 0.825196Z" fill="#CA9B52"/>
    </svg>
)

export const ArrowRightWithTail = (props: SvgIconProps) => (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0372 6.69426C11.4278 7.08479 11.4278 7.71795 11.0372 8.10847L4.67327 14.4724C4.28275 14.863 3.64958 14.863 3.25906 14.4724C2.86854 14.0819 2.86854 13.4487 3.25906 13.0582L8.91592 7.40137L3.25906 1.74451C2.86854 1.35399 2.86854 0.720824 3.25906 0.3303C3.64959 -0.0602247 4.28275 -0.0602247 4.67328 0.3303L11.0372 6.69426ZM0.473145 6.40137L10.3301 6.40137L10.3301 8.40137L0.473144 8.40137L0.473145 6.40137Z" fill="#111111"/>
    </svg>
)

export const ArrowLeftWithNoTail = (props: SvgIconProps) => (
    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M10.0469 20.7472L1.04688 10.9195L10.0469 1.0918" stroke="#AD5068" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export const CircleArrowLeft = (props: SvgIconProps) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
        <g opacity="0.65">
            <circle cx="16" cy="16" r="16" fill="#AD5068"/>
            <path d="M10.1728 15.2929C9.7823 15.6834 9.7823 16.3166 10.1728 16.7071L16.5368 23.0711C16.9273 23.4616 17.5605 23.4616 17.951 23.0711C18.3415 22.6805 18.3415 22.0474 17.951 21.6569L12.2941 16L17.951 10.3431C18.3415 9.95262 18.3415 9.31946 17.951 8.92893C17.5605 8.53841 16.9273 8.53841 16.5368 8.92893L10.1728 15.2929ZM21.1199 15L10.8799 15L10.8799 17L21.1199 17L21.1199 15Z" fill="white"/>
        </g>
    </svg>
)

export const CircleArrowRight = (props: SvgIconProps) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g opacity="0.65">
            <circle cx="16" cy="16" r="16" transform="rotate(-180 16 16)" fill="#AD5068"/>
            <path d="M21.8272 16.7071C22.2177 16.3166 22.2177 15.6834 21.8272 15.2929L15.4632 8.92893C15.0727 8.53841 14.4395 8.53841 14.049 8.92893C13.6585 9.31946 13.6585 9.95262 14.049 10.3431L19.7059 16L14.049 21.6569C13.6585 22.0474 13.6585 22.6805 14.049 23.0711C14.4395 23.4616 15.0727 23.4616 15.4632 23.0711L21.8272 16.7071ZM10.8801 17L21.1201 17L21.1201 15L10.8801 15L10.8801 17Z" fill="white"/>
        </g>
    </svg>
)

export const CircleArrowRightLight = (props: SvgIconProps) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="16" cy="16" r="16" transform="rotate(-180 16 16)" fill="white"/>
        <path d="M21.8272 16.7071C22.2177 16.3166 22.2177 15.6834 21.8272 15.2929L15.4632 8.92893C15.0727 8.53841 14.4395 8.53841 14.049 8.92893C13.6585 9.31946 13.6585 9.95262 14.049 10.3431L19.7059 16L14.049 21.6569C13.6585 22.0474 13.6585 22.6805 14.049 23.0711C14.4395 23.4616 15.0727 23.4616 15.4632 23.0711L21.8272 16.7071ZM10.8801 17L21.1201 17L21.1201 15L10.8801 15L10.8801 17Z" fill="#AD5068"/>
    </svg>
)

export const CircleArrowLeftLight = (props: SvgIconProps) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="16" cy="16" r="16" fill="white"/>
        <path d="M10.1728 15.2929C9.78224 15.6834 9.78224 16.3166 10.1728 16.7071L16.5367 23.0711C16.9272 23.4616 17.5604 23.4616 17.9509 23.0711C18.3415 22.6805 18.3415 22.0474 17.9509 21.6569L12.2941 16L17.9509 10.3431C18.3415 9.95262 18.3415 9.31946 17.9509 8.92893C17.5604 8.53841 16.9272 8.53841 16.5367 8.92893L10.1728 15.2929ZM21.1199 15L10.8799 15L10.8799 17L21.1199 17L21.1199 15Z" fill="#AD5068"/>
    </svg>
)
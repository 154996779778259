import { Button, ButtonProps, CircularProgress, Stack, SvgIconProps, Typography, useMediaQuery} from "@mui/material";
import {cloneElement, ReactElement} from "react";
import {usePixelToRem} from "../../../hooks/design/usePixelToRem";
import {useTheme} from "@mui/styles";
import {COLORS} from "../../../config/Colors";

interface ResponsiveSize {
    desktop: string,
    mobile: string
}

type IconsProps = {
    svgIcon: ReactElement,
    width?: ResponsiveSize,
    height?: ResponsiveSize,
    iconSpacing?: ResponsiveSize,
    svgProps?: SvgIconProps
}

export type ColorState = {
    normal: string,
    hover: string,
}

type ColorButtonProps = {
    bg?: ColorState,
    textColor?: ColorState,
    borderedOnHover?: boolean,
    borderedOnNormal?: boolean,
    noFillSVGPathOnHover?: boolean,
    noFillSVGPathOnNormal?: boolean,
}

export type CustomStandardButtonProps = {
    width?: ResponsiveSize;
    height?: ResponsiveSize;
    fontWeight?: ResponsiveSize;
    fontSize?: ResponsiveSize;
    lineHeight?: ResponsiveSize;
    startIcon?: IconsProps,
    endIcon?: IconsProps,
    borderRadius?: ResponsiveSize,
    buttonColor?: ColorButtonProps,
    paddingX?: ResponsiveSize,
    fontFamily?: 'Ubuntu' | 'Poppins',
    textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none',
    /* to active loading spinner */
    isSubmit?: boolean
    /** Custom spinner to override default spinner */
    Spinner?: React.ReactElement
} & Omit<ButtonProps, "startIcon" | "endIcon">

export const CustomStandardButton = (props: CustomStandardButtonProps) => {

    // Extract data from props
    const {
        width,
        height,
        fontWeight,
        fontSize,
        fontFamily,
        lineHeight,
        startIcon,
        endIcon,
        borderRadius,
        textTransform,
        sx,
        fullWidth,
        buttonColor,
        paddingX,
        ...rest
    } = props;

    const {pixelToRem} = usePixelToRem();

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    // ====== RESPONSIVE VARIABLES =========
    /**
     * Those variables responsible for the responsive behavior of the button
     * @type {String}
     * @private
     * @memberof CustomStandardButton
     */
    const defaultIconSize = isSmallDown ? pixelToRem(14) : pixelToRem(16);
    const defaultIconSpacing = isSmallDown ? pixelToRem(12.5) : pixelToRem(48);
    const defaultFontSize = isSmallDown ? pixelToRem(14) : pixelToRem(16);
    const defaultFontWeight = '700';
    const defaultBorderRadius = pixelToRem(5);
    const defaultHeight = isSmallDown ? pixelToRem(38) : pixelToRem(32);
    const defaultWidth = 'auto';
    const defaultLineHeight = isSmallDown ? pixelToRem(20) : pixelToRem(24);
    // ====== END RESPONSIVE VARIABLES ======

    return (
        <Button
            className={'transition-all-3s'}
            {...rest}
            disabled={props?.isSubmit}
            sx={{
                borderRadius: isSmallDown ? (borderRadius?.mobile || defaultBorderRadius) :
                    (borderRadius?.desktop || defaultBorderRadius),
                height: isSmallDown ? (height?.mobile || defaultHeight) :
                    (height?.desktop || defaultHeight),
                width: fullWidth ? '100%' : (isSmallDown ? (width?.mobile || defaultWidth) :
                    (width?.desktop || defaultWidth)),
                backgroundColor: buttonColor?.bg?.normal,
                color: buttonColor?.textColor?.normal,
                border: buttonColor?.borderedOnNormal ? `${pixelToRem(1)} solid ${buttonColor?.textColor?.normal}` : 'none',
                '&:hover': {
                    color: buttonColor?.textColor?.hover || COLORS.white,
                    backgroundColor: buttonColor?.bg?.hover || 'primary',
                    boxShadow: 'none',
                    border: buttonColor?.borderedOnHover ? `${pixelToRem(1)} solid ${buttonColor?.bg?.normal}` : 'none',
                },
                '&:hover path': buttonColor?.noFillSVGPathOnHover ? 'auto' : {
                    fill: buttonColor?.textColor?.hover || COLORS.white,
                },
                '& path': buttonColor?.noFillSVGPathOnNormal ? 'auto' :{
                    fill: buttonColor?.textColor?.normal || COLORS.white,
                },
                paddingX: isSmallDown ? paddingX?.mobile : paddingX?.desktop,
                ...sx,
            }}
        >
            {
                props.isSubmit &&
                <>
                    {
                        props.Spinner
                        ? props.Spinner
                        : <CircularProgress size={24} />
                    }
                </>
            }
            {   !props.isSubmit &&
                <Stack
                    direction={'row'}
                    spacing={
                        isSmallDown ? (startIcon?.iconSpacing?.mobile || endIcon?.iconSpacing?.mobile || defaultIconSpacing) :
                            (startIcon?.iconSpacing?.desktop || endIcon?.iconSpacing?.desktop || defaultIconSpacing)
                    }
                    sx={{
                        color: 'inherit'
                    }}
                    alignItems={'center'}
                >
                    {
                        startIcon?.svgIcon &&
                        <>
                            {
                                cloneElement(
                                    startIcon?.svgIcon,
                                    {
                                        width: isSmallDown ? (startIcon?.width?.mobile || defaultIconSize) :
                                            (startIcon?.width?.desktop || defaultIconSize),
                                        height: isSmallDown ? (startIcon?.height?.mobile || defaultIconSize) :
                                            (startIcon?.height?.desktop || defaultIconSize),
                                    }
                                )
                            }
                        </>
                    }

                    <Typography
                        fontFamily={fontFamily || 'Ubuntu'}
                        fontSize={
                            isSmallDown ?
                                ( fontSize?.mobile || defaultFontSize ):
                                ( fontSize?.desktop || defaultFontSize )
                        }
                        fontWeight={
                            isSmallDown ?
                                ( fontWeight?.mobile || defaultFontWeight ) :
                                ( fontWeight?.desktop || defaultFontWeight )
                        }
                        lineHeight={
                            isSmallDown ?
                                ( lineHeight?.mobile || defaultLineHeight ) :
                                ( lineHeight?.desktop || defaultLineHeight )
                        }
                        textTransform={textTransform || 'uppercase'}
                        sx={{
                            color: 'inherit',
                        }}
                    >
                        {props.children}
                    </Typography>

                    {
                        endIcon?.svgIcon &&
                        <>
                            {
                                cloneElement(
                                    endIcon?.svgIcon,
                                    {
                                        width: isSmallDown ? (endIcon?.width?.mobile || defaultIconSize) :
                                            (endIcon?.width?.desktop || defaultIconSize),
                                        height: isSmallDown ? (endIcon?.height?.mobile || defaultIconSize) :
                                            (endIcon?.height?.desktop || defaultIconSize),
                                    }
                                )
                            }
                        </>
                    }
                </Stack>
            }
        </Button>
    )
}
import { Container } from '@mui/material'
import React from 'react'
import { Outlet, useNavigate } from 'react-router'
import FallbackButton from '../standard/button/FallbackButton'

const MediumContainerLayout = () => {
    const navigate = useNavigate()
  return (
    <Container maxWidth="lg" sx={{position:'relative', mt: {xs:'5px', md: '20px'}}}>
        <FallbackButton label="Retour" onClick={()=>{navigate(-1)}} textTransform="none" sx={{ display:{xs:'none', md:'block'}, position:'absolute', top:0,left:0 }}/>
        <Container maxWidth="md" >
            <Outlet />
        </Container>
    </Container>
  )
}

export default MediumContainerLayout
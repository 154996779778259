import { TCustomer } from "@ephatta/services"
import { useEphattaQuery } from "../../.."
import { useCustomerServices } from ".."


export const useCustomerDetails = (userId: string) => {
    const services = useCustomerServices()
    return useEphattaQuery<TCustomer | null>(
        ["Customers.Customer.Details", userId],
        () => services.details(userId),
        {
            enabled: !!userId
        }
    )
}
import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const BadgeProfile = React.lazy(() => import('./BadgeProfile'))
const EditProfile = React.lazy(() => import('./EditProfile'))
const Profile = React.lazy(() => import('./Profile'))


const Routes: EphattaRouteProps[] = [
    {
        path: '/profile-badge',
        element: <BadgeProfile />,
    },
    {
        path: '/my-profile/edit',
        element: <EditProfile />,
    },
    {
        path: '/my-profile',
        element: <Profile />,
    },
    {
        path: '/profile/:userId',
        element: <Profile isPublic={true}/>,
    }
   
]

export default Routes
import { useRecoilValue } from "recoil"
import { TBackofficeIdentity, TClientIdentity } from "@ephatta/services"
import { Environment } from "../../state/env/Environment"

export const useEnv = <TData = TBackofficeIdentity | TClientIdentity | null>(): TData => {
    const identity: unknown = useRecoilValue(Environment)
    return identity as TData
}



import React, { useEffect } from "react"
import { GenericDialog, GenericDialogType } from "components/dialog"
import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import { useLinkFormData } from "./hooks/useLinkFormData"
import _ from "lodash"

export const LinkDialog = ({ onSubmit, value, ...props }: LinkDialogProps) => {

    const { formData, getTextFieldProps, handleInputChange, isValid, setFormData } = useLinkFormData(value)

    useEffect(() => {
        if (!_.isEqual(value, formData)) {
            setFormData(value)
        }
    }, [value])

    const handleSubmit = () => {
        if (isValid()) {
            onSubmit?.(formData)
        }
    }

    return (
        <GenericDialog
            {...props}
            title="Insérer un lien"
            onConfirm={() => handleSubmit()}>
                <Box display="flex" flexDirection="column" gap={3} mt={5}>
                    <TextField
                        label="Lien"
                        fullWidth
                        {...getTextFieldProps('href')}
                        />

                    <TextField
                        label="Info bulle"
                        fullWidth
                        {...getTextFieldProps('title')}
                        />

                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={formData.targetBlank}
                                    onChange={(e, checked) => handleInputChange('targetBlank', checked)}
                                    />
                            } 
                            label="Ouvir une nouvelle onglet lors du click"
                            />
                    </FormGroup>
                </Box>
        </GenericDialog>
    )
}

export type LinkDialogProps = Omit<GenericDialogType, 'onSubmit'> & {
    value: LinkDialogValue
    onSubmit?: (value: LinkDialogValue) => void
}

export type LinkDialogValue = {
    href: string
    title: string
    targetBlank?: boolean
}

import {usePixelToRem} from "../../hooks/design/usePixelToRem";
import {useTheme} from "@mui/styles";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import UserContainerRightLeft from "./components/UserContainerRightLeft";
import {COLORS} from "../../config/Colors";
import {useTranslation} from "react-i18next";

const EmailHasBeenSent = () => {
    const { t } = useTranslation();
    const { pixelToRem } = usePixelToRem();

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====


    return (
        <UserContainerRightLeft
            showWelcomeText={false}
            gridLeftProps={{
                alignItems: isSmallDown ? "flex-start" : "center"
            }}
            stackLeftProps={{
                spacing: isSmallDown ? pixelToRem(20.91) : pixelToRem(53.34)
            }}
        >
            <Box
                sx={{
                    width: isSmallDown ? '100%' : pixelToRem(449)
                }}
            >
                <Stack
                    direction={'column'}
                    spacing={isSmallDown ? pixelToRem(18) : pixelToRem(43)}
                    alignItems={'center'}
                >
                    <Typography
                        fontFamily={'Ubuntu'}
                        fontSize={isSmallDown ? pixelToRem(14) : pixelToRem(16)}
                        fontWeight={'500'}
                        textTransform={'uppercase'}
                        align={'center'}
                    >
                        { t("Register.Page.SubscribeEmail.HasBeenSent") }
                    </Typography>
                    <Typography
                        variant={isSmallDown ? 'body2' : 'body1'}
                        align={isSmallDown ? 'justify' : 'center'}
                        color={COLORS.philippineGray}
                        lineHeight={isSmallDown ? pixelToRem(20) : pixelToRem(24)}
                        sx={{
                            maxWidth: isSmallDown ? pixelToRem(250) : undefined,
                        }}
                    >
                        { t("Register.Page.EmailHasSent.Sentence1") } <br/>
                        { t("Register.Page.EmailHasSent.Sentence2")} { isSmallDown ? undefined : <br/> }
                        { t("Register.Page.EmailHasSent.Sentence3")} <br/>
                        { t("Register.Page.EmailHasSent.Sentence4")}

                    </Typography>
                </Stack>
            </Box>
        </UserContainerRightLeft>
    )
}

export default EmailHasBeenSent;
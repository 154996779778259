import { TLoginRequest } from "@ephatta/services"
import { useEphattaMutation } from "../.."
import { useTranslations } from "../../translation"
import { useLoginService } from "./useLoginService"

export const useLogin = () => {
    const loginService = useLoginService()
    const [
        ERROR_OCCURED
    ] = useTranslations(i18n)

    return useEphattaMutation((payload: TLoginRequest) => loginService.login({
        ...payload,
        applicationId: parseInt(process.env.REACT_APP_ID!)
    }), {
        errorMessage: ERROR_OCCURED
    })
}

const i18n = [
    "Login.Page.Login.LoginErrorMessage"
]

import React from "react";
import { Typography, TypographyProps } from "@mui/material"

export const PropertyItemTitle  = (props: Props) => {

    return (
        <Typography variant="body1" fontWeight="bold" {...props}>
            { props.children }
        </Typography>
    )
}

type Props = TypographyProps

import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

export const PropertyContainer = (props: PropertyContainerProps) => {

    const classes = useStyles()

    return (
        <Accordion elevation={0}>
            <AccordionSummary
                sx={{ paddingLeft: 0 }}
                expandIcon={<ExpandMore />}>
                <Typography variant="body1" fontWeight="bold">
                    {props.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                { props.children }
            </AccordionDetails>
        </Accordion>
    )
}

const useStyles = makeStyles(() => ({
    accordionDetails: {
        '&.MuiAccordionDetails-root': {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        '& .MuiTypography-body1': {
            fontWeight: 'normal'
        }
    }
}))

export type PropertyContainerProps = {
    title: string
    children: React.ReactNode
}

import { TCooptationPayload } from "@ephatta/services"
import { useEphattaMutation } from "hooks/utils"
import { useCooptationService } from "./useCooptationService"


export const useCoopte = () => {
    const services = useCooptationService()
    return useEphattaMutation(
        (payload: TCooptationPayload) => services.coopte(payload)
    )
}

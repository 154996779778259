import React from "react";
import { Box, Paper, TextField } from "@mui/material"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { DropZone } from "components/form";
import { ContainerProperty } from "./ContainerProperty";
import { PropertyContainer } from "./PropertyContainer";
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint";

export const ImageProperty = () => {

    const { getValue, setValue, setProp } = useBreakpoint()

    const handleDropAccepted = (files: Array<File>) => {
        const [file] = files
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const binaryStr = reader.result
            setProp((props: any) => props.src = binaryStr)
        }
        reader.readAsDataURL(file)
    }

    return (
        <>
            
            <ContainerProperty 
                hideDirection
                hideAlignment
                hideSwiper
                />

            <PropertyContainer title="Télécharger une image">
                <Paper>
                    <Box p={2}>
                        <DropZone 
                            message="Glissez une image ici ou cliquez pour choisir une image" 
                            accept={['image/*']} 
                            onDropAccepted={handleDropAccepted}
                            />
                    </Box>
                </Paper>

                <PropertyItemTitle variant="body1" fontWeight="normal">Texte alternatif</PropertyItemTitle>
                <TextField 
                    fullWidth 
                    value={getValue('alt')}
                    onChange={(e) => setValue('alt', e.target.value) } 
                    />

                <PropertyItemTitle variant="body1" fontWeight="normal">Titre</PropertyItemTitle>
                <TextField 
                    fullWidth 
                    value={getValue('title')}
                    onChange={(e) => setValue('title', e.target.value) } 
                    />
            </PropertyContainer>
        </>
    )
}

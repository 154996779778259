import React, { useEffect, createRef } from "react"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, useTheme} from "@mui/material";
import { ButtonSubmit, ButtonSubmitProps, GenericButton } from "../button";
import { CloseTwoTone } from "@mui/icons-material";

export const GenericDialog: React.FC<GenericDialogType> = (props) => {

    const theme = useTheme()
    const { title } = props;
    const outerElt = createRef<HTMLDivElement>()

    useEffect(() => {
        if(outerElt && outerElt.current) {
            outerElt.current?.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [outerElt])

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...props}
            sx={{
                '& .MuiDialog-paper': {
                    overflow: 'unset'
                },
                ...props.sx
            }}>

            {
                props.closable !== false &&
                <IconButton 
                    style={{ float: 'right' }}
                    sx={{
                        position: 'absolute',
                        right: -35,
                        top: -35,
                        color: theme.palette.primary.light
                    }}
                    onClick={() => props.onClose?.()}>
                    <CloseTwoTone />
                </IconButton>
            }

            {
                title &&
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            }
            
            <DialogContent ref={outerElt}>
                <DialogContentText id="alert-dialog-description">
                    { props.message || props.content }
                    { props.children }
                </DialogContentText>
            </DialogContent>

            {
                (props.btnCancel !== false || props.btnOk !== false) &&
                <DialogActions>
                    {
                        props.btnCancel !== false && 
                        <Button 
                            color="primary" 
                            autoFocus 
                            {...props.btnCancel}
                            onClick={() => props.onClose?.()}>
                            { props.btnCancel?.label || 'Annuler' }
                        </Button>
                    }
                    {
                        props.btnOk !== false &&
                        <ButtonSubmit
                            color="primary"
                            {...props.btnOk}
                            onClick={() => props.onConfirm?.()}>
                            { props.btnOk?.label || 'Ok' }
                        </ButtonSubmit>
                    }
                    
                </DialogActions>
            }
            
        </Dialog>
    )
}

export type GenericDialogType = DialogProps & {
    title?: string;
    message?: string | React.ReactNode;
    content?: React.ReactNode;
    open: boolean;
    btnOk?: ButtonSubmitProps | false
    btnCancel?: GenericButton | false
    closable?: boolean
    onClose?: () => void

    onConfirm?: () => void
};

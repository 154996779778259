import {Typography, TypographyProps, useMediaQuery} from "@mui/material";
import {COLORS} from "../../../../config/Colors";
import {useTheme} from "@mui/styles";

const TypoVariantLink = (props: TypographyProps) => {

    const {sx, ...rest} = props;

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    return (
        <Typography
            variant={'subtitle2'}
            color={COLORS.black}
            className={'transition-all-3s'}
            sx={{
                '&:hover': {
                    fontWeight: '700',
                    color: COLORS.chinaRose
                },
                textDecoration: isMediumDown ? 'underline' : 'none',
                ...sx
            }}
            {...rest}
        >
            {props.children}
        </Typography>
    )
}

export default TypoVariantLink;
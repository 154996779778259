import { useEphattaQuery } from "../../..";
import { useHomePageServices } from "./useHomePageServices";

export const useHomePageGet = (id: number, suspense: boolean = false) => {
    const service = useHomePageServices()
    return useEphattaQuery(
        ['Homepage.Get', id],
        () => service.get(id),
        { suspense }
    );
};

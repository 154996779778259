import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint"
import { useCallback } from "react"

export const useContainerChangeHandler = () => {
    const { setValue } = useBreakpoint()

    return useCallback((key: string, value?: any) => {
        setValue((props: any) => {
            if (props.container === undefined) {
                props.container = {}
            }

            props.container[key] = value
            return props
        })
    }, [setValue])
}

import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const ThematicLandingPage = React.lazy(() => import('./ThematicLandingPage'))


const Routes: EphattaRouteProps[] = [
    {
        path: '/thematiques',
        element: <ThematicLandingPage apiUrl="/admin/thematic?enable=false" />,
    }
   
]

export default Routes
import {SvgIconProps} from "@mui/material";

const CircleClosedIcon = (props: SvgIconProps) => (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="15.4015" cy="15.4015" r="14.9015" fill="white" stroke="#AD5068"/>
        <path d="M12.0001 12L18.6509 19.2751" stroke="#AD5068" stroke-width="2"/>
        <path d="M19 12L12.3492 19.2751" stroke="#AD5068" stroke-width="2"/>
    </svg>
)

export default CircleClosedIcon;
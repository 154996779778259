import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Messaging = React.lazy(() => import('./Messaging'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/conversations',
        element: <Messaging  apiUrl="/customer/message?conversationId=" />,
    }
]

export default Routes

import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material"
import _ from "lodash"
import React, { useMemo } from "react"
import { useCraftEditorTheme } from "./hooks/useCraftEditorTheme"

export const CraftEditorTheme = (props: Props) => {

    const defaultTheme = useCraftEditorTheme()
    const theme = useMemo(() => {
        return _.extend(defaultTheme, props.theme || {})
    }, [props.theme])

    return (
        <ThemeProvider theme={(outerTheme) => createTheme({ ...outerTheme, ...theme })}>
            {props.children}
        </ThemeProvider>
    )
}

type Props = {
    theme?: ThemeOptions
    children: React.ReactElement
}

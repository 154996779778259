import React, { useMemo } from "react"
import { ViewColumn, ViewDay } from '@mui/icons-material';
import { ToolboxItemProps } from "./useToolbox";
import { Container } from "../components/elements/Container";

export const useToolboxContainer = () => {

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Conteneur Verticale',
            icon: (<ViewColumn />),
            element: Container,
            elementProps: { padding: 20, container: { flexDirection: 'row', elevation: 1 } }
        },
        {
            label: 'Conteneur Horizontal',
            icon: (<ViewDay />),
            element: Container,
            elementProps: { padding: 20, container: { flexDirection: 'column', elevation: 1 } }
        },
    ]), [])
}

import { useEditor } from "@craftjs/core";
import { Box, Typography } from "@mui/material";
import React from "react";
import { PropertyItems } from "./PropertyItems";

export const Property = () => {

    const { active, related } = useEditor((state, query) => {
        const currentlySelectedNodeId = query.getEvent('selected').first();
        const node = currentlySelectedNodeId && state.nodes[currentlySelectedNodeId]
        return {
            active: currentlySelectedNodeId,
            related: node?.related,
        };
    });

    
    return (
        <PropertyItems>
            {
                active && 
                <>
                    { related.toolbar && React.createElement(related.toolbar) }
                </>
            }
            {!active && (
                <Box> 
                    <Typography variant="body1">
                        Click on a component to start editing.
                    </Typography>
                </Box>
                
            )}
        </PropertyItems>
    )
}

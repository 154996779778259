import { useEphattaQuery } from "hooks/utils"
import { useLandingPageServices } from "./useLandingPageServices"

export const useGetLandingDetails = (url : string, id: string) =>{
    const services = useLandingPageServices()
    return useEphattaQuery(
        'Landing.GetDetails.ByUrl.WithId',
        () => services.getDetailsById(url, id) ,
        {}
    )
}
import React from "react"
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint"
import { ColorPicker, DropZone } from "components/form"
import { useContainerChangeHandler } from "./hooks/useContainerChangeHandler"
import { PropertyContainer } from "./PropertyContainer"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { MenuItem, TextField } from "@mui/material"
import { useContainerBackgroundImagePosition } from "./hooks/useContainerBackgroundImagePosition"
import { useContainerBackgroundAttachment } from "./hooks/useContainerBackgroundAttachment"
import { useContainerBackgroundSize } from "./hooks/useContainerBackgroundSize"
import { useContainerBackgroundImageRepeat } from "./hooks/useContainerBackgroundImageRepeat"

export const ContainerBackgroundProperty = () => {

    const { getValue } = useBreakpoint()
    const handleContainerChange = useContainerChangeHandler()
    const backgroundPositions = useContainerBackgroundImagePosition()
    const backgroundAttachments = useContainerBackgroundAttachment()
    const backgroundSizes = useContainerBackgroundSize()
    const backgroundRepeats = useContainerBackgroundImageRepeat()

    const handleDropAccepted = (files: Array<File>) => {
        const [file] = files
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const binaryStr = reader.result
            handleContainerChange('backgroundImage', `url("${binaryStr}")`)
        }
        reader.readAsDataURL(file)
    }

    return (
        <PropertyContainer title="Fond">
            <PropertyItemTitle>Couleur de fond</PropertyItemTitle>
            <ColorPicker
                color={getValue('container.backgroundColor')}
                onChange={(color) => handleContainerChange('backgroundColor', `${color.hex}`)}
            />

            <PropertyItemTitle>Image de fond</PropertyItemTitle>
            <DropZone
                message="Glissez une image ici ou cliquez pour choisir une image"
                accept={['image/*']}
                onDropAccepted={handleDropAccepted}
            />

            <PropertyItemTitle>Position de l'image de fond</PropertyItemTitle>
            <TextField
                select
                fullWidth
                value={getValue('container.backgroundPosition')}
                onChange={(e) => handleContainerChange('backgroundPosition', e.target.value)}>
                {
                    backgroundPositions.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))
                }
            </TextField>

            <PropertyItemTitle>Jointure de l'image de fond</PropertyItemTitle>
            <TextField
                select
                fullWidth
                value={getValue('container.backgroundAttachment')}
                onChange={(e) => handleContainerChange('backgroundAttachment', e.target.value)}>
                {
                    backgroundAttachments.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))
                }
            </TextField>

            <PropertyItemTitle>Taille de l'image de fond</PropertyItemTitle>
            <TextField
                select
                fullWidth
                value={getValue('container.backgroundAttachment')}
                onChange={(e) => handleContainerChange('backgroundAttachment', e.target.value)}>
                {
                    backgroundSizes.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))
                }
            </TextField>

            <PropertyItemTitle>Jointure de l'image de fond</PropertyItemTitle>
            <TextField
                select
                fullWidth
                value={getValue('container.backgroundSize')}
                onChange={(e) => handleContainerChange('backgroundSize', e.target.value)}>
                {
                    backgroundAttachments.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))
                }
            </TextField>

            <PropertyItemTitle>Répétition de l'image de fond</PropertyItemTitle>
            <TextField
                select
                fullWidth
                value={getValue('container.backgroundRepeat')}
                onChange={(e) => handleContainerChange('backgroundRepeat', e.target.value)}>
                {
                    backgroundRepeats.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))
                }
            </TextField>

        </PropertyContainer>
    )
}


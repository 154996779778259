import { useNode } from "@craftjs/core";
import { Tab, Tabs, Box } from "@mui/material";
import React from "react";

export const BreakpointProperty = () => {

    const {
        actions: { setProp },
        breakpoint,
    } = useNode((node) => ({
        breakpoint: node.data.props.breakpoint,
    }));

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setProp((props: any) => props.breakpoint = newValue)
    };

    return (
        <Box width="100%">
            <Tabs 
                value={breakpoint || 'xl'} 
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="basic tabs example">
                {
                    tabs.map((tab, index) => (
                        <Tab key={index} {...tab} {...a11yProps(index)} />
                    ))
                }
            </Tabs>
        </Box>
    )
}

const tabs = [
    { label: 'Par défaut', value: 'xl' },
    { label: 'Desktop', value: 'md' },
    { label: 'Tablets', value: 'sm' },
    { label: 'Mobile', value: 'xs' }
]

function a11yProps(index: number) {
    return {
        id: `property-breakpoint-tab-${index}`,
        'aria-controls': `property-breakpoint-tabpanel-${index}`,
    };
}

import {SvgIconProps} from "@mui/material";

export const InstagramIcon = (props: SvgIconProps) => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.07516 0.583008H18.9252C23.0585 0.583008 26.4168 3.94134 26.4168 8.07467V18.9247C26.4168 20.9116 25.6275 22.8171 24.2226 24.2221C22.8176 25.627 20.9121 26.4163 18.9252 26.4163H8.07516C3.94183 26.4163 0.583496 23.058 0.583496 18.9247V8.07467C0.583496 6.08776 1.37279 4.18223 2.77775 2.77727C4.18271 1.37231 6.08825 0.583008 8.07516 0.583008ZM7.81683 3.16634C6.58357 3.16634 5.40083 3.65625 4.52878 4.52829C3.65674 5.40034 3.16683 6.58308 3.16683 7.81634V19.183C3.16683 21.7534 5.24641 23.833 7.81683 23.833H19.1835C20.4168 23.833 21.5995 23.3431 22.4715 22.4711C23.3436 21.599 23.8335 20.4163 23.8335 19.183V7.81634C23.8335 5.24592 21.7539 3.16634 19.1835 3.16634H7.81683ZM20.2814 5.10384C20.7096 5.10384 21.1203 5.27395 21.4231 5.57674C21.7259 5.87953 21.896 6.29021 21.896 6.71842C21.896 7.14664 21.7259 7.55731 21.4231 7.86011C21.1203 8.1629 20.7096 8.33301 20.2814 8.33301C19.8532 8.33301 19.4425 8.1629 19.1397 7.86011C18.8369 7.55731 18.6668 7.14664 18.6668 6.71842C18.6668 6.29021 18.8369 5.87953 19.1397 5.57674C19.4425 5.27395 19.8532 5.10384 20.2814 5.10384ZM13.5002 7.04134C15.213 7.04134 16.8557 7.72177 18.0669 8.93294C19.2781 10.1441 19.9585 11.7868 19.9585 13.4997C19.9585 15.2125 19.2781 16.8552 18.0669 18.0664C16.8557 19.2776 15.213 19.958 13.5002 19.958C11.7873 19.958 10.1446 19.2776 8.93343 18.0664C7.72226 16.8552 7.04183 15.2125 7.04183 13.4997C7.04183 11.7868 7.72226 10.1441 8.93343 8.93294C10.1446 7.72177 11.7873 7.04134 13.5002 7.04134ZM13.5002 9.62467C12.4724 9.62467 11.4868 10.0329 10.7601 10.7596C10.0334 11.4863 9.62516 12.472 9.62516 13.4997C9.62516 14.5274 10.0334 15.513 10.7601 16.2397C11.4868 16.9664 12.4724 17.3747 13.5002 17.3747C14.5279 17.3747 15.5135 16.9664 16.2402 16.2397C16.9669 15.513 17.3752 14.5274 17.3752 13.4997C17.3752 12.472 16.9669 11.4863 16.2402 10.7596C15.5135 10.0329 14.5279 9.62467 13.5002 9.62467Z" fill="white"/>
        </svg>
    )
}

export const FacebookIcon = (props: SvgIconProps) => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.5002 0.634766C6.396 0.634766 0.583496 6.43435 0.583496 13.5773C0.583496 20.0356 5.311 25.396 11.4852 26.3648V17.3231H8.20433V13.5773H11.4852V10.7227C11.4852 7.4806 13.4097 5.6981 16.3677 5.6981C17.7756 5.6981 19.2481 5.94352 19.2481 5.94352V9.13393H17.6206C16.0189 9.13393 15.5152 10.1285 15.5152 11.1489V13.5773H19.106L18.5247 17.3231H15.5152V26.3648C18.5589 25.8841 21.3305 24.3311 23.3297 21.9861C25.3288 19.6411 26.4237 16.6587 26.4168 13.5773C26.4168 6.43435 20.6043 0.634766 13.5002 0.634766Z" fill="white"/>
        </svg>
    )
}

export const YoutubeIcon = (props: SvgIconProps) => {
    return (
        <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.9168 13.3747L17.6206 9.49967L10.9168 5.62467V13.3747ZM25.8485 3.26092C26.0164 3.86801 26.1327 4.68176 26.2102 5.71509C26.3006 6.74842 26.3393 7.63967 26.3393 8.41467L26.4168 9.49967C26.4168 12.3284 26.2102 14.408 25.8485 15.7384C25.5256 16.9009 24.7764 17.6501 23.6139 17.973C23.0068 18.1409 21.896 18.2572 20.191 18.3347C18.5118 18.4251 16.9747 18.4638 15.5539 18.4638L13.5002 18.5413C8.08808 18.5413 4.71683 18.3347 3.38641 17.973C2.22391 17.6501 1.47475 16.9009 1.15183 15.7384C0.983913 15.1313 0.867663 14.3176 0.790163 13.2843C0.699746 12.2509 0.660996 11.3597 0.660996 10.5847L0.583496 9.49967C0.583496 6.67092 0.790163 4.59134 1.15183 3.26092C1.47475 2.09842 2.22391 1.34926 3.38641 1.02634C3.9935 0.858425 5.10433 0.742174 6.80933 0.664674C8.4885 0.574258 10.0256 0.535508 11.4464 0.535508L13.5002 0.458008C18.9122 0.458008 22.2835 0.664675 23.6139 1.02634C24.7764 1.34926 25.5256 2.09842 25.8485 3.26092Z" fill="white"/>
        </svg>
    )
}

export const LinkedinIcon = (props: SvgIconProps) => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M23.5465 0.583008C24.3077 0.583008 25.0378 0.885421 25.5761 1.42372C26.1144 1.96202 26.4168 2.69211 26.4168 3.45338V23.546C26.4168 24.3072 26.1144 25.0373 25.5761 25.5756C25.0378 26.1139 24.3077 26.4163 23.5465 26.4163H3.45387C2.6926 26.4163 1.96251 26.1139 1.42421 25.5756C0.885909 25.0373 0.583496 24.3072 0.583496 23.546V3.45338C0.583496 2.69211 0.885909 1.96202 1.42421 1.42372C1.96251 0.885421 2.6926 0.583008 3.45387 0.583008H23.5465ZM22.8289 22.8284V15.2219C22.8289 13.981 22.3359 12.791 21.4585 11.9136C20.5811 11.0361 19.391 10.5432 18.1502 10.5432C16.9303 10.5432 15.5094 11.2895 14.8205 12.4089V10.8159H10.8164V22.8284H14.8205V15.7529C14.8205 14.6478 15.7103 13.7437 16.8154 13.7437C17.3483 13.7437 17.8594 13.9553 18.2362 14.3322C18.613 14.709 18.8247 15.22 18.8247 15.7529V22.8284H22.8289ZM6.15201 8.56264C6.79148 8.56264 7.40476 8.30861 7.85693 7.85644C8.3091 7.40427 8.56313 6.79099 8.56313 6.15153C8.56313 4.8168 7.48674 3.72606 6.15201 3.72606C5.50874 3.72606 4.89182 3.9816 4.43695 4.43646C3.98209 4.89133 3.72655 5.50825 3.72655 6.15153C3.72655 7.48625 4.81729 8.56264 6.15201 8.56264ZM8.14692 22.8284V10.8159H4.17146V22.8284H8.14692Z" fill="white"/>
        </svg>

    )
}

export const FacebookColoredIcon = (props: SvgIconProps) => {
    return (
        <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M11.5 0.5H8.5C7.17392 0.5 5.90215 1.02678 4.96447 1.96447C4.02678 2.90215 3.5 4.17392 3.5 5.5V8.5H0.5V12.5H3.5V20.5H7.5V12.5H10.5L11.5 8.5H7.5V5.5C7.5 5.23478 7.60536 4.98043 7.79289 4.79289C7.98043 4.60536 8.23478 4.5 8.5 4.5H11.5V0.5Z" fill="#4092FF"/>
        </svg>
    )
}

export const GoogleColoredIcon = (props: SvgIconProps) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M16.3445 6.93323H15.7001V6.90003H8.50003V10.1H13.0213C12.3617 11.9628 10.5892 13.3001 8.50003 13.3001C5.84922 13.3001 3.70001 11.1508 3.70001 8.50003C3.70001 5.84922 5.84922 3.70001 8.50003 3.70001C9.72364 3.70001 10.8368 4.16162 11.6844 4.91562L13.9473 2.65281C12.5185 1.3212 10.6072 0.5 8.50003 0.5C4.08202 0.5 0.5 4.08202 0.5 8.50003C0.5 12.9181 4.08202 16.5001 8.50003 16.5001C12.9181 16.5001 16.5001 12.9181 16.5001 8.50003C16.5001 7.96363 16.4449 7.44003 16.3445 6.93323Z" fill="#FFC107"/>
            <path d="M1.42188 4.77642L4.05029 6.70403C4.76149 4.94322 6.4839 3.70001 8.49951 3.70001C9.72311 3.70001 10.8363 4.16162 11.6839 4.91562L13.9467 2.65281C12.5179 1.3212 10.6067 0.5 8.49951 0.5C5.42669 0.5 2.76188 2.23481 1.42188 4.77642Z" fill="#FF3D00"/>
            <path d="M8.49996 16.5005C10.5664 16.5005 12.444 15.7097 13.8636 14.4237L11.3876 12.3285C10.5844 12.9369 9.58596 13.3005 8.49996 13.3005C6.41915 13.3005 4.65234 11.9737 3.98674 10.1221L1.37793 12.1321C2.70194 14.7229 5.39075 16.5005 8.49996 16.5005Z" fill="#4CAF50"/>
            <path d="M16.3444 6.93359H15.7V6.90039H8.5V10.1004H13.0212C12.7044 10.9952 12.1288 11.7668 11.3864 12.3288C11.3868 12.3284 11.3872 12.3284 11.3876 12.328L13.8636 14.4232C13.6884 14.5824 16.5 12.5004 16.5 8.5004C16.5 7.964 16.4448 7.44039 16.3444 6.93359Z" fill="#1976D2"/>
        </svg>
    )
}
import { memo, useMemo, useState } from "react";
import { CabinetSecretLogoWithText } from "../../../icons/logo/cabinet-secret-logo-icon";
import { COLORS } from "../../../../config/Colors";
import { usePixelToRem } from "../../../../hooks/design/usePixelToRem";
import { useTheme } from "@mui/styles";
import { Box, Stack, Typography, useMediaQuery} from "@mui/material";
import CustomDivider from "../../../standard/CustomDivider";
import LinkCustomed from "../../../standard/link/LinkCustomed";
import { CustomStandardButton } from "../../../standard/button/CustomStandardButton";
import { DownloadIcon } from "../../../icons/basic/unclassified";
import CircleClosedIcon from "../../../icons/basic/closed-icon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEnv } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";


const DrawerMenuMobile = (props: { open: boolean, onClose?: () => void }) => {

    const { pixelToRem } = usePixelToRem();

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    const env = useEnv<TClientIdentity>()
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ResponsiveLogo = memo(() => (<CabinetSecretLogoWithText
        svgProps={{
            width: isSmallDown ? pixelToRem(78) : pixelToRem(68),
            height: isSmallDown ? pixelToRem(80) : pixelToRem(70),
        }}
        textProps={{
            spaceRelativeToTheLogo: {
                desktop: pixelToRem(20.07),
                mobile: pixelToRem(15.8),
            },
            lineHeight: {
                desktop: pixelToRem(30),
                mobile: pixelToRem(24),
            },
            fontSize: {
                desktop: pixelToRem(25),
                mobile: pixelToRem(19),
            },
            color: COLORS.aztecGold,
        }}
        logoType={'light'} />));

        const isLogged = useMemo(()=>{
            return env !== null
        },[env])
    return (
        <Box
            className={'transition-all-3s'}
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                transform: props.open ? 'translate(0)' : 'translateX(-100%)',
                height: '100vh',
                backgroundColor: `${COLORS.chinaRose}52`,
                zIndex: 200,
            }}
        >
            
            {/* Drawer panel */}
            <Box
                sx={{
                    position: 'relative',
                    boxSizing: 'border-box',
                    backgroundColor: COLORS.white,
                    width: '95%',
                    height: '100%',
                    paddingTop: pixelToRem(16),
                    paddingBottom: pixelToRem(16),
                }}
            >
                <span
                    className={'transition-all-3s zoom-in-on-hover zoom-in-on-press'}
                    style={{
                        position: 'absolute',
                        right: pixelToRem(-15),
                        top: pixelToRem(96),
                    }}
                    onClick={() => props.onClose && props.onClose()}
                >
                    <CircleClosedIcon />
                </span>
                <Stack
                    direction={'column'}
                    className={'full-height'}
                    justifyContent={'space-between'}
                >
                    <Box
                        className={'full-width'}
                    >
                        <Stack
                            direction={'column'}
                            alignItems={'flex-start'}
                            divider={<CustomDivider color={COLORS.grayBlueLight} />}
                            spacing={pixelToRem(15.58)}
                        >
                            <Box
                                paddingX={pixelToRem(18)}
                                onClick={(event) => navigate('/')}
                            >
                                <ResponsiveLogo />
                            </Box>
                            {/* Menu for the subscriber */}
                            {   isLogged &&
                                <Box>
                                    <Stack
                                        direction={'column'}
                                        spacing={pixelToRem(15)}
                                    >
                                        {
                                            subscriberMenus.map((menu, index) => {
                                                return (
                                                    <Box
                                                        paddingX={pixelToRem(40)}
                                                        onClick={() => props.onClose?.()}
                                                    >
                                                        <LinkCustomed to={menu.link} key={index}>
                                                            <Typography
                                                                variant={'body1'}
                                                                className={'transition-all-3s color-burgundy-on-hover bold-on-hover'}
                                                            >
                                                                {t(menu.name)}
                                                            </Typography>
                                                        </LinkCustomed>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Stack>
                                </Box>
                            }
                            {/* Standard menu */}
                            <Box className={'full-width'}>
                                <Stack
                                    direction={'column'}
                                    spacing={pixelToRem(15)}
                                >
                                    {
                                        menus.map((menu, index) => {
                                            return (
                                                <Box
                                                    paddingX={pixelToRem(40)}
                                                    onClick={() => props.onClose?.()}
                                                >
                                                    <LinkCustomed to={menu.link} key={index} >
                                                        <Typography 
                                                            variant={'body1'}
                                                            className={'transition-all-3s color-burgundy-on-hover bold-on-hover'}
                                                        >
                                                            {t(menu.name)}
                                                        </Typography>
                                                    </LinkCustomed>
                                                </Box>
                                            )
                                        })
                                    }
                                    { !isLogged &&
                                        <>
                                            <Box
                                                paddingX={pixelToRem(18)}
                                            >
                                                <CustomStandardButton
                                                    height={{ desktop: pixelToRem(27), mobile: pixelToRem(27) }}
                                                    width={{ desktop: '100%', mobile: '100%' }}
                                                    variant={'contained'}
                                                    onClick={() => {props.onClose?.() ; navigate('/registration')}}
                                                    buttonColor={{
                                                        textColor: {
                                                            normal: COLORS.white,
                                                            hover: COLORS.aztecGold,
                                                        },
                                                        bg: {
                                                            normal: COLORS.aztecGold,
                                                            hover: COLORS.white,
                                                        },
                                                        borderedOnHover: true
                                                    }}
                                                    fontWeight={{ desktop: '400', mobile: '400' }}
                                                >
                                                    {t("Client.SignUp.Title")}
                                                </CustomStandardButton>
                                            </Box>

                                            <Box
                                                paddingX={pixelToRem(18)}
                                            >
                                                <CustomStandardButton
                                                    height={{ desktop: pixelToRem(27), mobile: pixelToRem(27) }}
                                                    width={{ desktop: '100%', mobile: '100%' }}
                                                    variant={'contained'}
                                                    buttonColor={{
                                                        textColor: {
                                                            normal: COLORS.aztecGold,
                                                            hover: COLORS.white,
                                                        },
                                                        bg: {
                                                            normal: COLORS.white,
                                                            hover: COLORS.aztecGold,
                                                        },
                                                        borderedOnNormal: true
                                                    }}
                                                    fontWeight={{ desktop: '400', mobile: '400' }}
                                                    onClick={() => {props.onClose?.() ; navigate('/login')}}
                                                >
                                                    {t("Client.Login.Title")}
                                                </CustomStandardButton>
                                            </Box>
                                        </>
                                    }

                                   { isLogged &&
                                    <Box
                                            paddingX={pixelToRem(18)}
                                        >
                                            <CustomStandardButton
                                                height={{ desktop: pixelToRem(27), mobile: pixelToRem(27) }}
                                                width={{ desktop: '100%', mobile: '100%' }}
                                                variant={'contained'}
                                                buttonColor={{
                                                    textColor: {
                                                        normal: COLORS.white,
                                                        hover: COLORS.aztecGold,
                                                    },
                                                    bg: {
                                                        normal: COLORS.aztecGold,
                                                        hover: COLORS.white,
                                                    },
                                                    borderedOnHover: true
                                                }}
                                                fontWeight={{ desktop: '400', mobile: '400' }}
                                                onClick={() => {props.onClose?.() ; navigate('/logout')}}
                                            >
                                                {t("Client.Header.MyProfile.Logout.Label")}
                                            </CustomStandardButton>
                                        </Box>
                                    }

                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    {/* Download Mobile Application Button */}
                    <Box
                        paddingX={pixelToRem(18)}
                    >
                        <CustomStandardButton
                            height={{ desktop: pixelToRem(58), mobile: pixelToRem(58) }}
                            width={{ desktop: '100%', mobile: '100%' }}
                            variant={'contained'}
                            startIcon={{
                                height: { desktop: pixelToRem(17), mobile: pixelToRem(17) },
                                width: { desktop: pixelToRem(20), mobile: pixelToRem(20) },
                                iconSpacing: { desktop: pixelToRem(17), mobile: pixelToRem(17) },
                                svgIcon: <DownloadIcon />,
                            }}
                            buttonColor={{
                                textColor: {
                                    normal: COLORS.chinaRose,
                                    hover: COLORS.white,
                                },
                                bg: {
                                    normal: COLORS.white,
                                    hover: COLORS.chinaRose,
                                },
                                borderedOnNormal: true
                            }}
                            lineHeight={{ desktop: pixelToRem(13.79), mobile: pixelToRem(13.79) }}
                            fontWeight={{ desktop: '700', mobile: '700' }}
                            onClick={()=>{props.onClose?.()}}
                        >
                            {t("Home.Page.DrawerPanel.DownloadApplication.Label")} <br />
                            {t("Home.Page.DrawerPanel.DownloadApplication.Mobile")}
                        </CustomStandardButton>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}

const menus = [
    {
        name: "Home.Page.Footer.CabinetSecret.Label",
        link: '/cabinet-secret',
    },
    {
        name: "Home.Page.Footer.CabinetSecret.WhoAmI.Label",
        link: '/presentation',
    },
    {
        name: "Home.Page.Footer.Community.Testimonial.Label",
        link: '/testomies',
    },
    {
        name: "Home.Page.Footer.Community.Blog.Label",
        link: '/faq',
    }
]

const subscriberMenus = [
    {
        name: "Home.Page.Footer.CabinetSecret.Thematic.Label",
        link: '/thematiques',
    },
    {
        name: "Home.Page.Footer.Profile.MyProfile.BestPlan",
        link: '/bons-plans',
    },
    {
        name: "Home.Page.Footer.Profile.MyProfile.Games",
        link: '/jeux-concours',
    }
]

export default DrawerMenuMobile;
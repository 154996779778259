import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Dashboard = React.lazy(() => import('./Dashboard'))
const Details = React.lazy(() => import('./Details'))


const Routes: EphattaRouteProps[] = [
    {
        path: '/bons-plans',
        element: <Dashboard title="Bons Plans" id='voucher' apiUrl="/admin/voucher"/>,
    },
    {
        path: '/bons-plans/:id',
        element: <Details title="Bons Plans details" apiUrl="/admin/voucher"/>,
    },
    {
        path: '/jeux-concours',
        element: <Dashboard title="Jeux Concours" id='game' apiUrl="/admin/game" />,
    },
    {
        path: '/jeux-concours/:id',
        element: <Details title="Jeux Concours Details" apiUrl="/admin/game"/>,
    },
]

export default Routes


import { useNode } from "@craftjs/core";
import React from "react"
import { BreakpointProperty } from "../property/BreakpointProperty";
import { ListProperty } from "../property/ListProperty";
import { Container, ContainerProps } from "./Container"
import { Text } from "./Text"

export const List = (props: ListProps) => {

    const {
        setProp,
    } = useNode();

    const getContent = (value: string) => {

        const match = value.match(new RegExp(`(?<=<(ol|ul)>)(.*?)(?=</(ol|ul)>)`))
        return match?.[2] || value
    }

    return (
        <Container container={props.container}>
            <Text text={`<${props.type}>${getContent(props.text) || ''}</${props.type}>`} onChange={(value) => setProp((props: any) => props.text = value)} />
        </Container>
    )
}

export const ListDefaultProps = {
    type: 'ul',
}

List.craft = {
    displayName: 'Button',
    props: ListDefaultProps,
    related: {
        toolbar: ListProperty,
        breakpoint: BreakpointProperty
    },
};

export type ListProps = {
    type: 'ul' | 'ol'
    text: string
    container?: ContainerProps['container']
}

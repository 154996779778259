import React, { useEffect, useMemo } from "react";
import { useNode } from "@craftjs/core";
import { Checkbox, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Slider, TextField } from "@mui/material"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { useJustifyContent } from "components/craft-editor/hooks/useJustifyContent";
import { useAlignItems } from "components/craft-editor/hooks/useAlignItems";
import { BorderProperty } from "./BorderProperty";
import { SquareProperty } from "./SquareProperty";
import { PropertyContainer } from "./PropertyContainer";
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint";
import { useContainerChangeHandler } from "./hooks/useContainerChangeHandler";
import { ContainerBackgroundProperty } from "./ContainerBackgroundProperty";
import { ContainerSwiperProperty } from "./ContainerSwiperProperty";

export const ContainerProperty = (props: ContainerPropertyProps) => {

    const {
        actions: { setProp },
        container,
    } = useNode((node) => ({
        container: node.data.props.container,
    }));

    const justifyContents = useJustifyContent()
    const alignItemses = useAlignItems()
    const { getValue } = useBreakpoint()
    const handleContainerChange = useContainerChangeHandler()

    useEffect(() => {
        if (!container) {
            setProp((props: any) => props.container = {})
        }
    }, [])

    const flexDirectionRow = useMemo(() => {
        return getValue('container.flexDirection') === 'row'
    }, [getValue])

    return (
        <>
            <PropertyContainer title="Conteneur">
                {
                    props.hideDirection !== true &&
                    <>
                        <PropertyItemTitle>Direction</PropertyItemTitle>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="row"
                            value={getValue('container.flexDirection')}
                            onChange={(e, value) => handleContainerChange('flexDirection', value)}>
                            <FormControlLabel value="column" control={<Radio />} label="Verticale" />
                            <FormControlLabel value="column-reverse" control={<Radio />} label="Verticale Inverse" />
                            <FormControlLabel value="row" control={<Radio />} label="Horizontale" />
                            <FormControlLabel value="row-reverse" control={<Radio />} label="Horizontale Inverse" />
                        </RadioGroup>

                        <PropertyItemTitle>Espacement</PropertyItemTitle>
                        <TextField
                            fullWidth
                            value={getValue('container.gap')}
                            placeholder="Espacement entre les blocs"
                            onChange={(e) => handleContainerChange('gap', e.target.value)}
                        />
                    </>
                }

                {
                    props.hideDimension !== true &&
                    <>
                        <PropertyItemTitle>Largeur</PropertyItemTitle>
                        <TextField
                            fullWidth
                            value={getValue('container.width')}
                            placeholder="Largeur de la bloc"
                            onChange={(e) => handleContainerChange('width', e.target.value)}
                        />

                        <PropertyItemTitle>Hauteur</PropertyItemTitle>
                        <TextField
                            fullWidth
                            value={getValue('container.height')}
                            placeholder="Hauteur de la block"
                            onChange={(e) => handleContainerChange('height', e.target.value)}
                        />

                        <PropertyItemTitle>Flex</PropertyItemTitle>
                        <TextField
                            fullWidth
                            value={getValue('container.flex')}
                            placeholder="Controller la dimension du bloc"
                            onChange={(e) => handleContainerChange('flex', e.target.value)}
                        />
                    </>
                }

                <PropertyItemTitle>Visible</PropertyItemTitle>
                <FormControlLabel
                    control={<Checkbox checked={getValue('container.display') !== 'none'} />}
                    label="Visible"
                    onChange={(e, checked) => handleContainerChange('display', !checked ? 'none' : 'unset')}
                />
            </PropertyContainer>

            {
                (props.hideMargin !== true || props.hidePadding !== true) &&
                <PropertyContainer title="Marge">
                    {
                        props.hideMargin !== true &&
                        <>
                            <PropertyItemTitle>Marge extérieur</PropertyItemTitle>
                            <SquareProperty
                                value={getValue('container.margin')}
                                onChange={(value) => handleContainerChange('margin', value)}
                            />
                        </>
                    }

                    {
                        props.hidePadding !== true &&
                        <>
                            <PropertyItemTitle>Marge intérieur</PropertyItemTitle>
                            <SquareProperty
                                value={getValue('container.padding')}
                                onChange={(value) => handleContainerChange('padding', value)}
                            />
                        </>
                    }
                </PropertyContainer>
            }


            {
                props.hideBackground !== true &&
                <ContainerBackgroundProperty />
            }

            {
                props.hideBorder !== true &&
                <BorderProperty propName="container" />
            }

            {
                props.hideShadow !== true &&
                <PropertyContainer title="Extra">
                    <PropertyItemTitle>Ombre</PropertyItemTitle>
                    <Slider
                        min={0}
                        max={24}
                        value={getValue('container.elevation')}
                        onChange={(e, value) => handleContainerChange('elevation', value)}
                    />
                </PropertyContainer>
            }

            {
                props.hideAlignment !== true &&
                <PropertyContainer title="Alignement">
                    <PropertyItemTitle>Alignement {flexDirectionRow ? 'Horizontal' : 'Vertical'}</PropertyItemTitle>
                    <Select
                        fullWidth
                        value={getValue('container.justifyContent')}
                        onChange={(e) => handleContainerChange('justifyContent', e.target.value)}>
                        {
                            flexDirectionRow &&
                            justifyContents.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                            ))
                        }

                        {
                            !flexDirectionRow &&
                            alignItemses.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                            ))
                        }
                    </Select>

                    <PropertyItemTitle>Alignement {flexDirectionRow ? 'Vertical' : 'Horizontal'}</PropertyItemTitle>
                    <Select
                        fullWidth
                        value={getValue('container.alignItems')}
                        onChange={(e) => handleContainerChange('alignItems', e.target.value)}>
                        {
                            flexDirectionRow &&
                            alignItemses.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                            ))
                        }
                        {
                            !flexDirectionRow &&
                            justifyContents.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                            ))
                        }
                    </Select>
                </PropertyContainer>
            }

            {
                props.hideSwiper !== true &&
                <ContainerSwiperProperty />
            }


        </>
    )
}

export type ContainerPropertyProps = {
    hideAlignment?: boolean
    hideShadow?: boolean
    hideDimension?: boolean
    hideDirection?: boolean
    hideBackground?: boolean
    hideBorder?: boolean
    hideMargin?: boolean
    hidePadding?: boolean
    hideSwiper?: boolean
}

import {Breakpoint, useMediaQuery} from "@mui/material";
import {usePixelToRem} from "./usePixelToRem";
import {useTheme} from "@mui/styles";

export const useResponsiveContainerSize = () => {

    const { pixelToRem } = usePixelToRem();

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    const containerMaxWidth: Breakpoint = 'lg';

    const headerHeight: string = isMediumDown ? pixelToRem(31) : pixelToRem(86);
    const footerHeight: string = isMediumDown ? pixelToRem(359.33) : pixelToRem(400.3);
    const bodyContainerHeight = `calc(100vh - ${headerHeight} - ${footerHeight})`;
    const fullHeightMinusHeader = `calc(100vh - ${headerHeight})`;

    return {
        containerMaxWidth,
        headerHeight,
        footerHeight,
        fullHeightMinusHeader,
        bodyContainerHeight
    };

}
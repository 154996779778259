import {
    createTheme,
    ThemeProvider,
    Theme,
    ThemeOptions
} from '@mui/material';
import React, { Suspense, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot, RecoilRootProps } from "recoil"
import { config, DefaultTheme } from "../../config"
import { Shell, ShellProps } from './Shell'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import './i18n'
import { MainLoader } from 'components/progress/MainLoader';
import { frFR, enUS } from '@mui/x-data-grid';
import i18n from "i18next";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const Root = (props: Props) => {

    const queryClient = new QueryClient({
        defaultOptions: config.query,
    })


    const locale = useMemo(() => {
        if (i18n.language === 'en') {
            return enUS
        }
        return frFR
    }, [i18n])

    return (
        <RecoilRoot {...props}>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={createTheme({...DefaultTheme, ...props.theme }, locale)}>
                        <Suspense fallback={<MainLoader sx={{ height: '100vh' }} />}>
                            <BrowserRouter>
                                <Shell {...props} />
                            </BrowserRouter>
                        </Suspense>
                    </ThemeProvider>
                </LocalizationProvider>
            </QueryClientProvider>
        </RecoilRoot>
    );
}

type Props = RecoilRootProps 
    & ShellProps 
    & {
        theme?: ThemeOptions
    }

import { useEphattaQuery } from "hooks/utils"
import { useCustomerCardsServices } from "./useCustomerCardsServices"


export const useCustomerCards = (stripeId: string) => {
    const service = useCustomerCardsServices()
    return useEphattaQuery(
        ['Customer.Cards', stripeId],
        () => service.getCards(stripeId)
    );
}

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useImgUrlSplit } from 'hooks';

export const PopupEstablishment = (props: PopupEstablishmentProps) => {
  const formatImgUrl = useImgUrlSplit()

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image={formatImgUrl(props?.mainPicture!)}
        alt={props.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          { props.name }
        </Typography>
        <Typography variant="body2" color="text.secondary">
          { props.description }
        </Typography>
      </CardContent>
    </Card>
  );
}

type PopupEstablishmentProps = {
  id?: number | null,
  establishmentId?: string | null,
  mainPicture?: string,
  name?: string,
  description?: string,
  street?: string,
  city?: string,
  country?: string,
  zipCode?: number,
  latitude?: number,
  longitude?: number,
  culture?: string,
}

import { useEphattaQuery } from "hooks";
import { useMessageServices } from "./useMessageServices";

export const useMessageGetAll = (conversationId : string) =>{
    const services = useMessageServices()
    return useEphattaQuery(
        ['Message.GetAll', conversationId],
        () => services.getAll(conversationId) ,
        {}
    )
}

import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Dashboard = React.lazy(() => import('./Dashboard'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/payment',
        element: <Dashboard/>,
    },
]

export default Routes

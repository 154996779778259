import { EphattaRouteProps } from "@ephatta/components"
import React from 'react'

const FAQPage = React.lazy(() => import('./FAQPage') )

const Routes: EphattaRouteProps[] = [
    {
        path:'/faq',
        element:<FAQPage/>
    }
]

export default Routes

import React from 'react'
import ReactDropzone, { DropzoneProps as ReactDropzoneProps  } from 'react-dropzone'

export const DropZone = ({ message, ...props }: DropZoneProps) => {

    return (
        <ReactDropzone {...props}>
            {
                ({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>{ message }</p>
                        </div>
                    </section>
                )
            }
        </ReactDropzone>
    )
}

export type DropZoneProps = ReactDropzoneProps & {
    message?: string
}

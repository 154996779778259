import { useEphattaQuery } from "hooks";
import { useNotificationsServices } from "./useNotificationsServices";

export const useNotificationsGetAll =  (userId : string) =>{
    const services = useNotificationsServices()
    return useEphattaQuery(
        'Landing.GetAll.ByUrl',
        () => services.getAll(userId) ,
        {}
    )
};

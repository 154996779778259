import {Box, IconButton, SvgIconProps, TextField, TextFieldProps, useMediaQuery, useTheme} from "@mui/material";
import {COLORS} from "../../../config/Colors";
import {usePixelToRem} from "../../../hooks/design/usePixelToRem";
import {SearchIcon} from "../../icons/basic/unclassified";
import CustomDivider from "../CustomDivider";

const SearchBarFullWidth = (props: SearchBarProps) => {
    const {pixelToRem} = usePixelToRem();

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====


    return (
        <Box
            component="form"
            noValidate
            className={'cursor-pointer transition-all-3s'}
            onSubmit={(e)=>{e.preventDefault(); props?.searchIconProps?.onClick?.(e as any)}}
            sx={{
                width: '100%',
                paddingX: isSmallDown ? pixelToRem(8) : pixelToRem(13.5),
                background: COLORS.white,
                paddingTop: isSmallDown ? pixelToRem(8) : pixelToRem(22.5),
                paddingBottom: isSmallDown ? pixelToRem(5) : pixelToRem(16.5),
                height: isSmallDown ? pixelToRem(40) : pixelToRem(73),
                '&:hover': {
                    border: `${pixelToRem(1)} solid ${COLORS.aztecGold}`,
                }
            }}>
            <TextField
                placeholder={"Rechercher"}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <IconButton className={'searchInputButton'} type="submit">
                            <SearchIcon
                                {...props.searchIconProps}
                                fontSize="large"
                                width={isSmallDown ? 18 : 26}
                                height={isSmallDown ? 18 : 26}
                            />
                        </IconButton>
                    ),
                    sx: {
                        paddingLeft: pixelToRem(8),
                        paddingRight: pixelToRem(9),
                        fontSize: {xs: "1rem", md: "1.125rem"},
                        fontWeight: "500",
                        height: {md: pixelToRem(55), xs: pixelToRem(50)}
                    },
                    ...props.textFieldProps?.InputProps,
                }}
                {...props.textFieldProps}
                sx={{
                    ...props.textFieldProps?.sx,
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0px!important',
                        height: 'auto',
                        paddingY: '0px',
                        paddingX: pixelToRem(5),
                    },
                    '& input': {
                        paddingBottom: pixelToRem(0),
                        paddingTop: pixelToRem(0),
                    },
                    '& .searchInputButton': {
                        padding: pixelToRem(0),
                    },
                    '& fieldset': {
                        border: 'none',
                    }
                }}
                className={`${props.textFieldProps?.className}`}
            />
            <Box sx={{
                marginTop: isSmallDown ? pixelToRem(2) : pixelToRem(8),
                width: '100%'
            }}>
                <CustomDivider color={COLORS.grayBlueLight}/>
            </Box>
        </Box>
    )
}
type SearchBarProps = {
    textFieldProps?: TextFieldProps;
    searchIconProps?: SvgIconProps;
};

export default SearchBarFullWidth;

import { useMemo } from "react"
import { TextProps } from "../components/elements/Text"

export const useTextVariants = () => {

    return useMemo((): Array<TitleProps> => ([
        {
            label: 'Titre 1',
            variant: 'h1'
        },
        {
            label: 'Titre 2',
            variant: 'h2'
        },
        {
            label: 'Titre 3',
            variant: 'h3'
        },
        {
            label: 'Titre 4',
            variant: 'h4'
        },
        {
            label: 'Titre 5',
            variant: 'h5'
        },
        {
            label: 'Titre 6',
            variant: 'h6'
        },
        {
            label: 'Paragraphe',
            variant: 'body1'
        },
    ]), [])
}


type TitleProps = {
    label: string
    variant: TextProps['variant']
}


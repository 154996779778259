import { CSSProperties, useMemo } from "react"

export const useBorderStyles = () => {

    return useMemo((): Array<TitleProps> => ([
        {
            label: 'Aucun',
            value: 'hidden'
        },
        {
            label: 'Pointillé',
            value: 'dotted'
        },
        {
            label: '',
            value: 'dashed'
        },
        {
            label: 'Solide',
            value: 'solid'
        },
        {
            label: 'Double',
            value: 'double'
        },
        {
            label: 'Double',
            value: 'groove'
        },
        {
            label: 'Cadré',
            value: 'ridge'
        },
        {
            label: 'Intérieur',
            value: 'inset'
        },
        {
            label: 'Extérieur',
            value: 'outset'
        },
    ]), [])
}

type TitleProps = {
    label: string
    value: CSSProperties['borderStyle']
}


import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Statistic = React.lazy(() => import('./Statistic'))
const Routes: EphattaRouteProps[] = [
    {
        path: '/statistic',
        element: <Statistic />,
    },
]

export default Routes

import { EphattaRouteProps } from "@ephatta/components"
import React from "react"
import EmailHasBeenSent from "./EmailHasBeenSent";

const Login = React.lazy(() => import('./Login'));
const RegisterStepOne = React.lazy(() => import('./RegisterStepOne'));
const RegisterStepTwo = React.lazy(() => import('./RegisterStepTwo'));
const RegisterStepThree = React.lazy(() => import('./RegisterStepThree'));
const RegisterStepFour = React.lazy(() => import('./RegisterStepFour'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const Logout = React.lazy(() => import('./Logout'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/registration',
        element: <RegisterStepOne/>
    },
    {
        path: '/register-step-one',
        element: <RegisterStepTwo/>
    },
    {
        path: '/register-step-two',
        element: <RegisterStepThree/>
    },
    {
        path: '/register-step-three',
        element: <RegisterStepFour/>
    },
    {
        path: '/email-sent',
        element: <EmailHasBeenSent/>
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword/>
    },
    {
        path: '/reset-password',
        element: <ResetPassword/>
    },
    {
        path: '/logout',
        element: <Logout />,
    },
]

export default Routes
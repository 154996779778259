import { Article } from "@mui/icons-material"
import React, { useMemo } from "react"
import { Card } from "../components/elements/Card"
import { ToolboxItemProps } from "./useToolbox"

export const useToolboxCards = () => {

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Card',
            icon: <Article />,
            element: Card,
        },
    ]), [])
}


import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactDOM from 'react-dom'
import mapboxgl, { LngLatLike } from "mapbox-gl";
import _ from "lodash";
import { PopupEstablishment } from "./PopupEstablishment";
import { IconButton, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { GeocodingAdress } from "./GeocodingSearch";

const MapBox = ({ coordinate, currentCoordinate, currentZoom, ...props }: Props) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null)
    const [zoom, setZoom] = useState(currentZoom ? currentZoom : 12);
    const [initialData, setInitialData] = useState<LngLatLike>(currentCoordinate ? currentCoordinate : [9.1, 42.2]);
    const [coordinates, setCoordinates] = useState<Array<LngLatLike>>(props?.coordinates || [])
    const [mapInstance, setMapInstance] = useState<mapboxgl.Map>()
    const markers = useRef<Array<mapboxgl.Marker>>([])
    const theme = useTheme()

    useEffect(() => {

        if (!mapContainerRef.current) {
            return
        }

        // Initialize map when component mounts
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_TOKEN!
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: initialData,
            zoom: zoom,
        });
        map.addControl(new mapboxgl.NavigationControl(), "top-right");
        setMapInstance(map)

        return () => map.remove();
    }, [])

    useEffect(() => {
        if (!mapInstance) {
            return ;
        }

        if(currentCoordinate) {
            new mapboxgl.Marker({ color: theme.palette.primary.main }).setLngLat(currentCoordinate).addTo(mapInstance);
        }

        if (coordinate) {
            setInitialData(coordinate);
            setZoom(12);
            new mapboxgl.Marker({ color: theme.palette.primary.main }).setLngLat(coordinate).addTo(mapInstance);
        }
    }, [mapInstance])

    useEffect(() => {

        if (!mapInstance) {
            return ;
        }

        if(coordinates) {
            console.log('coordinates effect', coordinates)
            setInitialData(coordinates[0]);
            setZoom(13);
            markers.current.forEach((item) => item.remove())
            coordinates?.map(coordinateItem => {
                const popupElt = document.createElement('div');
                const foundEstablishment = findEstablishment(coordinateItem[0], coordinateItem[1])

                ReactDOM.render(<PopupEstablishment {...foundEstablishment}/>, popupElt)
                const popup = new mapboxgl.Popup({maxWidth: "auto"}).setDOMContent(popupElt);
                
                const marker = new mapboxgl.Marker({ color: "#e0612e" })
                    .setLngLat(coordinateItem)
                    .setPopup(popup)
                    .addTo(mapInstance);

                markers.current.push(marker)

                mapInstance.setCenter(coordinateItem)
            })
        }
    }, [coordinates, mapInstance])

    useEffect(() => {

        if (!_.isEqual(props.coordinates, coordinates)) {
            setCoordinates(props.coordinates || [])
        }

    }, [props.coordinates])

    const findEstablishment = useCallback((long: number, lat: number) => {
        return props?.establishments?.find(item => item.longitude === long && item.latitude === lat)
    }, [props.establishments])

    return (
        <>
            <div
                {...props}
                className={`map-container ${props.className}`}
                style={{ width: "300px", height: "300px", marginTop: "20px", ...props.style }}
                ref={mapContainerRef}
            />
            <div ref={popupRef}>
            </div>
            {
                props?.hasCloseButton && (
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundColor: 'white',
                            marginLeft: 10,
                            borderRadius: '10%',
                            width: 35,
                            height: 35,
                        }}
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <Close/>
                    </IconButton>
                )
            }
        </>
    );
};

export default MapBox

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    coordinate?: LngLatLike
    coordinates?: Array<LngLatLike>
    currentCoordinate?: LngLatLike
    establishments?: Array<GeocodingAdress>
    currentZoom?: number
} & CloseBtnProps

type CloseBtnProps = 
    | { hasCloseButton?: false, onClose?: never }
    | { hasCloseButton?: true, onClose?: () => void }


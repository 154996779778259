
/**
 * Hooks to update document metadata
 */
export const useMetadata = () => {

    const setDocumentTitle = (value: string) => {
        document.title = value
    }

    const setMetadata = (key: string, value: string, type = 'name') => {
        const el = document.querySelector(`meta[${type}="${key}"]`)
        el && el.setAttribute("content", value)
    }

    const setDocumentMetadata = (obj: MetadataProps) => {
        if (obj) {
            obj.title && setDocumentTitle(obj.title)
            obj.title && setMetadata('og:title', obj.title, 'property')
            setMetadata('description', obj.description || '')
            setMetadata('og:description', obj.description || '')
            setMetadata('og:url', obj.path)
            setMetadata('og:type', obj.type || '')
            setMetadata('og:keywords', obj.keywords || '')
        }
    }

    return {
        setDocumentMetadata,
        setMetadata
    }
}

export type MetadataProps = {
    title?: string
    description?: string
    path: string
    type?: string
    keywords?: string
}

import { useMemo } from "react"

export const useContainerBackgroundImageRepeat = () => {

    return useMemo(() => {
        return [
            { value: 'repeat', label: 'Répéter' },
            { value: 'repeat-x', label: 'Répéter-X' },
            { value: 'repeat-y', label: 'Répéter-Y Bas' },
            { value: 'no-repeat', label: 'Pas de répétition' },
            { value: 'initial', label: 'Initial' },
            { value: 'inherit', label: 'Hérité' },
            { value: 'unset', label: 'Non défini' },
        ]
    }, [])
}

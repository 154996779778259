import {Box, Container, Divider, Stack, Typography, useMediaQuery} from '@mui/material';
import {usePixelToRem} from "../../../hooks/design/usePixelToRem";
import {COLORS} from "../../../config/Colors";
import {CustomStandardButton} from "../../standard/button/CustomStandardButton";
import {DownloadIcon} from "../../icons/basic/unclassified";
import {CabinetSecretLogoWithText} from "../../icons/logo/cabinet-secret-logo-icon";
import LinkCustomed from "../../standard/link/LinkCustomed";
import TypoVariantLink from "./footer-extra/TypoVariantLink";
import {FacebookIcon, InstagramIcon, LinkedinIcon, YoutubeIcon} from "../../icons/basic/socials-icon";
import {useTheme} from "@mui/styles";
import MobileContainer from "../../../pages/home/components/MobileContainer";
import useFooterWebsiteLink from "../hooks/useFooterWebsiteLink";
import {useTranslations} from "@ephatta/components";
import { useNavigate } from "react-router-dom";
import { useEnv } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";
import ContactModal from "./../components/ContactModal";
import * as serviceWorker from '../../../serviceWorker';
const FooterSection = () => {
    const env = useEnv<TClientIdentity | null>();
    console.log(env?.userId);
    const { pixelToRem } = usePixelToRem();

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    const {FOOTER_LINKS} = useFooterWebsiteLink();

    const [
        DOWNLOAD_MOBILE_APP_LINK,
        CONTACT_ME_LINK,
        FOLLOW_US_LINK,
    ] = useTranslations(i18nKeys)
    const navigate = useNavigate();

    const handleApps = (index) => {
        serviceWorker.installPwa();
    };
    return (
        <Container maxWidth={'lg'}>
            <Box
                paddingTop={isMediumDown ? pixelToRem(11.71) : pixelToRem(41)}
                paddingBottom={isMediumDown ? pixelToRem(19) : pixelToRem(45)}
            >
                <Stack
                    direction={'column'}
                    spacing={pixelToRem(41)}
                    divider={
                        <Divider sx={{
                            width: '100%',
                            backgroundColor: COLORS.black,
                            height: pixelToRem(1),
                            border: 'none'
                        }}/>
                    }
                >
                    {/* Download mobile application button */}
                    {
                        !isMediumDown &&
                        <Box>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <CustomStandardButton
                                onClick={handleApps}
                                    height={{desktop: pixelToRem(54), mobile: 'auto'}}
                                    width={{desktop: pixelToRem(553), mobile: 'auto'}}
                                    variant={'contained'}
                                    fontSize={{desktop: pixelToRem(14), mobile: pixelToRem(12)}}
                                    startIcon={{
                                        iconSpacing: {desktop: pixelToRem(17), mobile: pixelToRem(12)},
                                        svgIcon: <DownloadIcon/>,
                                        height: {desktop: pixelToRem(17), mobile: pixelToRem(17)},
                                        width: {desktop: pixelToRem(20), mobile: pixelToRem(20)}
                                    }}
                                    buttonColor={{
                                        textColor: {
                                            normal: COLORS.white,
                                            hover: COLORS.chinaRose,
                                        },
                                        bg: {
                                            normal: COLORS.chinaRose,
                                            hover: COLORS.white,
                                        },
                                        borderedOnHover: true
                                    }}
                                >
                                    { DOWNLOAD_MOBILE_APP_LINK }
                                </CustomStandardButton>
                            </Stack>
                        </Box>
                    }

                    {/* Different links */}
                    <Box>
                        <MobileContainer>
                            <Stack
                                direction={isMediumDown ? 'column' : 'row'}
                                alignItems={'flex-start'}
                                justifyContent={'space-between'}
                                spacing={isMediumDown ? pixelToRem(17) : undefined}
                            >
                                {/* Agreement section  */}
                                <Box
                                    width={isMediumDown ? '100%' : pixelToRem(300) }
                                >
                                    <Stack
                                        direction={'column'}
                                        spacing={isMediumDown ? pixelToRem(25.44) : pixelToRem(26.57)}
                                    >
                                        <Box>
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                justifyContent={isMediumDown ? 'center' : 'flex-start'}
                                                onClick={(event) => navigate("/")}
                                            >
                                                <CabinetSecretLogoWithText
                                                    textProps={{
                                                        spaceRelativeToTheLogo: {
                                                            desktop: pixelToRem(28.17),
                                                            mobile: pixelToRem(24.47)
                                                        },
                                                        fontSize: {
                                                            desktop: pixelToRem(20),
                                                            mobile: pixelToRem(20)
                                                        },
                                                        lineHeight: {
                                                            desktop: pixelToRem(24),
                                                            mobile: pixelToRem(24)
                                                        },
                                                        color: COLORS.white,
                                                    }}
                                                    logoType={'dark'}/>
                                            </Stack>
                                        </Box>
                                        <Box>
                                            <Stack
                                                spacing={pixelToRem(10)}
                                            >
                                                {
                                                    FOOTER_LINKS.agreementSection.map((link, index) => {
                                                        return (
                                                            <LinkCustomed
                                                                to={link.link}
                                                                style={{
                                                                    textDecoration: 'none',
                                                                }}
                                                                key={index}
                                                            >
                                                                <TypoVariantLink>
                                                                    {link.text}
                                                                </TypoVariantLink>
                                                            </LinkCustomed>
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>

                                {
                                    !isMediumDown &&
                                    <>
                                        {/* Website link */}
                                        <Box>
                                            <Stack
                                                direction={'row'}
                                                spacing={pixelToRem(85)}
                                            >
                                                {
                                                    FOOTER_LINKS.websiteLinks.slice(0, 2).map((link, index) => {
                                                        return (
                                                            <Box
                                                                key={index}
                                                                sx={{
                                                                    minWidth: pixelToRem(136),
                                                                }}
                                                            >
                                                                <Stack
                                                                    direction={'column'}
                                                                    spacing={pixelToRem(15)}
                                                                >
                                                                    <Typography
                                                                        variant={'subtitle2'}
                                                                        fontWeight={'700'}
                                                                        color={COLORS.white}
                                                                    >
                                                                        {link.title}
                                                                    </Typography>
                                                                    <Box>
                                                                        <Stack
                                                                            direction={'column'}
                                                                            spacing={pixelToRem(10)}
                                                                        >
                                                                            {
                                                                                link.children.map((child, index) => {
                                                                                    return (
                                                                                        <LinkCustomed
                                                                                            to={child.link}
                                                                                            style={{
                                                                                                textDecoration: 'none',
                                                                                            }}
                                                                                            key={index}
                                                                                        >
                                                                                            <TypoVariantLink>
                                                                                                {child.text}
                                                                                            </TypoVariantLink>
                                                                                        </LinkCustomed>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Stack>
                                                                    </Box>
                                                                </Stack>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </Box>

                                        {/* Community link */}
                                        <Box>
                                            {
                                                FOOTER_LINKS.websiteLinks.slice(2, 3).map((link, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                minWidth: pixelToRem(160),
                                                            }}
                                                        >
                                                            <Stack
                                                                direction={'column'}
                                                                spacing={pixelToRem(15)}
                                                            >
                                                                <Typography
                                                                    variant={'subtitle2'}
                                                                    fontWeight={'700'}
                                                                    color={COLORS.white}
                                                                >
                                                                    {link.title}
                                                                </Typography>
                                                                <Box>
                                                                    <Stack
                                                                        direction={'column'}
                                                                        spacing={pixelToRem(10)}
                                                                    >
                                                                        {
                                                                            link.children.map((child, index) => {
                                                                                return (
                                                                                    <LinkCustomed
                                                                                        to={child.link}
                                                                                        key={index}
                                                                                    >
                                                                                        <TypoVariantLink>
                                                                                            {child.text}
                                                                                        </TypoVariantLink>
                                                                                    </LinkCustomed>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Stack>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                }
                                {/* Social media links and contact */}
                                <Box
                                    width={ isMediumDown ? '100%' : 'auto' }
                                >
                                    <Stack
                                        direction={'column'}
                                        spacing={isMediumDown ? pixelToRem(17) : pixelToRem(44)}
                                    >
                                        <ContactModal title={CONTACT_ME_LINK}></ContactModal>
                                       

                                        <Box>
                                            <Stack
                                                direction={'column'}
                                                spacing={pixelToRem(12.63)}
                                                alignItems={'center'}
                                            >
                                                <Typography
                                                    variant={'subtitle2'}
                                                    color={COLORS.black}
                                                    textAlign={'center'}
                                                >
                                                    { FOLLOW_US_LINK }
                                                </Typography>
                                                <Box>
                                                    <Stack
                                                        direction={'row'}
                                                        spacing={pixelToRem(40.5)}
                                                        alignItems={'center'}
                                                    >
                                                        <a href={'https://www.instagram.com/plume.dhistoire/'} target={'_blank'} rel="noreferrer">
                                                            <InstagramIcon/>
                                                        </a>
                                                        <a href={'https://www.facebook.com/Plumedhistoire/'} target={'_blank'} rel="noreferrer">
                                                            <FacebookIcon/>
                                                        </a>
                                                        <a href={'https://www.youtube.com/channel/UCAA9BJ-i-PLnoNazE0N53WA'} target={'_blank'} rel="noreferrer">
                                                            <YoutubeIcon/>
                                                        </a>
                                                        <a href={'https://www.linkedin.com/'} target={'_blank'} rel="noreferrer">
                                                            <LinkedinIcon/>
                                                        </a>
                                                        
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </MobileContainer>
                    </Box>
                </Stack>
            </Box>
        </Container>
    )
}


const i18nKeys = [
    "Home.Page.Footer.Button.Download.MobileApplication.Label",
    "Home.Page.Footer.Button.ContactMe.Label",
    "Home.Page.Footer.Follow.Label"
]

export default FooterSection;

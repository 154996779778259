import { useMemo } from "react"

export const useJustifyContent = () => {

    return useMemo(() => ([
        {
            value: 'stretch',
            label: 'Etiré',
        },
        {
            value: 'flex-start',
            label: 'Alignée à gauche',
        },
        {
            value: 'center',
            label: 'Centrée',
        },
        {
            value: 'space-between',
            label: 'Espacée au millieu',
        },
        {
            value: 'space-around',
            label: 'Espace autour',
        },
        {
            value: 'space-evenly',
            label: 'Espace equitable',
        },
        {
            value: 'flex-end',
            label: 'Alignée à droite'
        }
    ]), [])
}

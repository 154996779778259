import React, { ReactElement } from "react";
import {Swiper, SwiperProps, SwiperSlide} from "swiper/react";
import {EffectCards, EffectCoverflow, EffectFade, Grid, Navigation, Pagination} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Box } from "@mui/material";

export const ContainerSwiper = ({ children, ...props }: ContainerSwiperProps) => {

    console.log('ContainerSwiper', props)

    return (
        <Box width="100%">

            <Swiper
                {...props}
                modules={[Pagination, Navigation, Grid, EffectFade, EffectCards, EffectCoverflow]}
            >
                {
                    children?.map((item: ReactElement, index: number) => (
                        <SwiperSlide key={index + 1}>
                            {item}
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </Box>
    )
}

export const ContainerSwiperDefaultProps: Partial<ContainerSwiperProps> = {
    slidesPerView: 1,
    grid: { rows: 1 },
    navigation: true,
    allowSlideNext: true,
    allowSlidePrev: true,
    centeredSlides: false,
    loop: false,
    autoplay: false,
    pagination: true
}

export type ContainerSwiperProps = Omit<SwiperProps, 'children'> & {
    children: Array<ReactElement>
} 

export const ContainerDefaultProps: SwiperProps = {
    
}


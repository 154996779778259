import React, { createRef, useEffect, useRef } from "react";
import { useEditor, useNode } from "@craftjs/core";
import { Typography, TypographyProps } from "@mui/material";
import ContentEditable  from "react-contenteditable"
import { TextProperty } from "../property/TextProperty";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container";
import { BreakpointProperty } from "../property/BreakpointProperty";

export const Text = (props: TextProps) => {

    const {
        id,
        setProp,
    } = useNode();
    
    const innerRef = createRef<HTMLElement>()
    const {text, ...rest} = props;
    const { enabled, isActive } = useEditor((state, query) => ({
        isActive: query.getEvent('selected').contains(id),
        enabled: state.options.enabled,
    }));

    const textValue = useRef<string>(text)

    useEffect(() => {
        if (!isActive) {
            save()
        }
    }, [isActive])

    const handleBlur = () => {
        save()
    }

    const save = () => {
        setProp((newProps:any) =>{
            newProps.text = textValue.current
            props.onChange?.(newProps.text)
        })
    }
    
    const renderText = () => {
        return (
            <Typography {...rest}>
                <ContentEditable
                    disabled={!enabled}
                    html={textValue.current}
                    onChange={e => {
                        textValue.current = e.target.value
                    }}
                    onBlur={handleBlur}
                    tagName="span"
                    innerRef={innerRef}
                    style={{
                        width: '100%',
                        outline: '0px solid transparent'
                    }}
                    />
            </Typography>
        )
    }

    if (!props.draggable) {
        return renderText()
    }

    return (
        <Container container={props.container}>
            { renderText() }
        </Container>
    )
}

Text.craft = {
    displayName: 'Text',
    props: {
        draggable: true,
        variant: 'h1',
        fontWeight: 'bold',
        container: ContainerDefaultProps
    },
    related: {
        toolbar: TextProperty,
        breakpoint: BreakpointProperty
    },
};
 
export type TextProps = TypographyProps & {
    text: string
    draggable?: boolean
    container?: ContainerProps
    onChange?: (value: string) => void
}

import { useMediaQuery, useTheme } from "@mui/material"
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useEditorEnabled } from "./useEditorEnabled";
import { useScreenBreakPoint } from "./useScreenBreakPoint";

export const useBreakpointMatch = <TData = Record<string, any>>(props: TData) => {

    const theme = useTheme();
    const enabled = useEditorEnabled()
    const [breakpoint] = useScreenBreakPoint()
    // const downXl = useMediaQuery(theme.breakpoints.down('xl'))
    const downLg = useMediaQuery(theme.breakpoints.down('lg'))
    const downMd = useMediaQuery(theme.breakpoints.down('md'))
    const downSm = useMediaQuery(theme.breakpoints.down('sm'))

    const extendObj = useCallback((defaults: Record<string, any>, obj: Record<string, any>): Record<string, any> => {

        if (!obj) {
            return defaults
        }

        return Object.entries(defaults).reduce<Record<string, any>>((all, item) => {
            const [key, value] = item
            if (obj[key] === undefined) {
                all[key] = value
                return all
            }

            if (typeof value === 'object') {
                return {
                    ...all,
                    ...extendObj(all[key], obj[key])
                }
            }

            return all
        }, obj)
    }, [])

    const base = useMemo(() => {
        return _.omit(_.cloneDeep(props) as any, breakpoint)
    }, [props, breakpoint])

    const propsBreakpoint = useMemo(() => {
        return _.cloneDeep(props[breakpoint])
    }, [props, breakpoint])

    if (enabled && breakpoint) {
        return _.merge(base, propsBreakpoint)
    }
    
    if (downSm) {
        return _.merge(base, props['xs'])
    }

    if (downMd) {
        return _.merge(base, props['sm'])
    }

    if (downLg) {
        return _.merge(base, props['md'])
    }

    // if (downXl) {
    //     return extendObj(props, props['lg'])
    // }

    return props
}

import { Computer, DesktopMac, PhoneIphone, TabletMac } from "@mui/icons-material"
import React, { useMemo } from "react"

export const useScreenBreakpointOptions = () => {

    return useMemo(() => {
        return [
            { label: 'Par défaut', value: 'xl', icon: (<DesktopMac />) },
            { label: 'Desktop', value: 'md', icon: (<Computer /> ) },
            { label: 'Tablets', value: 'sm', icon: (<TabletMac />) },
            { label: 'Mobile', value: 'xs', icon: (<PhoneIphone />) }
        ]
    }, [])
}

import { Button } from '../components/elements/Button'
import { Card } from '../components/elements/Card'
import { Container } from '../components/elements/Container'
import { Image } from '../components/elements/Image'
import { List } from '../components/elements/List'
import { Text } from '../components/elements/Text'
import { VideoYoutube } from '../components/elements/VideoYoutube'
import { BreakpointProperty } from '../components/property/BreakpointProperty'

export const useElementResolver = () => {
    return {
        Container,
        Text,
        Card,
        VideoYoutube,
        Image,
        Button,
        List,
        BreakpointProperty,
    }
}

import { useEphattaQuery } from "hooks/utils"
import { useLandingPageServices } from "./useLandingPageServices"

export const useGetAllLandingByUrl = (url : string) =>{
    const services = useLandingPageServices()
    return useEphattaQuery(
        ['Landing.GetAll.ByUrl',url],
        () => services.getAllByUrl(url) ,
        {}
    )
}
import { useEphattaMutation, useTranslations } from "../.."
import { useLoginService } from "./useLoginService"

export const useForgotPasswordRequest = () => {
    const [
        ERROR_OCCURED,
        SUCCESS_MESSAGE
    ] = useTranslations(i18n)

    const loginService = useLoginService()

    return useEphattaMutation((email: string) => loginService.forgotPassword({
        email,
        applicationId: parseInt(process.env.REACT_APP_ID!)
    }),{
        successMessage: SUCCESS_MESSAGE,
        errorMessage : ERROR_OCCURED,
    })
}

const i18n = [
    "Login.Page.Login.LoginErrorMessage",
    "Login.Page.ForgotPassword.SuccessMessage"
]
import {Box, CssBaseline} from "@mui/material"
import { Outlet } from "react-router";
import { MainLoader, GenericSuspenseWrapper } from "@ephatta/components";
import { Header } from "./components/Header";
import FooterSection from "./components/FooterSection";
import {COLORS} from "../../config/Colors";

export const StaticPageLayout = () => {

    return (
        <>
           <CssBaseline />
            <Header />
            <GenericSuspenseWrapper fallback={<MainLoader />}>
                <Outlet />
            </GenericSuspenseWrapper>

            <Box
                sx={{
                    backgroundColor: COLORS.aztecGold,
                    position: 'relative',
                    zIndex: 99
                }}>
                <FooterSection/>
            </Box>
        </>
    );
}

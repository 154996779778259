import { useMemo } from "react"

export const useContainerSwiperDirection = () => {

    return useMemo(() => {
        return [
            { value: 'horizontal', label: 'Horizontale' },
            { value: 'vertical', label: 'Verticale' },
        ]
    }, [])
}

import React, { useCallback, useEffect } from 'react'
import { useNode } from "@craftjs/core";
import { MenuItem, Select, Switch, TextField } from "@mui/material";
import { useButtonColors } from "components/craft-editor/hooks/useButtonColors";
import { useButtonVariants } from "components/craft-editor/hooks/useButtonVariants";
import { PropertyItemTitle } from "./PropertyItemTitle";
import { ContainerProperty } from './ContainerProperty';
import { useBreakpoint } from 'components/craft-editor/hooks/useBreakpoint';
import { PropertyContainer } from './PropertyContainer';
import { SquareProperty } from './SquareProperty';

export const ButtonProperty = (props: Props) => {

    const {
        actions: { setProp },
        button,
    } = useNode((node) => ({
        button: node.data.props?.button,
    }));

    const { getValue, setValue } = useBreakpoint()

    useEffect(() => {
        if (!button) {
            setProp((props: any) => props.button = {})
        }
    }, [])

    const handleButtonChange = useCallback((key: string, value?: any) => {
        setValue((props: any) => {
            if (props?.button === undefined) {
                props.button = {}
            }

            props.button[key] = value
            return props
        })

    }, [])

    const variants = useButtonVariants()
    const colors = useButtonColors()

    return (
        <>
            {
                props.hideContainerProperty !== true &&
                <ContainerProperty hideSwiper hideMargin hidePadding />
            }

            <PropertyContainer title="Bouton">
                <PropertyItemTitle>Variant</PropertyItemTitle>
                <Select
                    fullWidth
                    label="Variant"
                    value={getValue('button.variant')}
                    onChange={(e) => handleButtonChange('variant', e.target.value)}>
                    {
                        variants.map((item, index) => (
                            <MenuItem key={index} value={item.variant}>{item.label}</MenuItem>
                        ))
                    }
                </Select>

                <PropertyItemTitle>Couleur</PropertyItemTitle>
                <Select
                    fullWidth
                    label="Couleurs"
                    value={getValue('button.color')}
                    onChange={(e) => handleButtonChange('color', e.target.value)}>
                    {
                        colors.map((item, index) => (
                            <MenuItem key={index} value={item.color}>{item.label}</MenuItem>
                        ))
                    }
                </Select>

                <PropertyItemTitle>Url</PropertyItemTitle>
                <TextField
                    fullWidth
                    value={getValue('button.url')}
                    onChange={(e) => handleButtonChange('url', e.target.value)}
                    placeholder="Largeur de la bloc"
                />

                <PropertyItemTitle>Ouvir une nouvelle onglet</PropertyItemTitle>
                <Switch
                    value={getValue('button.targetBlank')}
                    onChange={(e) => handleButtonChange('targetBlank', e.target.value)}
                />

                <PropertyItemTitle>Marge extérieur</PropertyItemTitle>
                <SquareProperty
                    value={getValue('button.margin')}
                    onChange={(value) => handleButtonChange('margin', value)}
                />

                <PropertyItemTitle>Marge intérieur</PropertyItemTitle>
                <SquareProperty
                    value={getValue('button.padding')}
                    onChange={(value) => handleButtonChange('padding', value)}
                />

                <PropertyItemTitle>Rondeur de la bordure</PropertyItemTitle>
                <TextField
                    fullWidth
                    value={getValue('container.borderRadius')}
                    onChange={(e) => handleButtonChange('borderRadius', e.target.value)}
                />
            </PropertyContainer>
        </>
    )
}

type Props = {
    hideContainerProperty?: boolean
}

import _ from "lodash";
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const useFormData = <TData = any>(props: Props<TData>): UseFormData<TData> => {

    const {t} = useTranslation()
    const [formData, setFormData] = useState(props.formData);
    const [errors, setErrors] = useState<ErrorType>()
    const handleInputChange = (key: string, value: any) => {
        setFormData((v) => ({ ...v, [key]: value }))
    }


    const evalErrors = useCallback(() => {
        let _errors: ErrorType = {}
        Object.keys(formData).forEach((key) => {
            const value = formData[key]
            const error = props.validate?.(key, value, formData)
            if (error) {
                _errors[key] = error
            }
        })

        props.required?.forEach((key) => {
            const value = formData[key]
            if (value === null || value === undefined || value === '') {
                _errors[key] = t(requiredErrorMessage)
            }
        })

        return _errors
    }, [formData])

    const isValid = (render: boolean = true): boolean => {
        const _errors = evalErrors()
        render && setErrors(_errors)
        return _.isEmpty(_errors)
    }

    const getTextFieldProps = useCallback((name: string): FieldProps => {
        return {
            name,
            error: errors?.[name] !== undefined,
            helperText: errors?.[name],
            value: formData[name] || '',
            required: props.required?.includes(name),
            onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
                if (checked !== undefined) {
                    return handleInputChange(name, checked)
                }
                handleInputChange(name, e.target.value)
            }
        }

    }, [errors, formData])

    
    const getError = useCallback((name: string) => {
        return errors?.[name]
    }, [errors])

    const hasError = useMemo(() => {
        return !_.isEmpty(evalErrors())
    }, [evalErrors])

    return {
        formData,
        setFormData,
        handleInputChange,
        isValid,
        getTextFieldProps,
        errors,
        getError,
        hasError
    }
}

const requiredErrorMessage = 'Generic.FormData.RequiredField.label'

type Props<TData = any> = {
    formData: TData,
    /**
     * Array that contain all required fields
     */
    required?: Array<string>
    /**
     * Function that check if the entiere form is valid
     * @param name Field name
     * @param value field value
     * @param formData all form data
     * @returns The new error message
     */
    validate?: (name: string, value: any, formData?: TData) =>  string | React.ReactNode | undefined | void
}

export type UseFormData<TData> = {
    formData: TData
    setFormData: Dispatch<SetStateAction<TData>>
    /** Callback that will set form hooks global value */
    handleInputChange: (key: string, value: any) => void

    getTextFieldProps: (name: string) => FieldProps
    /**
     * Function that will check if formData is valid
     * It will check if required field is empty and show error
     * Then will execute "validate" function
     */
    isValid: () => boolean

    errors?: ErrorType

    getError: (name: string) => string | React.ReactNode | undefined

    hasError?: boolean
}

type FieldProps = {
    name: string
    value: any
    error?: boolean
    required?: boolean
    helperText?: string | React.ReactNode,
    onChange?: ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => void)
        | ((e: React.ChangeEvent<HTMLInputElement>, value?: string) => void)
        | ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
}

type ErrorType = Record<string, string | ReactNode>

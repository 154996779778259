import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const SubscribeOne = React.lazy(() => import('./SubscribeOne'))
const SubscribeTwo = React.lazy(() => import('./SubscribeTwo'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/subscribe',
        element: <SubscribeOne/>,
    },
    {
        path: '/subscription',
        element: <SubscribeTwo/>,
    }
]

export default Routes

import { TCustomerInboxDetails } from "@ephatta/services"
import { useEphattaQuery } from "../../.."
import { useCustomerServices } from ".."


export const useCustomerInboxId = (userId: string) => {
    const services = useCustomerServices()
    return useEphattaQuery<TCustomerInboxDetails| null>(
        ["Customers.Customer.Inbox.Id", userId],
        () => services.getCustomerInboxId(userId),
        {
            enabled: !!userId
        }
    )
}
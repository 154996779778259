import {Container, ContainerProps, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/styles";
import {usePixelToRem} from "../../../hooks/design/usePixelToRem";

const MobileContainer = (props: ContainerProps & {noPaddingLeft?: boolean, noPaddingRight?: boolean}) => {

    const  { pixelToRem } = usePixelToRem();

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    return (
        <Container
            { ...props }
            sx={{
                paddingLeft: isMediumDown && !(props.noPaddingLeft) ? `${pixelToRem(12)} !important` : 0,
                paddingRight: isMediumDown && !(props.noPaddingRight) ? `${pixelToRem(12)} !important` : 0,
            }}
        >
            { props.children }
        </Container>
    )
}

export default MobileContainer;
import React from 'react'
import { Button, ButtonTypeMap, CircularProgress } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"

export const ButtonSubmit = (props: Props) => {

    return (
        <Button disabled={props.isSubmit} {...props}>

            {
                props.isSubmit &&
                <React.Fragment>
                    {
                        props.Spinner
                        ? props.Spinner
                        : <CircularProgress size={24} />
                    }
                </React.Fragment>
            }

            {
                !props.isSubmit && props.children
            }
        </Button>
    )
}

export type ButtonSubmitProps = Props

type Props = GenericButton & {
    isSubmit?: boolean
    /** Custom spinner to override default spinner */
    Spinner?: React.ReactElement
}

export type GenericButton = DefaultComponentProps<ButtonTypeMap<{}, "button">> & {
    /** Text inside the button */
    label?: string
}

import {ThemeOptions} from "@mui/material";
import {COLORS} from "./Colors";


const palette= {
    primary: {
        main: COLORS.aztecGold,
    },
    secondary: {
        main: COLORS.chinaRose,
    },
    text: {
        primary: COLORS.aztecGold,
        secondary: COLORS.white,
    },
}

const MEDIUM_DEVICE_DOWN = '@media only screen and (max-width: 933px)'

const SMALL_DEVICE_DOWN = '@media only screen and (max-width: 600px)'

const LARGE_DEVICE_UP = '@media only screen and (min-width: 933px)'

export const Theme: ThemeOptions = {
    palette: {
        error: {main: COLORS.red},
        ...palette,
    },
    typography: {
        fontFamily: [
            "Ubuntu",
            "Poppins",
            "Dancing Script",
            "sans-serif"
        ].join(","),
        fontSize: 16,
        h2: {
            fontFamily: 'Dancing Script',
            fontWeight: '400',
            /* Medium devices */
            [MEDIUM_DEVICE_DOWN]: {
                fontSize: '1.562rem', // 25px
            },
            /* Small devices */
            [SMALL_DEVICE_DOWN]: {
                fontSize: '1.1875rem', // 19px
            },
            /* Large devices and up */
            [LARGE_DEVICE_UP]: {
                fontSize: '1.5625rem', // 25px
            },
        },
        h3: {
            textTransform: 'uppercase',
            fontWeight: '700',
            lineHeight: "1.5rem",
            /* Medium devices */
            [MEDIUM_DEVICE_DOWN]: {
                fontSize: '1rem', // 16px
            },
            /* Small devices */
            [SMALL_DEVICE_DOWN]: {
                fontSize: '0.875rem', // 14px
            },
            /* Large devices and up */
            [LARGE_DEVICE_UP]: {
                fontSize: '1.125rem', // 18px
            },
        },
        h4: {
            textTransform: 'uppercase',
            fontWeight: '500',
            /* Medium devices */
            [MEDIUM_DEVICE_DOWN]: {
                fontSize: '1rem', // 16px
            },
            /* Small devices */
            [SMALL_DEVICE_DOWN]: {
                fontSize: '0.875rem', // 14px
            },
            /* Large devices and up */
            [LARGE_DEVICE_UP]: {
                fontSize: '1rem', // 18px
            },
        },
        h5: {
            textTransform: 'uppercase',
            fontWeight: '500',
            /* Medium devices */
            [MEDIUM_DEVICE_DOWN]: {
                fontSize: '0.875rem', // 14px
            },
            /* Small devices */
            [SMALL_DEVICE_DOWN]: {
                fontSize: '0.75rem', // 12px
            },
            /* Large devices and up */
            [LARGE_DEVICE_UP]: {
                fontSize: '0.875rem', // 14px
            },
        },
        h6: {
            fontWeight: '700',
            /* Medium devices */
            [MEDIUM_DEVICE_DOWN]: {
                fontSize: '0.875rem', // 14px
            },
            /* Small devices */
            [SMALL_DEVICE_DOWN]: {
                fontSize: '0.75rem', // 12px
            },
            /* Large devices and up */
            [LARGE_DEVICE_UP]: {
                fontSize: '0.875rem', // 14px
            },
        },
        body1: {
            fontFamily: 'Poppins',
            fontSize: '0.875rem', // 14px
            fontWeight: '400'
        },
        body2: {
            fontFamily: 'Poppins',
            fontSize: '0.75rem', // 12px
            fontWeight: '400'
        },
        /**
         * Element uses this variant:
         *  - Thematic card title
         */
        subtitle1: {
            textTransform: 'uppercase',
            fontWeight: '700',
            /* Medium devices */
            [MEDIUM_DEVICE_DOWN]: {
                fontSize: '0.875rem', // 14px
            },
            /* Small devices */
            [SMALL_DEVICE_DOWN]: {
                fontSize: '0.875rem', // 14px
            },
            /* Large devices and up */
            [LARGE_DEVICE_UP]: {
                fontSize: '1rem', // 16px
            },
        },
        subtitle2: {
            fontFamily: 'Poppins',
            fontWeight: '400',
            /* Medium devices */
            [MEDIUM_DEVICE_DOWN]: {
                fontSize: '0.9375rem', // 15px
            },
            /* Small devices */
            [SMALL_DEVICE_DOWN]: {
                fontSize: '0.75rem', // 12px
            },
            /* Large devices and up */
            [LARGE_DEVICE_UP]: {
                fontSize: '0.9375rem', // 15px
            },
        },
        button: {
            fontSize: '0.875rem',
            lineHeight: '1rem',
            fontWeight: 700,
            padding: '0.625rem'
        },
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 0,
            sm: 600,
            md: 933,
            lg: 1300,
            xl: 1355,
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: '0px! important',
                }
            }
        },
        MuiPaper: {},
        MuiCssBaseline:{
            styleOverrides:{
                    '& .center-column':{
                        display:'flex',
                        justifyContent:'center'
                    },
                    "& .font-size12to16":{
                        [SMALL_DEVICE_DOWN]:{
                            fontSize : "0.75rem"
                        },
                        [MEDIUM_DEVICE_DOWN]:{
                            fontSize: "1rem"
                        }
                    },
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.primary.main,
                }
            }
        },
        MuiDialog:{
            styleOverrides:{
                root:{
                    '& .MuiDialogContentText-root':{
                        color:'black'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    /* Medium devices */
                    [MEDIUM_DEVICE_DOWN]: {
                        fontSize: '0.875rem', // 15px
                    },
                    /* Small devices */
                    [SMALL_DEVICE_DOWN]: {
                        fontSize: '0.75rem', // 12px
                    },
                    /* Large devices and up */
                    [LARGE_DEVICE_UP]: {
                        fontSize: '0.875rem', // 14px
                    },
                    width: '100%',
                    height: '3rem', // 48px
                    paddingLeft: '1.125rem', // 18px
                    paddingRight: '0.40625rem', // 6.5px
                    borderRadius: '0.625rem', // 5px
                    backgroundColor: COLORS.white,
                    '& fieldset': {
                        borderColor: COLORS.grayBlue,
                    },
                    // Input multiline style
                    '&.MuiInputBase-multiline': {
                        height: 'auto',
                    },
                    // Style on focused
                    '&.Mui-focused input': {
                        color: COLORS.black,
                    },
                    '&.Mui-focused .MuiInputAdornment-positionStart ': {
                        '& path': {
                            fill: COLORS.aztecGold,
                        }
                    },
                    // Custom styles on error
                    '&.Mui-error': {
                        '& input': {
                            color: COLORS.red,
                        },
                        '&.MuiOutlinedInput-notchedOutline': {
                            color: COLORS.red,
                        },
                        '.MuiInputAdornment-positionStart ': {
                            '& path': {
                                fill: COLORS.red,
                            }
                        },
                    },

                    // Secondary styles
                    '&.MuiInputBase-colorSecondary': {
                        '&:hover': {
                            borderColor: COLORS.chinaRose,
                            '& fieldset': {
                                borderColor: COLORS.chinaRose,
                            }
                        }
                    },

                    '& .MuiSelect-outlined': {
                        fontWeight: '700',
                    },
                    '&.border-gray-color': {
                        '& fieldset': {
                            borderColor: COLORS.gray,
                        },
                        height: '2.125rem', // 34px
                    }
                },
                input: {
                    color: 'black',
                    paddingLeft: 0
                }
            }
        },
        MuiTextField:{
            
            styleOverrides:{
                root:{
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        display:'none'
                    },
                    [MEDIUM_DEVICE_DOWN]: {
                        // border:`1px solid ${COLORS.white}`, TODO: Mba amboaro azafady fa misy olana ho any page hafa efa validé
                        borderRadius: '0px!important'
                    },
                    /* Large devices and up */
                    [LARGE_DEVICE_UP]: {
                        // border:`1px solid ${COLORS.white}` TODO: Mba amboaro azafady fa misy olana ho any page hafa efa validé
                    },
                    "&.china-rose-input":{
                        '& .MuiOutlinedInput-notchedOutline':{
                            display: "none",
                            borderRadius: '0px!important'
                        },
                       /* Small devices */
                       [SMALL_DEVICE_DOWN]: {
                           border :`1px solid ${COLORS.chinaRose}`,
                           borderRadius: '0px!important'
                       },
                       "& input::placeholder": {
                            color: "black",
                            fontSize:"1rem",
                            fontWeight:"500",
                       },
                       
                       "& .Mui-focused":{
                           border: `1px solid ${COLORS.chinaRose}`,
                           borderRadius: '0px!important'
                       },
                       "&:hover":{
                           border: `1px solid ${COLORS.chinaRose}`,
                           '& .MuiOutlinedInput-notchedOutline':{
                               display: "none"
                           }
                       },
                       "&.no-border-inactive":{
                            border:`1px solid ${COLORS.white} !important`
                       }
                   },
                   "&.border-gold":{
                        [SMALL_DEVICE_DOWN]: {
                            border :`1px solid ${COLORS.aztecGold}`,
                        },
                        "& .Mui-focused":{
                            borderColor: COLORS.aztecGold
                        },
                        "&:hover":{
                            border: `1px solid ${COLORS.aztecGold}`,
                            '& .MuiOutlinedInput-notchedOutline':{
                                display: "none"
                            }
                        },
                        "&:hover .Mui-focused":{
                            borderColor: COLORS.aztecGold
                        }
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins',
                    fontWeight: '700',
                    fontSize: '0.875rem', // 14px
                    margin: '0px',
                    marginTop: '0.5625rem', // 9px
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    marginRight: '1.1875rem', // 19px,
                    '& path': {
                        color: COLORS.gray,
                    }
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    textTransform: 'uppercase',
                    boxShadow: 'none'
                },
                outlined: {
                    borderWidth: 3,
                    ":hover": {
                        borderWidth: 3,
                    }
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: palette.secondary.main,
                        color: palette.secondary.main
                    }
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: palette.primary.main,
                        color: palette.primary.main
                    }
                }
            }
        },
        MuiSwitch:{
            defaultProps:{
                disableRipple: true
            },
            styleOverrides:{
                root:{
                    width:'100%',
                    padding: '7px',
                    paddingLeft:'0px',
                    '& .MuiSwitch-switchBase':{
                        margin:'5px',
                        marginTop:'4.55px',
                        padding:'0px',
                    },
                   
                    '& .MuiSwitch-thumb': {
                        position:'relative',
                        boxShadow: 'none',
                        width: '12px',
                        height: '12px',
                        margin: '5px',
                        color: COLORS.gray,
                        left: '-6.23px'
                      },
                    '& .Mui-checked > .MuiSwitch-thumb':{
                        color: COLORS.aztecGold,
                        left: '-14px'
                      },
                    '& .MuiSwitch-track':{
                        border:`2px solid ${COLORS.gray}`,
                        backgroundColor:'white !important',
                        borderRadius:'28px',
                        height:'17.5px',
                        width:'31.68px',
                    },
                    '& .Mui-checked ~ .MuiSwitch-track':{
                        border:`2px solid ${COLORS.aztecGold}`,
                    }

                }
            }
        }
    },
}

import { useMemo } from "react"

export const useContainerSwiperPaginationType = () => {

    return useMemo(() => {
        return [
            { value: 'bullets', label: 'Points' },
            { value: 'fraction', label: 'Fraction' },
            { value: 'progressbar', label: 'Barre de progression' },
        ]
    }, [])
}


import { TNotificationPayloadUpdate } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useNotificationsServices } from "./useNotificationsServices";

export const useNotificationUpdate = () => {
    const service = useNotificationsServices()
    return useEphattaMutation<TNotificationPayloadUpdate, any, any>(
        (payload: TNotificationPayloadUpdate) => service.update(payload),
    )
}
import { useToolboxCards } from "./useToolboxCards"
import { useToolboxContainer } from "./useToolboxContainer"
import { useToolboxImages } from "./useToolboxImages"
import { useToolbarTitles } from "./useToolboxTitles"
import { useToolboxVideos } from "./useToolboxVideos"

export const useToolbox = (): Array<ToolboxItemProps> => {

    const containers = useToolboxContainer()
    const titles = useToolbarTitles()
    const cards = useToolboxCards()
    const images = useToolboxImages()
    const videos = useToolboxVideos()

    return [
        ...containers,
        { label: '', isDivider: true },
        ...titles,
        { label: '', isDivider: true },
        ...cards,
        { label: '', isDivider: true },
        ...images,
        { label: '', isDivider: true },
        ...videos
    ]
}

export type ToolboxItemProps = {
    label: string
    icon?: React.ReactElement
    isDivider?: boolean
    element?: any
    elementProps?: any
    [key: string]: any
}

import { useEphattaQuery } from "../../.."
import { useThematicsService } from "../useThematicsService"

export const useGetHomeThematics = () => {
    const services = useThematicsService()
  return useEphattaQuery(
    ["Get.Home.Thematics"],
    () => services.getHomeThematics(),
    {}
  )
}

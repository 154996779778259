import { Image as ImageIcon } from "@mui/icons-material"
import React, { useMemo } from "react"
import { Image } from "../components/elements/Image"
import { ToolboxItemProps } from "./useToolbox"

export const useToolboxImages = () => {

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Image',
            icon: <ImageIcon />,
            element: Image,
        },
    ]), [])
}


import {Box, Container, CssBaseline, useTheme} from "@mui/material"
import React, {Suspense, useMemo} from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { MainLoader, useUrlFilterValue, useEnv, GenericSuspenseWrapper } from "@ephatta/components";
import { Header } from "./components/Header";
import FooterSection from "./components/FooterSection";
import {COLORS} from "../../config/Colors";
import {useResponsiveContainerSize} from "../../hooks/design/useResponsiveContainerSize";
import { MainLock } from "./MainLock";

export const MainLayout = () => {

    const { login, signup, completeProfil, ...rest } = useUrlFilterValue()
    const location = useLocation()
    const navigate = useNavigate()

    // ======= PAGES WITHOUT MAX-WIDTH  ==========
    const isContainerLessMaxWidth = useMemo(() => (containerLessPageRoutes.map(
        route => location.pathname === route
    ).includes(true)), [location.pathname])
    // ======= END PAGES WITHOUT MAX-WIDTH =======

    const { bodyContainerHeight } = useResponsiveContainerSize();

    const handleZoomInFinished = () => {
        navigate('/home')
    }

    if (location.pathname === '/') {
        return (
            <MainLock onZoomInFinished={handleZoomInFinished} />
        )
    }

    return (
        <>
           <CssBaseline />
            <Header />
            <Container
                maxWidth={isContainerLessMaxWidth ?  false : 'xl'}
                style={{
                    paddingLeft: isContainerLessMaxWidth ? '0px' : undefined,
                    paddingRight: isContainerLessMaxWidth ? '0px' : undefined,
                }}
                sx={{
                    minHeight: bodyContainerHeight,
                }}
            >

                <GenericSuspenseWrapper fallback={<MainLoader />}>
                    <Outlet />
                </GenericSuspenseWrapper>
            </Container>

            <Box
                sx={{
                    backgroundColor: COLORS.aztecGold,
                    position: 'relative',
                    zIndex: 99
                }}
            >
                <FooterSection/>
            </Box>
        </>
    );
}

const containerLessPageRoutes = [
    '/',
    '/home',
    '/login',
    '/registration',
    '/register-step-one',
    '/register-step-two',
    '/register-step-three',
    '/email-sent',
    '/forgot-password',
    '/reset-password',
    '/subscribe-step-one',
    '/subscribe-step-two',
    '/thematiques',
    '/subscribe',
    '/subscription',
    '/faq'
]

import { ThemeOptions } from "@mui/material"

export const useCraftEditorTheme = (): ThemeOptions => {

    return {
        typography: {
            h1: {
                fontSize: '2.2em',
            },
            h2: {
                fontSize: '1.8em',
            },
            h3: {
                fontSize: '1.4em',
            },
            body1: {
                fontSize: '1em',
            },
            body2: {
                fontSize: '0.8em',
            }
        }
    }
}

import { ButtonProps } from "@mui/material"
import { useMemo } from "react"

export const useButtonColors = () => {

    return useMemo((): Array<Props> => ([
        {
            label: 'Erreur',
            color: 'error'
        },
        {
            label: 'Info',
            color: 'info'
        },
        {
            label: 'Primaire',
            color: 'primary'
        },
        {
            label: 'Secondaire',
            color: 'secondary'
        },
        {
            label: 'Warning',
            color: 'warning'
        },
    ]), [])
}


type Props = {
    label: string
    color: ButtonProps['color']
}


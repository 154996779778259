import {COLORS} from "../../config/Colors";
import {Divider, SxProps} from "@mui/material";
import {usePixelToRem} from "../../hooks/design/usePixelToRem";

const CustomDivider = (props: { width?: string | number, height?: string | number, color?: string, dashed?: boolean, sx?: SxProps }) => {

    const { pixelToRem } = usePixelToRem();

    return (
        <Divider sx={{
            border: 'none',
            width: props.width || '100%',
            backgroundColor: props.dashed ? 'transparent' : (props.color || COLORS.black),
            height: props.height || pixelToRem(1),
            borderTop: props.dashed ? `1px dashed ${props.color || COLORS.black}` : 'none',
            strokeDasharray: props.dashed ? '70' : 'none',
            ...props?.sx
        }}/>
    )
};


CustomDivider.defaultProps = {
    dashed: false
}

export default CustomDivider;

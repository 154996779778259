import React from "react"
import { Editor, Options as EditorProps , Frame as FrameProps } from "@craftjs/core"
import { Box, ThemeOptions } from "@mui/material"
import { CraftEditorDesigner } from "./CraftEditorDesigner"
import { useElementResolver } from "./hooks/useElementResolver"
import { Toolbar, ToolbarProps } from "./components/toolbar/Toolbar"
import { RenderNode } from "./components/elements/RenderNode"
import './css/override.css'

export const CraftEditor = ({ header, footer, json, data, hideToolbar, onSave, isSaving, enabled, theme, showViewBtn = true , showSaveBtn = true , ...props }: CraftEditorProps) => {

    const resolver = useElementResolver()

    return (
        <Box className="page-container" width="100%">
            <Editor {...props} resolver={resolver} onRender={RenderNode} >
                
                { header }

                {
                    !hideToolbar &&
                    <Box width="100%" display="flex" bgcolor="secondary.light" mb={3} position="sticky" pt={2} pb={2} top={60} zIndex={99}>
                        <Toolbar onSave={onSave} isSaving={isSaving} showSaveBtn={showSaveBtn} showViewBtn={showViewBtn} />
                    </Box>
                }
                
                <CraftEditorDesigner enabled={enabled}  json={json} data={data} />

                { footer }
            </Editor>
        </Box>
    )
}

export type CraftEditorProps = Partial<EditorProps> & FrameProps & ToolbarProps & {
    theme?: ThemeOptions
    header?: React.ReactNode
    hideToolbar?: boolean
    footer?: React.ReactNode
    showViewBtn?:boolean,
    showSaveBtn?:boolean,
}


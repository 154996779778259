import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const PaymentMethod = React.lazy(() => import('./PaymentMethod'))
const AddCard = React.lazy(() => import('./AddCard'))
const Routes: EphattaRouteProps[] = [
    {
        path: '/payment-method',
        element: <PaymentMethod />,
    },
    {
        path: '/add-card',
        element: <AddCard />,
    },
]

export default Routes

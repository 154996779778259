import React, { useCallback } from "react";
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@mui/material"
import { Container, ContainerProps } from "./Container"
import { ButtonProperty } from "../property/ButtonProperty";
import { Text } from "./Text";
import { useEditor, useNode } from "@craftjs/core";
import { useBreakpointMatch } from "components/craft-editor/hooks/useBreakpointMatch";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { useRedirectHard } from "hooks";
import { useGetSquareProperty } from "components/craft-editor/hooks/useGetSquareProperty";

export const Button = (props: ButtonProps) => {

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled 
    }));

    const {
        setProp
    } = useNode();

    const { label, url, targetBlank, button } = useBreakpointMatch<ButtonProps>(props)
    const redirect = useRedirectHard()
    const getSquareProperty = useGetSquareProperty()

    const handleClick = () => {
        if (!enabled) {
            url && redirect(url, targetBlank)
            button.url && redirect(button.url, button.targetBlank)
        }
    }

    const handleTextChange = useCallback((value?: any) => {
        setProp((props: any) => {
            if (props.button === undefined) {
                props.button = {}
            }
    
            props.button.label = value
            return props
        })
    }, [setProp])

    return (
        <Container container={props.container}>
            <MUIButton 
                size="small" 
                variant="contained" 
                onClick={handleClick} 
                color="primary" 
                {...{...props, ...button}}
                sx={{
                    margin: button?.margin ? getSquareProperty(button.margin) : undefined,
                    padding: button?.padding ? getSquareProperty(button.padding) : undefined,
                    borderRadius: button?.borderRadius ? getSquareProperty(button.borderRadius) : undefined,
                    ...button?.sx
                }}>
                <Text 
                    draggable={false} 
                    text={button?.label || label || 'Bouton'} 
                    variant="inherit" 
                    onChange={(value) => handleTextChange(value)} 
                    />
            </MUIButton>
        </Container>
    )
}

export const ButtonDefaultProps = {
    bouton: {
        label: 'Bouton',
        color: 'primary',
        variant: 'contained',
        url: '',
        targetBlank: false,
    },
    container: {
        borderRadius: 0,
        elevation: 0
    }
}

Button.craft = {
    displayName: 'Button',
    props: {
        button: ButtonDefaultProps
    },
    related: {
        toolbar: ButtonProperty,
        breakpoint: BreakpointProperty
    },
};

export type ButtonProps = {
    /**
     * @deprecated use "button.label" instead
     */
    label?: string
    /**
     * @deprecated use "button.url" instead
     */
    url?: string
    /**
     * @deprecated use "button.targetBlank" instead
     */
    targetBlank?: boolean
    button: MUIButtonProps & {
        label?: string
        url?: string
        targetBlank?: boolean
        margin?: string
        padding?: string
    }
    container?: ContainerProps['container']
}



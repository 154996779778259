import { Theme, Modal, Typography, Button, Box, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from 'react'
import GoldenLargeButton from "../../../pages/user/components/GoldenLargeButton";
import { usePixelToRem } from "../../../hooks/design/usePixelToRem";
import { useTheme } from "@mui/styles";
import { COLORS } from "../../../config/Colors";
import {CloseModalIcon} from "../../../components/icons/basic/unclassified";
import {CustomStandardButton} from "../../standard/button/CustomStandardButton";
import { useEnv } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";
import { useNavigate } from "react-router-dom";
export const ContactModal = (props: Props) => {
    const env = useEnv<TClientIdentity | null>();
    const navigate = useNavigate();
    const theme = useTheme();
    const classes = useStyle();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if(env?.userId == undefined) {
            setOpen(true)
        }else {
            navigate('/conversation/marie')
        }
    }
        
    const handleClose = () => setOpen(false);
    const handleRedirectButton = () => {
        navigate('/login?redirectUrl=/conversation/marie');
        setOpen(false);
    };
    const { pixelToRem } = usePixelToRem();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div>
             <CustomStandardButton
                                            onClick={handleOpen}
                                            height={{desktop: pixelToRem(38), mobile: pixelToRem(27)}}
                                            fontWeight={{desktop: '500', mobile: '500'}}
                                            fullWidth={true}
                                            variant={'contained'}
                                            buttonColor={{
                                                bg: {
                                                    normal: COLORS.white,
                                                    hover: COLORS.chinaRose,
                                                },
                                                textColor: {
                                                    normal: COLORS.chinaRose,
                                                    hover: COLORS.white,
                                                },
                                                noFillSVGPathOnNormal: true,
                                                noFillSVGPathOnHover: true,
                                            }}
                                            sx={{
                                                '&:hover':{
                                                    boxShadow: 'none',
                                                    '& .MuiTypography-root':{
                                                        color: COLORS.white
                                                    }
                                                }
                                            }}
                                        >
                                            
                                            <Typography variant='subtitle1' 
                                                fontWeight={"500"}
                                                sx={{                                             
                                                    color:{ xs:COLORS.aztecGold , sm: COLORS.chinaRose},
                                                    '&:hover':{
                                                        color:COLORS.white,
                                                    }
                                                }}>
                                            {props.title}
                                            </Typography>
                                        </CustomStandardButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                
                <Box className={isMediumDown? classes.btnStyleMobile: classes.btnStyleDesktop }>
                <CloseModalIcon onClick={handleClose} width={isMediumDown ? 16 : 31.65} height={isMediumDown ? 16 : 29.36} style={ isMediumDown?{position: 'absolute', top: pixelToRem(10), right: pixelToRem(12)}: {position: 'absolute', top: pixelToRem(29), right: pixelToRem(52)}}/>
                    <Typography
                        fontFamily={'Ubuntu'}
                        fontSize={isMediumDown ? pixelToRem(16) : pixelToRem(18)}
                        fontWeight={'700'}
                        textTransform={'uppercase'}
                        align={'center'}
                        color={COLORS.aztecGold}
                    >
                        Contactez-moi
                    </Typography>
                    <Typography color={COLORS.black} fontFamily={'Poppins'} fontWeight={400} fontSize={pixelToRem(14)} textAlign={'center'} letterSpacing={'0.5px'} marginTop={isMediumDown?pixelToRem(15): pixelToRem(30)} marginBottom={isMediumDown?pixelToRem(15): pixelToRem(50)}>
                    Je réponds à toutes vos questions, n'hésitez pas à me solliciter ! 
                    </Typography>

                    <Box
                        marginTop={isMediumDown ? pixelToRem(20) : pixelToRem(25)}
                        display={"flex"}
                        justifyContent={"center"}
                        onClick={handleRedirectButton}
                    >
                        <GoldenLargeButton style={isMediumDown? { marginBottom: pixelToRem(15), padding: '10.5px 0 10.5px 0' }: { marginBottom: pixelToRem(50) }}
                            width={{ desktop: pixelToRem(371), mobile: '100%' }} height={{ desktop: pixelToRem(53.5), mobile: pixelToRem(45) }} fontFamily={'Ubuntu'}>
                           Je me connecte pour contacter Marie en privé
                        </GoldenLargeButton>
                    </Box>
                    <Box>
                        <Typography fontFamily={'Poppins'}
                            fontSize={pixelToRem(14)}
                            fontWeight={'400'}
                            align={'center'}
                            color={COLORS.black}>
                            ou envoyez votre message à cette adresse email :
                        </Typography>
                        <Typography fontFamily={'Poppins'}
                            fontSize={pixelToRem(14)}
                            fontWeight={'700'}
                            align={'center'}
                            color={COLORS.chinaRose}>
                         <a href="mailto:contact@plume-dhistoire.fr" target="_blank" rel="noreferrer">contact@plume-dhistoire.fr</a>   
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

type Props = {
    img?: string
    video?: string
    title?: string
    subTitle?: string
    text?: string
    typeWriter?: boolean
    children?: React.ReactElement
};

const useStyle = makeStyles((theme: Theme) => ({
    btnStyleDesktop: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 876,
        height: 469,
        backgroundColor: 'white',
        padding: '72px 213px 72px 213px',
        borderRadius: '10px'
    },
    btnStyleMobile: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '304px',
        height: '271px',
        backgroundColor: 'white',
        padding: '10px 18px 32px 18px',
        borderRadius: '10px'
    },
}));
export default ContactModal;
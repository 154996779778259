/* eslint-disable no-extend-native */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var fnObjectToString = Object.prototype.toString;
var ArrayProto = Array.prototype;
var stringTag = '[object String]';
var numberTag = '[object Number]';
var dateTag = '[object Date]';
var arrayTag = '[object Array]';
var boolTag = '[object Boolean]';
var undef;
function isObjectLike(arg) {
    return arg != null && typeof arg === 'object'; // eslint-disable-line eqeqeq
}
function innerMapToDeepObject(target, src, options) {
    if (!ToolKit.isObject(target) || !ToolKit.isObject(src))
        return;
    var srcKeys = Object.keys(src);
    var targetKeys = Object.keys(target);
    targetKeys
        .filter(function (key) { return srcKeys.includes(key); })
        .reduce(function (obj, key) {
        if (options.transformIsoToDate === true && ToolKit.isString(src[key])) {
            var date = ToolKit.parseDate(src[key]);
            obj[key] = ToolKit.isNull(date) ? src[key] : date;
            return obj;
        }
        if (ToolKit.isNativeTypeObject(src[key]) || !isObjectLike(src[key]))
            obj[key] = src[key];
        else
            innerMapToDeepObject(obj[key], src[key], options);
        return obj;
    }, target);
}
var ToolKit = /** @class */ (function () {
    function ToolKit() {
    }
    ToolKit.isDefined = function (arg) {
        return arg !== undef && arg !== null;
    };
    ToolKit.isObject = function (arg) {
        return fnObjectToString.call(arg) === '[object Object]';
    };
    ToolKit.isString = function (arg) {
        return (typeof arg === 'string' || (!ToolKit.isArray(arg) && isObjectLike(arg) && fnObjectToString.call(arg) == stringTag)); // eslint-disable-line eqeqeq
    };
    ToolKit.isFunction = function (arg) {
        return ['[object Function]', '[object AsyncFunction]'].indexOf(fnObjectToString.call(arg)) >= 0;
    };
    ToolKit.isArray = function (arg) {
        if (Array.isArray)
            return Array.isArray(arg);
        return fnObjectToString.call(arg) === arrayTag;
    };
    ToolKit.isBoolean = function (arg) {
        return (arg === true ||
            arg === false ||
            (isObjectLike(arg) && fnObjectToString.call(arg) === boolTag));
    };
    ToolKit.isNumber = function (arg) {
        return (typeof arg === 'number' ||
            (isObjectLike(arg) && fnObjectToString.call(arg) === numberTag));
    };
    ToolKit.isValidNumber = function (arg) {
        return ToolKit.isNumber(arg) && Number.isFinite(arg);
    };
    ToolKit.isNaN = function (arg) {
        return this.isNumber(arg) && arg != +arg; // eslint-disable-line eqeqeq
    };
    ToolKit.isFloat = function (value) {
        return this.isNumber(value) && value % 1 !== 0;
    };
    ToolKit.isDate = function (arg) {
        return ((isObjectLike(arg) && fnObjectToString.call(arg) === dateTag) || false);
    };
    ToolKit.isValidDate = function (arg) {
        return ToolKit.isDate(arg) && !ToolKit.isNaN(arg.getTime());
    };
    ToolKit.hasValue = function (arg) {
        return arg !== undef && arg !== null;
    };
    ToolKit.isUndefined = function (arg) {
        return arg === undef;
    };
    ToolKit.isNull = function (arg) {
        return arg === null;
    };
    ToolKit.isUndefinedOrNull = function (arg) {
        return arg === undef || arg === null;
    };
    ToolKit.isNativeTypeObject = function (arg) {
        // isObjectLike(arg) &&
        return (ToolKit.isUndefined(arg) ||
            ToolKit.isNull(arg) ||
            ToolKit.isDate(arg) ||
            ToolKit.isBoolean(arg) ||
            ToolKit.isNumber(arg) ||
            ToolKit.isString(arg) ||
            ToolKit.isArray(arg) ||
            ToolKit.isFunction(arg));
    };
    ToolKit.isEmpty = function (arg) {
        if (!ToolKit.isString(arg) && !ToolKit.isArray(arg))
            throw new TypeError('arg is not of a valid type');
        return arg.length === 0;
    };
    ToolKit.noop = function () { };
    /**
   * Array
   */
    ToolKit.take = function (array, count, from) {
        if (from === void 0) { from = 0; }
        if (!ToolKit.isArray(array))
            throw new TypeError('array is not valid');
        if (!ToolKit.isValidNumber(count))
            throw new TypeError('count is not valid');
        if (!ToolKit.isValidNumber(from))
            throw new TypeError('from is not valid');
        return array.slice(from, from + count);
    };
    ToolKit.addRange = function (src, newElements) {
        ArrayProto.push.apply(src, newElements);
    };
    ToolKit.removeFromCollection = function (array, predicate) {
        if (!ToolKit.isArray(array))
            return false;
        if (!ToolKit.isFunction(predicate))
            return false;
        for (var idx = 0; idx < array.length;) {
            if (predicate(array[idx], idx) === true)
                array.splice(idx, 1);
            else
                idx += 1;
        }
        return true;
    };
    ToolKit.removeAt = function (array, index) {
        if (!ToolKit.isArray(array))
            return false;
        array.splice(index, 1);
        return true;
    };
    ToolKit.replaceAt = function (array, index, item) {
        if (!ToolKit.isArray(array))
            return;
        array.splice(index, 1, item);
    };
    ToolKit.replaceCollectionItem = function (array, item, predicate) {
        if (!ToolKit.isArray(array))
            return;
        if (!ToolKit.isFunction(predicate))
            return;
        var indexToReplace = array.reduce(function (acc, value, index) {
            if (predicate(value, index))
                acc.push(index);
            return acc;
        }, []);
        indexToReplace.forEach(function (index) { return array.splice(index, 1, item); });
    };
    ToolKit.clearCollection = function (collection) {
        if (ToolKit.isArray(collection))
            collection.splice(0, collection.length);
    };
    ToolKit.diffCollection = function (array, values, options) {
        var result = [];
        if (!ToolKit.isArray(array) || !ToolKit.isArray(values) || !array.length)
            return result;
        var internalOptions = options || {};
        var objectKey = internalOptions.objectKey, predicate = internalOptions.predicate, format = internalOptions.format, alternativeFormat = internalOptions.alternativeFormat;
        var comparator;
        if (ToolKit.isFunction(predicate))
            comparator = predicate;
        else
            comparator = function (item, collection) { return collection.includes(item); };
        array.forEach(function (obj) {
            var transformedValue = obj;
            if (ToolKit.isString(objectKey))
                transformedValue = transformedValue[objectKey];
            if (ToolKit.isFunction(format))
                transformedValue = format(transformedValue);
            if (comparator(transformedValue, values) === false) {
                if (ToolKit.isFunction(alternativeFormat)) {
                    transformedValue = obj;
                    if (ToolKit.isString(objectKey))
                        transformedValue = transformedValue[objectKey];
                    transformedValue = alternativeFormat(transformedValue);
                    if (comparator(transformedValue, values))
                        return;
                }
                result.push(obj);
            }
        });
        return result;
    };
    ToolKit.findIndex = function (array, predicate) {
        if (!ToolKit.isArray(array))
            return -1;
        for (var idx = 0; idx < array.length; idx++) {
            if (predicate(array[idx], idx) === true)
                return idx;
        }
        return -1;
    };
    ToolKit.find = function (array, predicate) {
        if (!ToolKit.isArray(array))
            return null;
        for (var idx = 0; idx < array.length; idx++) {
            if (predicate(array[idx], idx) === true)
                return array[idx];
        }
        return null;
    };
    ToolKit.orderBy = function (array, propertyAccessor, options) {
        if (!ToolKit.isArray(array) || !ToolKit.isString(propertyAccessor)) {
            throw new Error("".concat(ToolKit.getClassName(ToolKit), " -> ").concat(ToolKit.getClassMethodName(ToolKit, ToolKit.orderBy), ": invalid parameters."));
        }
        var internalOptions = { nullFirst: false, ascending: true };
        if (ToolKit.hasValue(options) && ToolKit.isObject(options)) {
            if (options.nullFirst === true)
                internalOptions.nullFirst = true;
            if (options.ascending === false)
                internalOptions.ascending = false;
        }
        var nullOrderValue = internalOptions.nullFirst ? -1 : 1;
        var ascOrderValue = internalOptions.ascending ? 1 : -1;
        array.sort(function (itemA, itemB) {
            var aProperty = ToolKit.getPropertySafe(itemA, propertyAccessor);
            var bProperty = ToolKit.getPropertySafe(itemB, propertyAccessor);
            if (aProperty === null)
                return nullOrderValue * 1;
            if (bProperty === null)
                return nullOrderValue * -1;
            if (aProperty < bProperty)
                return ascOrderValue * -1;
            if (aProperty > bProperty)
                return ascOrderValue * 1;
            return 0;
        });
    };
    ToolKit.countCollection = function (array, predicate) {
        if (!ToolKit.isArray(array))
            return -1;
        if (!ToolKit.isFunction(predicate))
            return array.length;
        var count = 0;
        for (var idx = 0; idx < array.length; idx++) {
            if (predicate(array[idx], idx) === true)
                count += 1;
        }
        return count;
    };
    ToolKit.isCollectionOf = function (array, instanceOf) {
        for (var idx = 0; idx < array.length; idx++) {
            if (!(array[idx] instanceof instanceOf))
                return false;
        }
        return true;
    };
    /**
   * String
   */
    ToolKit.fixedLenInteger = function (value, length) {
        return (Array(length).join('0') + value).slice(-length);
    };
    ToolKit.fixedLenString = function (value, length) {
        return (value + Array(length).join(' ')).slice(0, length);
    };
    ToolKit.toCamelCase = function (value) {
        if (!ToolKit.isString(value))
            throw new TypeError('value is not valid');
        return value
            .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
            .replace(/\s/g, '')
            .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
    };
    ToolKit.capitalize = function (value) {
        if (!ToolKit.isString(value))
            throw new TypeError('value is not valid');
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    };
    /**
   * Number
   */
    ToolKit.randomNumber = function (minValue, maxValue) {
        return Math.floor(Math.random() * maxValue + minValue);
    };
    /**
   * Date
   */
    ToolKit.dateOnly = function (date) {
        if (!ToolKit.isValidDate(date))
            throw new TypeError('date is not valid');
        return new Date(date.toDateString());
    };
    ToolKit.formatDateTime = function (date, timezone) {
        if (timezone === void 0) { timezone = 'Europe/Paris'; }
        var tzDate = date.toTimezone(timezone);
        var textDate = ToolKit.formatDate(tzDate);
        var textTime = ToolKit.formatHour(tzDate);
        return "".concat(textDate, " ").concat(textTime);
    };
    ToolKit.parseDateUTC = function (input) {
        var newUtcDate = ToolKit.parseDate(input);
        if (newUtcDate === null)
            return null;
        var newDate = new Date(newUtcDate.getTime() - newUtcDate.getTimezoneOffset() * 60 * 1000);
        // const offset = newUtcDate.getTimezoneOffset() / 60;
        // const hours = newUtcDate.getHours();
        // newDate.setHours(hours - offset);
        // console.log(newDate.toString(), offset);
        // newDate.setHours(hours);
        return newDate;
    };
    ToolKit.parseDate = function (input) {
        var iso = /(\d{2})[-\/]{1}(\d{2})[-\/]{1}(\d{4})( (\d{2}):(\d{2})[:]?(\d{2})?)?/; // eslint-disable-line no-useless-escape
        var parts = input.match(iso);
        if (ToolKit.isArray(parts)) {
            for (var idx = parts.length - 1; idx >= 0; idx--) {
                if (!ToolKit.isString(parts[idx]))
                    parts.pop();
                else
                    break;
            }
            if (parts.length === 8) {
                return new Date(parseInt(parts[3], 10), parseInt(parts[2], 10) - 1, parseInt(parts[1], 10), parseInt(parts[5], 10), parseInt(parts[6], 10), parseInt(parts[7], 10));
            }
            if (parts.length === 7) {
                return new Date(parseInt(parts[3], 10), parseInt(parts[2], 10) - 1, parseInt(parts[1], 10), parseInt(parts[5], 10), parseInt(parts[6], 10), 0);
            }
            if (parts.length === 4) {
                return new Date(parseInt(parts[3], 10), parseInt(parts[2], 10) - 1, parseInt(parts[1], 10));
            }
        }
        return null;
    };
    ToolKit.parseHour = function (input) {
        if (ToolKit.isUndefinedOrNull(input))
            return null;
        var iso = /(\d{2}):(\d{2})[:]?(\d{2})?/;
        var parts = input.match(iso);
        if (ToolKit.isArray(parts)) {
            for (var idx = parts.length - 1; idx >= 0; idx--) {
                if (!ToolKit.isString(parts[idx]))
                    parts.pop();
                else
                    break;
            }
            if (parts.length > 2) {
                var date = new Date();
                date.setHours(parseInt(parts[1], 10));
                date.setMinutes(parseInt(parts[2], 10));
                if (parts.length > 3)
                    date.setSeconds(parseInt(parts[3], 10));
                else
                    date.setSeconds(0);
                return date;
            }
        }
        return null;
    };
    ToolKit.mergeDateAndTime = function (date, time) {
        if (!ToolKit.isDate(date) || !ToolKit.isDate(time))
            return null;
        var datetime = new Date(date);
        datetime.setHours(time.getHours());
        datetime.setMinutes(time.getMinutes());
        datetime.setSeconds(time.getSeconds());
        datetime.setMilliseconds(time.getMilliseconds());
        return datetime;
    };
    ToolKit.safeParseIsoDate = function (value) {
        if (ToolKit.isString(value)) {
            var date = new Date(value);
            if (ToolKit.isValidDate(date))
                return date;
        }
        return value;
    };
    ToolKit.safeParseDate = function (value) {
        if (ToolKit.isString(value)) {
            var date = ToolKit.parseDate(value);
            if (ToolKit.isValidDate(date))
                return date;
        }
        return value;
    };
    ToolKit.safeParseUTCDate = function (value) {
        if (ToolKit.isString(value)) {
            var date = ToolKit.parseDateUTC(value);
            if (ToolKit.isValidDate(date))
                return date;
        }
        return value;
    };
    /**
   * Classes
   */
    ToolKit.getClassName = function (instance) {
        if (ToolKit.isObject(instance) && ToolKit.isFunction(instance.constructor))
            return instance.constructor.name;
        if (ToolKit.isFunction(instance))
            return instance.name;
        return null;
    };
    ToolKit.getClassMethodName = function (instance, method) {
        if (ToolKit.isUndefinedOrNull(instance) ||
            !(ToolKit.isObject(instance) || ToolKit.isFunction(instance)) ||
            !ToolKit.isFunction(method))
            return null;
        var result = null;
        __spreadArray(__spreadArray([], Object.getOwnPropertyNames(Object.getPrototypeOf(instance)), true), Object.keys(instance), true).filter(function (key, index, context) {
            return context.indexOf(key) === index &&
                !['caller', 'callee', 'arguments'].includes(key);
        })
            .some(function (key) {
            if (instance[key] === method) {
                result = key;
                return true;
            }
            return false;
        });
        return result;
    };
    ToolKit.className = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!ToolKit.isArray(args))
            return undef;
        var finalClassName = [];
        args.forEach(function (item) {
            if (ToolKit.isUndefinedOrNull(item))
                return;
            if (ToolKit.isString(item)) {
                finalClassName.push(item);
            }
            else {
                Object.keys(item).forEach(function (key) {
                    if (item[key]) {
                        finalClassName.push(key);
                    }
                });
            }
        });
        return finalClassName.length > 0 ? finalClassName.join(' ') : undef;
    };
    /**
   * Utilities
   */
    ToolKit.getObjectKeysDeep = function (object, prefix) {
        if (prefix === void 0) { prefix = ''; }
        if (ToolKit.isNativeTypeObject(object) || !isObjectLike(object))
            return [];
        var keys = [];
        var internalPrefix = prefix;
        if (internalPrefix.length > 0)
            internalPrefix += '.';
        Object.keys(object).forEach(function (prop) {
            var propName = internalPrefix + prop;
            keys.push(propName);
            if (!ToolKit.isNativeTypeObject(object[prop]) && isObjectLike(object)) {
                keys.push.apply(keys, ToolKit.getObjectKeysDeep(object[prop], propName));
            }
        });
        return keys;
    };
    ToolKit.mapToShallowObject = function (target, src, filterPredicate) {
        if (!ToolKit.isObject(target) || !ToolKit.isObject(src))
            return;
        var predicate = (function () { return true; });
        if (ToolKit.isFunction(filterPredicate))
            predicate = filterPredicate;
        Object.keys(src)
            .filter(function (key) { return Object.keys(target).includes(key); })
            .reduce(function (obj, key) {
            if (predicate(key, obj))
                obj[key] = src[key];
            return obj;
        }, target);
    };
    ToolKit.mapToDeepObject = function (target, src, options) {
        if (options === void 0) { options = {
            transformIsoToDate: false,
            strictMapping: false,
            ignoreStrictMappingWhenNull: true
        }; }
        if (!ToolKit.isObject(target) || !ToolKit.isObject(src))
            return;
        var defaultOptions = {
            transformIsoToDate: false,
            strictMapping: false,
            ignoreStrictMappingWhenNull: true
        };
        var internalOptions = options || defaultOptions;
        if (ToolKit.isUndefinedOrNull(options.transformIsoToDate)) {
            internalOptions.transformIsoToDate = defaultOptions.transformIsoToDate;
        }
        if (ToolKit.isUndefinedOrNull(options.strictMapping)) {
            internalOptions.strictMapping = defaultOptions.strictMapping;
        }
        if (ToolKit.isUndefinedOrNull(options.ignoreStrictMappingWhenNull)) {
            internalOptions.ignoreStrictMappingWhenNull =
                defaultOptions.ignoreStrictMappingWhenNull;
        }
        if (internalOptions.strictMapping === true) {
            var diffOptions = internalOptions.ignoreStrictMappingWhenNull === true
                ? {
                    alternativeFormat: function (item) {
                        if (ToolKit.isString(item))
                            return item.split('.')[0];
                        return item;
                    }
                }
                : undef;
            var missingProperties = ToolKit.diffCollection(ToolKit.getObjectKeysDeep(target), ToolKit.getObjectKeysDeep(src), diffOptions);
            if (missingProperties.length > 0) {
                throw new Error("".concat(ToolKit.getClassName(ToolKit), " -> ").concat(ToolKit.getClassMethodName(ToolKit, ToolKit.mapToDeepObject), ": source object's properties doen't match the target object: ").concat(missingProperties.join(', '), "."));
            }
        }
        innerMapToDeepObject(target, src, internalOptions);
    };
    ToolKit.getPropertySafe = function (obj, propertyAccessor) {
        if (!ToolKit.isString(propertyAccessor))
            return null;
        var retValue = propertyAccessor
            .split('.')
            .reduce(function (acc, part) { return acc && acc[part]; }, obj);
        return retValue || null;
    };
    ToolKit.cast = function (arg) {
        return arg;
    };
    ToolKit.safeJsonReplacer = function (_key, value) {
        if (ToolKit.isNaN(value))
            return 'NaN';
        if (value === Infinity)
            return 'Infinity';
        if (value === -Infinity)
            return '-Infinity';
        if (ToolKit.isString(value)) {
            var matches = value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/);
            if (ToolKit.isArray(matches) && ToolKit.isDate(ToolKit.safeParseIsoDate(value))) {
                return "${DATE_".concat(value, "}"); // eslint-disable-line no-useless-escape
            }
        }
        return value;
    };
    ToolKit.safeJsonReviver = function (_key, value) {
        if (value === 'NaN')
            return NaN;
        if (value === 'Infinity')
            return Infinity;
        if (value === '-Infinity')
            return -Infinity;
        if (ToolKit.isString(value)) {
            var match = value.match(/\$\{DATE_(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)\}/);
            if (ToolKit.isArray(match) && match.length >= 2)
                return ToolKit.safeParseIsoDate(match[1]);
        }
        return value;
    };
    ToolKit.quickClone = function (arg) {
        try {
            return JSON.parse(JSON.stringify(arg, ToolKit.safeJsonReplacer), ToolKit.safeJsonReviver);
        }
        catch (error) {
            console.warn(error);
            return null;
        }
    };
    /*
     ** Objects
     */
    ToolKit.hasProperty = function (obj, prop) {
        if (!ToolKit.isObject(obj))
            throw new TypeError('obj is not valid');
        if (!ToolKit.isString(prop) && !ToolKit.isNumber(prop))
            throw new TypeError('prop is not valid');
        return Object.prototype.hasOwnProperty.call(obj, prop);
    };
    // Encapsulate the idea of passing a new object as the first parameter
    // to Object.assign to ensure we correctly copy hooks instead of mutating
    ToolKit.pureObjectAssign = function () {
        var values = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            values[_i] = arguments[_i];
        }
        if (!ToolKit.isArray(values))
            return null;
        if (values.some(function (val) { return !ToolKit.isObject(val); }))
            return null;
        return Object.assign.apply(Object, __spreadArray([{}], values, false));
    };
    ToolKit.getTimezones = function () {
        return timezones;
    };
    ToolKit.formatDateTimeUTC = function (date) {
        var textDate = ToolKit.formatDateUTC(date);
        var textTime = ToolKit.formatHourUTC(date);
        return "".concat(textDate, " ").concat(textTime);
    };
    ToolKit.formatDateUTC = function (date, customFn) {
        if (customFn === void 0) { customFn = function (year, month, day) { return "".concat(day, "/").concat(month, "/").concat(year); }; }
        if (!ToolKit.isValidDate(date))
            throw new TypeError('date is not valid');
        if (!ToolKit.isFunction(customFn))
            throw new TypeError('customFn is not valid');
        var day = ToolKit.fixedLenInteger(date.getUTCDate(), 2);
        var month = ToolKit.fixedLenInteger(date.getUTCMonth() + 1, 2);
        var year = ToolKit.fixedLenInteger(date.getUTCFullYear(), 4);
        return customFn(year, month, day);
    };
    ToolKit.formatDate = function (date, customFn) {
        if (customFn === void 0) { customFn = function (year, month, day) { return "".concat(day, "/").concat(month, "/").concat(year); }; }
        if (!ToolKit.isValidDate(date))
            throw new TypeError('date is not valid');
        if (!ToolKit.isFunction(customFn))
            throw new TypeError('customFn is not valid');
        var day = ToolKit.fixedLenInteger(date.getDate(), 2);
        var month = ToolKit.fixedLenInteger(date.getMonth() + 1, 2);
        var year = ToolKit.fixedLenInteger(date.getFullYear(), 4);
        return customFn(year, month, day);
    };
    ToolKit.formatHourUTC = function (value, customFn) {
        if (customFn === void 0) { customFn = function (hour, minute, second) { return "".concat(hour, ":").concat(minute, ":").concat(second); }; }
        if (ToolKit.isUndefinedOrNull(value))
            throw new TypeError('value is not valid');
        if (!ToolKit.isFunction(customFn))
            throw new TypeError('customFn is not valid');
        var hour;
        var minute;
        var second;
        if (ToolKit.isDate(value) && ToolKit.isValidDate(value)) {
            hour = value.getUTCHours();
            minute = value.getUTCMinutes();
            second = value.getUTCSeconds();
        }
        if (ToolKit.isNumber(value) && !ToolKit.isNaN(value)) {
            hour = Math.floor(value / 3600);
            minute = Math.floor((value - hour * 3600) / 60);
            second = value - hour * 3600 - minute * 60;
        }
        if (ToolKit.isNumber(hour) && ToolKit.isNumber(minute) && ToolKit.isNumber(second)) {
            hour = ToolKit.fixedLenInteger(hour, 2);
            minute = ToolKit.fixedLenInteger(minute, 2);
            second = ToolKit.fixedLenInteger(second, 2);
            return customFn(hour, minute, second);
        }
        throw new TypeError('type not supported');
    };
    ToolKit.formatHour = function (value, customFn) {
        if (customFn === void 0) { customFn = function (hour, minute, second) { return "".concat(hour, ":").concat(minute, ":").concat(second); }; }
        if (ToolKit.isUndefinedOrNull(value))
            throw new TypeError('value is not valid');
        if (!ToolKit.isFunction(customFn))
            throw new TypeError('customFn is not valid');
        var hour;
        var minute;
        var second;
        if (ToolKit.isDate(value) && ToolKit.isValidDate(value)) {
            hour = value.getHours();
            minute = value.getMinutes();
            second = value.getSeconds();
        }
        if (ToolKit.isNumber(value) && !ToolKit.isNaN(value)) {
            hour = Math.floor(value / 3600);
            minute = Math.floor((value - hour * 3600) / 60);
            second = value - hour * 3600 - minute * 60;
        }
        if (ToolKit.isNumber(hour) && ToolKit.isNumber(minute) && ToolKit.isNumber(second)) {
            hour = ToolKit.fixedLenInteger(hour, 2);
            minute = ToolKit.fixedLenInteger(minute, 2);
            second = ToolKit.fixedLenInteger(second, 2);
            return customFn(hour, minute, second);
        }
        throw new TypeError('type not supported');
    };
    return ToolKit;
}());
export default ToolKit;
var timezones = {
    'Europe/Paris': {
        utc: 60,
        dst: 120,
        code: 'FR'
    },
    'Europe/London': {
        utc: 0,
        dst: 60,
        code: 'GB'
    }
};
Date.prototype.updateTime = Date.prototype.updateTime ||
    function updateTime(time) {
        if (!ToolKit.isValidDate(this))
            return;
        this.setHours(time.getHours());
        this.setMinutes(time.getMinutes());
        this.setSeconds(time.getSeconds());
        this.setMilliseconds(time.getMilliseconds());
    };
Date.prototype.updateDate = Date.prototype.updateDate ||
    function updateDate(date) {
        if (!ToolKit.isValidDate(this))
            return;
        this.setFullYear(date.getFullYear());
        this.setMonth(date.getMonth());
        this.setDate(date.getDate());
    };
Date.prototype.stdTimezoneOffset = Date.prototype.stdTimezoneOffset ||
    function stdTimezoneOffset() {
        var jan = new Date(this.getFullYear(), 0, 1);
        var jul = new Date(this.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    };
Date.prototype.isDstObserved = Date.prototype.isDstObserved ||
    function isDstObserved() {
        var currentTimezoneOffset = this.getTimezoneOffset();
        var stdTimezoneOffset = this.stdTimezoneOffset();
        return currentTimezoneOffset < stdTimezoneOffset;
    };
Date.prototype.toTimezone = Date.prototype.toTimezone ||
    function toTimezone(timezone) {
        if (!Object.keys(timezones).some(function (key) { return key === timezone; })) {
            throw new Error("Timezone ".concat(timezone, " not found"));
        }
        var timezoneInfo = timezones[timezone];
        var isDst = this.isDstObserved();
        var utcOffset = this.getTimezoneOffset();
        var offset = isDst ? timezoneInfo.dst : timezoneInfo.utc;
        if (Math.abs(utcOffset) === Math.abs(offset)) {
            return new Date(this.getTime());
        }
        // convert date to UTC
        var utc = this.getTime() + (utcOffset * 60000);
        // convert UTC to timezone
        var newDate = new Date(utc + (60000 * offset));
        return newDate;
    };
export var removeFromCollection = function (array, predicate) {
    if (!Array.isArray(array))
        return false;
    if (!ToolKit.isFunction(predicate))
        return false;
    for (var idx = 0; idx < array.length;) {
        if (predicate(array[idx], idx) === true)
            array.splice(idx, 1);
        else
            idx += 1;
    }
    return true;
};
var Observer = /** @class */ (function () {
    function Observer() {
        this.subscribers = [];
    }
    Observer.prototype.subscribe = function (callback) {
        var _this = this;
        this.subscribers.push(callback);
        return function () {
            removeFromCollection(_this.subscribers, function (sub) { return sub === callback; });
        };
    };
    Observer.prototype.notify = function (data) {
        this.subscribers.forEach(function (sub) { return sub(data); });
    };
    return Observer;
}());
export { Observer };
/*
** Global state hook constructor
** > [Optional] initial value for the state
** => Returns the hook to manage the state
**
** Manage global state
** > value that will update the state depending on the memoize of this value
** > dependencies to update the memoized value
** => Returns an array that can be destructured : [value, setState] (such as useState API)
*/

import React, { useState, useEffect } from "react"
import { Box, Button, Slider, Typography } from "@mui/material"
import Cropper, { CropperProps } from "react-easy-crop"
import { useTranslations } from "hooks"
import { RotateLeftRounded, RotateRightRounded } from "@mui/icons-material"
import { useGetCroppedImage } from "./hooks/useGetCroppedImage"
import { Area } from "react-easy-crop/types"

export const PictureCropper = ({ value: _value, defaultValue, onSave, ...props }: PictureCropperProps) => {

    const [
        ZOOM,
        ROTATE,
        SAVE
    ] = useTranslations(i18n)

    const [value, setValue] = useState<string | undefined>(_value || defaultValue)

    useEffect(() => {
        if (_value !== value) {
            setValue(_value)
        }
    }, [_value])

    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)

    const cropImage = useGetCroppedImage()

    const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleCropSave = async () => {
        try {
            const imageCropped = await cropImage(value, croppedAreaPixels, rotation)
            onSave?.(imageCropped)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box position="relative" height={320}>
                <Cropper
                    image={value}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    showGrid={false}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={handleCropComplete}
                    onZoomChange={setZoom}
                    />
            </Box>

            <Box mt={3}>
                <Typography variant="overline">{ ZOOM }</Typography>

                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    onChange={(e, value) => setZoom(value as number)}
                    />
            </Box>

            <Box mt={3}>
                <Typography variant="overline">{ ROTATE }</Typography>

                <Box display="flex">
                    <Button
                        variant="contained"
                        onClick={() => setRotation(rotation - 90)}>
                        <RotateLeftRounded />
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setRotation(rotation + 90)}>
                        <RotateRightRounded />
                    </Button>
                </Box>
            </Box>

            <Box mt={3}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCropSave}>
                    { SAVE }
                </Button>
            </Box>
        </Box>
    )
}

PictureCropper.defaultProps = {
    cropShape: 'round'
}

const i18n = [
    'Generic.PictureCropper.Zoom.label',
    'Generic.PictureCropper.Rotate.label',
    'Generic.PictureCropper.Save.label'
]

export type PictureCropperProps = Partial<CropperProps> & {
    value?: string
    defaultValue?: string
    onSave?: (value?: string) => void
}


import { Avatar, Box, Container, Stack, Typography, useMediaQuery, List, ListItem, Button, MenuItem, Menu, Theme } from "@mui/material";
import { COLORS } from "../../../config/Colors";
import { usePixelToRem } from "../../../hooks/design/usePixelToRem";
import { CabinetSecretLogoWithText } from "../../icons/logo/cabinet-secret-logo-icon";
import LinkCustomed from "../../standard/link/LinkCustomed";
import { HamburgerIcon, MailIcon, SearchIcon, CloseIcon, DownloadIcon } from "../../icons/basic/unclassified";
import { memo, useState, useEffect, useMemo } from "react";
import { useTheme } from "@mui/styles";
import MobileContainer from "../../../pages/home/components/MobileContainer";
import DrawerMenuMobile from "./header-extra/DrawerMenuMobile";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles"
import SearchBarFullWidth from '../../standard/input/SearchBarFullWidth'
import { useEnv } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";
import CustomDivider from "../../standard/CustomDivider";
import * as serviceWorker from '../../../serviceWorker';
export const Header = () => {
    const { pixelToRem } = usePixelToRem();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isSearching, setSearching] = useState(false);

    // ===== MEDIA QUERY =========
    const theme = useTheme();
    const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
    // ===== END MEDIA QUERY =====

    const env = useEnv<TClientIdentity>()
    const classes = useStyles()
    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate();
 

    const ResponsiveLogo = memo(() => (<CabinetSecretLogoWithText
        svgProps={{
            width: isSmallDown ? pixelToRem(78) : pixelToRem(68),
            height: isSmallDown ? pixelToRem(80) : pixelToRem(70),
        }}
        textProps={{
            spaceRelativeToTheLogo: {
                desktop: pixelToRem(20.07),
                mobile: pixelToRem(20.07),
            },
            lineHeight: {
                desktop: pixelToRem(30),
                mobile: pixelToRem(30),
            },
            fontSize: {
                desktop: pixelToRem(25),
                mobile: pixelToRem(25),
            },
            color: COLORS.aztecGold,
        }}
        logoType={'light'} />));




    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [search, setSearch] = useState("");
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleRegister = (index) => {
        navigate('/registration')
        setSelectedIndex(index);
        setAnchorEl(null);
    };
    const handleLogin = (index) => {
        navigate('/login')
        setSelectedIndex(index);
        setAnchorEl(null);
    };
    const handleApps = (index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        serviceWorker.installPwa();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSearch = (e) => {
        setSearch(e)
      };
    const handleSearchClick = () => {
        if(!!(search.trim())) {
            navigate('/search?text=' + search)
        }
      };
    const searchAction = () => {
        setSearching(!isSearching)
    }

    useEffect(()=>{
        if(location.pathname.includes('/search')){
            setSearching(false)
        }
    },[location.pathname])

    const isLogged = useMemo(()=>{
        return env !== null
    },[env])

    const menuItem = useMemo(()=>{
        if(isLogged){
            return (
                <>
                   {profileMenu?.map((item, i)=>(
                            <>
                                <MenuItem
                                    key={'1'}
                                    className={classes.menuItem}
                                    onClick={() => navigate(item.link)}
                                >
                                    {item.name}
                                </MenuItem>
                                {(i+1)%3 === 0 && <Box paddingY={pixelToRem(13)} paddingX={pixelToRem(16)}><CustomDivider color={COLORS.aztecGold} /></Box>}
                            </>
                    ))}
                </>
            )
        }
        return (
            <>
                <MenuItem
                    key={'1'}
                    className={classes.menuItem}
                    onClick={(event) => handleRegister(event)}
                >
                    S’inscrire
                </MenuItem>
                <MenuItem
                    key={'1'}
                    className={classes.menuItem}
                    onClick={(event) => handleLogin(event)}
                >
                    Se connecter
                </MenuItem>
            </>
        )
    },[isLogged])
    return (
        <Box

        >
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: COLORS.white,
                    boxShadow: '0px 4px 4px rgba(202, 155, 82, 0.15)',
                    zIndex: 100
                }}>
                {
                    isMediumDown &&
                    <>
                        <DrawerMenuMobile open={openDrawer} onClose={() => setOpenDrawer(false)} />
                        <MobileContainer
                            sx={{
                                height: pixelToRem(31),
                            }}
                            style={{ height: pixelToRem(35) }}
                            noPaddingLeft={true}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{ height: '100%' }}
                            >
                                <HamburgerIcon onClick={() => { setOpenDrawer(true) }} />
                                <Box sx={{ height: '100%' }} >
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        spacing={pixelToRem(15)}
                                        style={{
                                            margin: 0,
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            right: '15px',
                                        }}
                                    >
                                        <Box className={classes.loop} onClick={() => searchAction()}>
                                            {!isSearching ? <><SearchIcon /></> : <><CloseIcon /></>}
                                        </Box>
                                        <List
                                                component="nav"
                                                aria-label="Device settings"
                                            >
                                                <ListItem
                                                    id="lock-button"
                                                    aria-haspopup="listbox"
                                                    aria-controls="lock-menu"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClickListItem}
                                                >
                                                    <Avatar
                                                        src={env?.pictureUrl  || 'src/icon/header/profil.svg'}
                                                        sx={{
                                                            width: pixelToRem(26),
                                                            height: pixelToRem(26),
                                                        }}
                                                    />
                                                </ListItem>
                                        </List>
                                        {isLogged ? <MailIcon width={30} height={20} className={'cursor-pointer'} onClick={(event) => navigate('/conversations')} /> : ''}
                                        <div>
                                            <Menu
                                                id="lock-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                className={classes.popover}
                                                MenuListProps={{
                                                    'aria-labelledby': 'lock-button',
                                                    role: 'listbox',
                                                }}
                                            >  
                                                 {menuItem}
                                                 <MenuItem
                                                    key={'1'}
                                                    onClick={(event) => handleApps(event)}
                                                >
                                                    <Button style={{ width: '206px', height: '102px', whiteSpace: 'normal', border: '1px solid #AD5068', borderRadius: '5px', color: '#AD5068', fontSize: '14px', fontWeight: 700 }}>{t('Home.Page.Footer.Button.Download.MobileApplication.Label')}</Button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </Stack>
                                </Box>
                            </Stack>
                        </MobileContainer>
                    </>
                }
                {
                    !isMediumDown &&
                    <Container
                        maxWidth={'xl'}
                        sx={{ height: pixelToRem(86) }}
                    >
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            sx={{ height: '100%' }}
                        >
                            {/* Cabinet secret logo */}
                            <Box onClick={(event) => navigate('/')} style={{ cursor: 'pointer' }}>
                                <ResponsiveLogo />
                            </Box>

                            {/* Menus */}
                            <Box sx={{
                                boxSizing: 'border-box',
                                height: '100%',
                                paddingTop: pixelToRem(42.5),
                            }}>
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={pixelToRem(79)}
                                >
                                    {
                                        menus.map((menu, index) => {
                                            return (
                                                <LinkCustomed to={menu.link} key={index}>
                                                    <Typography
                                                        variant={'subtitle2'}
                                                        className={'transition-all-3s color-burgundy-on-hover bold-on-hover'}
                                                    >
                                                        {t(menu.name)}
                                                    </Typography>
                                                </LinkCustomed>
                                            )
                                        })
                                    }
                                </Stack>
                            </Box>
                            {/* Extra buttons */}
                            <Box>
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    spacing={{xs: pixelToRem(15),md:pixelToRem(50)}}
                                >
                                    <Box className={classes.loop} onClick={() => searchAction()}>
                                        {!isSearching ? <><SearchIcon /></> : <><CloseIcon /></>}
                                    </Box>
                                    <List
                                        component="nav"
                                        aria-label="Device settings"
                                        sx={{
                                            "&.MuiList-root .MuiListItem-root":{
                                                paddingX: 0,
                                                paddinY: 0
                                            }
                                        }}
                                    >
                                        <ListItem
                                            id="lock-button"
                                            className={classes.popover}
                                            aria-haspopup="listbox"
                                            aria-controls="lock-menu"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClickListItem}
                                        >
                                            <Avatar
                                                src={env?.pictureUrl  || 'src/icon/header/profil.svg'}
                                                sx={{
                                                    width: pixelToRem(41),
                                                    height: pixelToRem(41),
                                                    border: `1px solid ${COLORS.white}`,
                                                    "&:hover": {
                                                        borderColor: COLORS.chinaRose
                                                    },
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                    {isLogged ? <LinkCustomed style={{display:'flex', alignItems:'center'}} to={'/conversations'}> <MailIcon className={classes.backgroundMail} /></LinkCustomed> : ''}
                                    <div>
                                        <Menu
                                            id="lock-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            className={classes.popover}
                                            MenuListProps={{
                                                'aria-labelledby': 'lock-button',
                                                role: 'listbox',
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center'
                                            }}
                                        > 
                                       
                                       {menuItem}
                                        <MenuItem
                                            key={'1'}
                                            onClick={(event) => handleApps(event)}
                                        >
                                            <Button sx={{
                                                width: '206px', height: '102px', whiteSpace: 'normal', border: '1px solid #AD5068',
                                                borderRadius: '5px', color: COLORS.chinaRose, fontSize: '14px', fontWeight: 700,
                                                "&:hover": { bgcolor: COLORS.chinaRose, color: 'white', "& path": { fill: "white" } },
                                                "& path": { fill: COLORS.chinaRose }, display: 'block', lineHeight: pixelToRem(16.09), padding: pixelToRem(10), gap: pixelToRem(10)
                                            }}
                                            >
                                                {t("Home.Page.Footer.Button.Download.MobileApplication.Label")}<br />
                                                <DownloadIcon height={pixelToRem(24)} width={pixelToRem(24)} />
                                            </Button>
                                        </MenuItem>
                                        </Menu>
                                    </div>
                                </Stack>
                            </Box>
                        </Stack>
                    </Container>
                }
            </Box>
            {isSearching &&
                (<Container
                    maxWidth={'xl'}
                    sx={{
                        position: 'absolute',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        left: 0,
                        right: 0,
                        zIndex: 9999
                    }}
                >
                    <Box
                        sx={{
                            px: {
                                lg: pixelToRem(22),
                                md: pixelToRem(22),
                                sm: 'auto',
                                xs: 'auto'
                            }
                        }}
                    >
                        <SearchBarFullWidth
                            searchIconProps={{
                                onClick: () => handleSearchClick(),  
                            }}
                            textFieldProps={{
                                className: "no-border-inactive",
                                onChange: (event) => handleSearch(event.target.value),
                            }}
                        />
                    </Box>
                </Container>)
            }
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    backgroundIcon: {
        '&:hover': {
            backgroundColor: '#AD5068'
        }
    },
    backgroundMail: {
        '&:hover': {
            '& path': {
                fill: '#AD5068'
            }
        }
    },
    loop: {
        border: `1px solid transparent`,
        padding: '6px 5px 0px 6px',
        "& svg":{ 
            height:"1.922rem",
            width:"1.922rem"
        },
        '&:hover': {
            borderColor: COLORS.chinaRose,
            borderRadius: '50%',
            "& path": {
                filter: "invert(39%) sepia(20%) saturate(1257%) hue-rotate(294deg) brightness(97%) contrast(95%)"
            }
        },
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]:{
            "& svg":{ 
                height:"1.125rem",
                width:"1.125rem"
            },
        }
    },
    popover: {
        '& .MuiPopover-paper': {
            boxShadow: 'none',
            border: '1px solid rgba(202, 155, 82, 0.15)',
        },
        cursor:'pointer',
        [theme.breakpoints.down('sm')]: {
            marginTop: "-0.285rem",
        }
    },
    menuItem:{
        "&.MuiButtonBase-root":{
            color: 'black', fontSize: '1.125rem', fontWeight: 400 , lineHeight: "1.5rem"
        }
    }
}))
const menus = [
    {
        name: "Home.Page.Footer.CabinetSecret.Label",
        link: '/cabinet-secret',
    },
    {
        name: "Home.Page.Footer.CabinetSecret.WhoAmI.Label",
        link: '/presentation',
    },
    {
        name: "Home.Page.Footer.Community.Testimonial.Label",
        link: '/testomies',
    },
    {
        name: "Home.Page.Footer.Community.Blog.Label",
        link: '/faq',
    }
]
const profileMenu = [
    { name: "Mon Profil", link: "/my-profile"},
    { name: "Modifier mon profil", link: "/edit-profile"},
    { name: "Mes notifications", link: "/notification"},
    { name: "Thématiques", link: "/thematiques"},
    { name: "Jeux Concours", link: "/jeux-concours"},
    { name: "Bons Plans", link: "/bons-plans"},
    { name: "Déconnexion", link: "/logout"}
]
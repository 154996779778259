import { Lock, LockOpen } from "@mui/icons-material";
import { Grid, IconButton, TextField, Typography } from "@mui/material"
import _ from "lodash";
import React, { useMemo, useState } from "react"

export const SquareProperty = ({ value, onChange }: SquarePropertyProps) => {

    const [isLocked, setIsLocked] = useState(true)
    const [top, right, bottom, left] = useMemo(() => {
        return value?.split(' ') || ['0', '0', '0', '0']
    }, [value])

    const handleChange = (value: string) => {
        onChange?.(value.split(' ').slice(0, 4).join(' '))
    }

    return (
        <>
            <Typography textAlign="right">
                <IconButton onClick={() => setIsLocked((v) => !v)}>
                    {
                        isLocked ? <Lock /> : <LockOpen />
                    }
                </IconButton>
            </Typography>
            {
                isLocked &&
                <TextField 
                    fullWidth 
                    value={value}
                    onChange={(e) => handleChange?.(e.target.value)} 
                    />
            }

            {
                !isLocked &&
                <>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                fullWidth
                                label="Haut"
                                value={top}
                                onChange={(e) => handleChange?.(`${e.target.value} ${right|| 0} ${bottom || 0} ${left|| 0}`)} 
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                fullWidth
                                label="Droite"
                                value={right}
                                onChange={(e) => handleChange?.(`${top|| 0} ${e.target.value} ${bottom || 0} ${left|| 0}`)}
                                />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                fullWidth
                                label="Bas"
                                value={bottom}
                                onChange={(e) => handleChange?.(`${top|| 0} ${right || 0} ${e.target.value} ${left|| 0}`)}
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField 
                                fullWidth
                                label="Gauche"
                                value={left}
                                onChange={(e) => handleChange?.(`${top|| 0} ${right || 0} ${bottom|| 0} ${e.target.value} `)}
                                />
                        </Grid>
                    </Grid>
                </>
            }

        </>
    )
}

export type SquarePropertyProps = {
    value: string
    onChange?: (newValue: string) => void
}

import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const HomeSearch = React.lazy(() => import('./HomeSearch'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/search',
        element: <HomeSearch />,
    }
   
]

export default Routes
import { TResetPasswordPayload } from "@ephatta/services"
import { useEphattaMutation } from "../.."
import { useLoginService } from "./useLoginService"

export const useResetPassword = () => {
    const loginService = useLoginService()

    return useEphattaMutation(
        (payload: TResetPasswordPayload) => loginService.resetPassword({
            ...payload,
            applicationId: parseInt(process.env.REACT_APP_ID!)
        })
    )
}

import { EphattaRouteProps } from '@ephatta/components'
import { MainLayout } from '../components/layout/MainLayout'
import HomeRoutes from './home/Routes'
import MessagingRoutes from './messaging/Routes'
import StaticPage from './static-page/Routes'
import UserRoutes from './user/Routes'
import SubscribeRoutes from './subscribe/Routes'
import Statistic from './statistic/Routes'
import PaymentMethod from './payment-method/Routes'
import NotificationRoutes from './notification/Routes'
import MediumContainerLayout from '../components/layout/MediumContainerLayout'
import ProfileRoutes from './my-profile/Routes'
import PayementRoutes from './payment/Routes'
import LandingPageRoutes from './landing-page/Routes'
import ThematicRoutes from './thematic/Routes'
import ReceiptRoutes from './receipt/Routes'
import FAQRoutes from './faq/Routes'
import SearchRoutes from './search/Routes'
import { StaticPageLayout } from '../components/layout/StaticPageLayout'

const Routes: EphattaRouteProps[] = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            ...HomeRoutes,
            ...MessagingRoutes,
            ...UserRoutes,
            ...PaymentMethod,
            ...Statistic,
            ...SubscribeRoutes,
            ...ProfileRoutes,
            ...LandingPageRoutes,
            ...ThematicRoutes,
            ...PayementRoutes,
            ...ReceiptRoutes,
            ...FAQRoutes,
            ...SearchRoutes,
            {
                path: '/',
                element: <MediumContainerLayout />,
                children:[
                    ...NotificationRoutes,
                ]
            }
        ]
    },
    {
        path: '/',
        element: <StaticPageLayout />,
        children: [
            ...StaticPage,
        ]
    }
]

export default Routes

import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Home = React.lazy(() => import('./ReceiptHome'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/receipt',
        element: <Home />,
    }
   
]

export default Routes
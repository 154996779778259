import React from "react";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container"
import { ImageProperty } from "../property/ImageProperty";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { useBreakpointMatch } from "components/craft-editor/hooks/useBreakpointMatch";

export const Image = (props: ImageProps) => {

    const { src, alt, title } = useBreakpointMatch<ImageProps>(props)

    return (
        <Container container={props.container}>
            <img width="100%" src={src} alt={alt === '' ? process.env.REACT_APP_NAME : alt} title={title} style={{ minHeight: 80 }} />
        </Container>
    )
}

Image.craft = {
    displayName: 'Image',
    props: {
        src: '',
        alt: process.env.REACT_APP_NAME,
        title: '',
        container: {
            ...ContainerDefaultProps,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
            alignItems: 'center'
        }
    },
    related: {
      toolbar: ImageProperty,
      breakpoint: BreakpointProperty
    },
};

export type ImageProps = {
    src: string
    alt?: string
    title?: string
    container?: ContainerProps['container']
}

import { useMemo } from "react"

export const useTextAligns = () => {

    return useMemo((): Array<TitleProps> => ([
        {
            label: 'Gauche',
            value: 'left'
        },
        {
            label: 'Centré',
            value: 'center'
        },
        {
            label: 'Droite',
            value: 'right'
        },
        {
            label: 'Justifié',
            value: 'justify'
        },
    ]), [])
}


type TitleProps = {
    label: string
    value: string
}


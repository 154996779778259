import {useTranslations} from "@ephatta/components";

const useFooterWebsiteLink = () => {

    const [
        GENERAL_TERMS_AND_CONDITIONS,
        PRIVACY_POLICY_LABEL,
        COOKIES_POLICY_LABEL,
        CABINET_SECRET_LABEL,
        WHO_AM_I_LABEL,
        THEMATICS_LABEL,
        SUBSCRIPTIONS_LABEL,
        PROFILE_LABEL,
        MY_PROFILE_LABEL,
        MY_INBOX_LABEL,
        BEST_PLANS_LABEL,
        GAMES_LABEL,
        COMMUNITY_LABEL,
        EXCHANGE_LABEL,
        TESTIMONIALS_LABEL,
        FAQ_LABEL,
    ] = useTranslations(i18nKeys)

    const FOOTER_LINKS = {
        agreementSection: [
            {
                link: '/cgv',
                text: GENERAL_TERMS_AND_CONDITIONS
            },
            {
                link: '/privacy',
                text: PRIVACY_POLICY_LABEL
            },
            {
                link: '/cookies',
                text: COOKIES_POLICY_LABEL
            }
        ],
        websiteLinks: [
            {
                title: CABINET_SECRET_LABEL,
                children: [
                    {
                        link: '/cabinet-secret',
                        text: CABINET_SECRET_LABEL.split('').map((curr, index) => index === 0 ? curr.toUpperCase() : curr.toLowerCase()).join('')
                    },
                    {
                        link: '/presentation',
                        text:  WHO_AM_I_LABEL
                    },
                    {
                        link: '/thematics',
                        text: THEMATICS_LABEL
                    },
                    {
                        link: '/subscribe',
                        text: SUBSCRIPTIONS_LABEL
                    }
                ]
            },
            {
                title: PROFILE_LABEL,
                children: [
                    {
                        link: '/my-profile',
                        text: MY_PROFILE_LABEL
                    },
                    {
                        link: '/conversations',
                        text: MY_INBOX_LABEL
                    },
                    {
                        link: '/bons-plans',
                        text: BEST_PLANS_LABEL
                    },
                    {
                        link: '/jeux-concours',
                        text: GAMES_LABEL
                    }
                ]
            },
            {
                title: COMMUNITY_LABEL,
                children: [
                    {
                        link: '/testimonies',
                        text: TESTIMONIALS_LABEL
                    },
                    {
                        link: '/faq',
                        text: FAQ_LABEL
                    },
                ]
            }
        ]
    };

    return ({
        FOOTER_LINKS
    })

}

const i18nKeys = [
    "Home.Page.Footer.Agreement.Conditions.Label",
    "Home.Page.Footer.Agreement.Privacy.Label",
    "Home.Page.Footer.Agreement.Cookies.Label",
    "Home.Page.Footer.CabinetSecret.Label",
    "Home.Page.Footer.CabinetSecret.WhoAmI.Label",
    "Home.Page.Footer.CabinetSecret.Thematic.Label",
    "Home.Page.Footer.CabinetSecret.Subscription.Label",
    "Home.Page.Footer.Profile.Label",
    "Home.Page.Footer.Profile.MyProfile.Label",
    "Home.Page.Footer.Profile.MyProfile.MyInbox",
    "Home.Page.Footer.Profile.MyProfile.BestPlan",
    "Home.Page.Footer.Profile.MyProfile.Games",
    "Home.Page.Footer.Community.Label",
    "Home.Page.Footer.Community.Exchange.Label",
    "Home.Page.Footer.Community.Testimonial.Label",
    "Home.Page.Footer.Community.Blog.Label"
]

export default useFooterWebsiteLink;
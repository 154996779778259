import { useEditor } from "@craftjs/core";

export const useEditorEnabled = () => {

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled 
    }));

    return enabled
}

import React from "react";
import { SerializedNode, useEditor } from "@craftjs/core";
import { Edit, Redo, Save, Undo, Visibility } from "@mui/icons-material"
import { Box, Button, IconButton } from "@mui/material"
import { ButtonSubmit } from "components/button";

export const Toolbar = (props: ToolbarProps) => {

    const { enabled, canUndo, canRedo, actions, query } = useEditor((state, query) => ({
        enabled: state.options.enabled,
        canUndo: query.history.canUndo(),
        canRedo: query.history.canRedo(),
    }));

    const handleSaveClick = () => {
        props.onSave?.(query.serialize(), query.getSerializedNodes())
    }

    const handleOverviewClick = () => {
        actions.setOptions((options) => (options.enabled = !enabled))
    }

    return (
        <Box display="flex" width="100%">

            {
                enabled &&
                <Box display="flex">
                    <IconButton disabled={!canUndo} onClick={() => actions.history.undo()}>
                        <Undo />
                    </IconButton>

                    <IconButton disabled={!canRedo} onClick={() => actions.history.redo()}>
                        <Redo />
                    </IconButton>
                </Box>
            }


            <Box display="flex" ml="auto">
                { props.showViewBtn &&
                    <Button
                        color="secondary"
                        variant={!enabled ? 'contained' : 'text'}
                        onClick={handleOverviewClick}
                        startIcon={enabled ? (<Visibility />) : <Edit />}>
                        {enabled ? 'Aperçue' : 'Modifier'}
                    </Button>}

                {
                    enabled && props.showSaveBtn &&
                    <ButtonSubmit
                        color="primary"
                        variant="contained"
                        isSubmit={props.isSaving}
                        onClick={handleSaveClick}
                        startIcon={(<Save />)}>
                        Sauvegarder
                    </ButtonSubmit>
                }
            </Box>
        </Box>
    )
}

export type ToolbarProps = {
    isSaving?: boolean
    showViewBtn?: boolean,
    showSaveBtn?: boolean,
    onSave?: (json: string, data?: Record<string, SerializedNode>) => void
}

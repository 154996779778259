import { useEditor, useNode } from "@craftjs/core";
import { CopyAll, Delete, DragIndicator } from "@mui/icons-material";
import { Box, IconButton, Paper, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles";
import React, { createRef, useCallback, useEffect } from "react";
import ReactDOM from 'react-dom';

export const RenderNode = ({ render, ...restProps }: Record<string, unknown>) => {

    const { id } = useNode();
    const { actions, query, isActive } = useEditor((_, query) => ({
        isActive: query.getEvent('selected').contains(id),
    }));
    const currentRef = createRef<HTMLDivElement>();
    const classes = useStyle()

    const {
        isHover,
        dom,
        moveable,
        deletable,
        node,
        parent,
        connectors: { drag },
    } = useNode((node) => ({
        isHover: node.events.hovered,
        dom: node.dom,
        name: node.data.custom.displayName || node.data.displayName,
        moveable: query.node(node.id).isDraggable(),
        deletable: query.node(node.id).isDeletable(),
        parent: node.data.parent,
        props: node.data.props,
        node: node
    }));

    useEffect(() => {
        if (dom) {
            if (isHover) {
                dom.classList.add(classes.componentHover)
            } else {
                dom.classList.remove(classes.componentHover)
            }

            if (isActive) {
                dom.classList.add(classes.componentActive)
            } else {
                dom.classList.remove(classes.componentActive)
            }
        }
    }, [dom, isActive, isHover]);

    const getPos = useCallback((dom: HTMLElement) => {
        const { top, left, right, bottom } = dom
            ? dom.getBoundingClientRect()
            : { top: 0, left: 0, bottom: 0, right: 0 };

        return {
            top: `${top > 0 ? top : bottom}px`,
            left: `${left}px`,
            right: `${right - (currentRef.current?.clientWidth || 80)}px`
        };
    }, [currentRef]);

    const scroll = useCallback(() => {

        const { current: currentDOM } = currentRef;

        if (!currentDOM) return;
        const { top, right } = getPos(dom!);
        currentDOM.style.top = top;
        currentDOM.style.left = right;
    }, [dom, getPos]);

    useEffect(() => {
        document.addEventListener('scroll', scroll);

        return () => {
            document.removeEventListener('scroll', scroll);
        };
    }, [scroll]);

    return (
        <>
            {
                (isHover || isActive)
                    ? ReactDOM.createPortal(
                        <Box
                            display="flex"
                            position="fixed"
                            bgcolor="white"
                            top={parseInt(getPos(dom!).top)}
                            left={getPos(dom!).right}
                            marginTop="-35px"
                            zIndex="900"
                            ref={currentRef}>
                            <Paper elevation={9}>
                                <IconButton onClick={() => actions.add(node, parent)}>
                                    <CopyAll />
                                </IconButton>
                                {
                                    moveable &&
                                    <IconButton
                                        ref={ref => drag(ref!)}>
                                        <DragIndicator />
                                    </IconButton>
                                }
                                {
                                    deletable &&
                                    <IconButton onClick={() => actions.delete(id)}>
                                        <Delete />
                                    </IconButton>
                                }
                            </Paper>
                        </Box>,
                        document.querySelector('.page-container')!
                    )
                    : null
            }
            {render}
        </>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    componentHover: {
        outline: `1px dashed ${theme.palette.secondary.main}`
    },

    componentActive: {
        outline: `2px solid ${theme.palette.secondary.main}`
    }
}))

import React, { useEffect } from "react";
import { Element, Frame, Frame as FrameProps, useEditor } from "@craftjs/core";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Paper, ThemeOptions, Typography, Container as MUIContainer, Breakpoint } from "@mui/material"
import { useState } from "react";
import { Toolbox } from "./components/toolbox/Toolbox";
import { Container } from "./components/elements/Container";
import { Property } from "./components/property/Property";
import { CraftEditorTheme } from "./CraftEditorTheme";
import { ScreenBreakpoint } from "./components/toolbox/ScreenBreakpoint";
import { useEditorEnabled } from "./hooks/useEditorEnabled";
import { useScreenBreakPoint } from "./hooks/useScreenBreakPoint";
import { useDebounce } from "hooks";

export const CraftEditorDesigner = ({ theme, ...props }: Props) => {

    const { actions } = useEditor();
    const enabled = useEditorEnabled()
    const [hideText, setHideText] = useState(true)
    const debounce = useDebounce()
    const [breakpoint] = useScreenBreakPoint()

    useEffect(() => {
        debounce(() => {
            actions.setOptions((options) => (options.enabled = (props.enabled !== false)))
        }, 10)
    }, [])

    const wrapContainer = (children: React.ReactElement) => {

        if (!enabled) {
            return children
        }

        return (
            <MUIContainer maxWidth={breakpoint as Breakpoint}>
                { children }
            </MUIContainer>
        )
    }

    return (
        <Box display="flex" width="100%">
            {
                enabled &&
                <Box width={hideText ? '90px' : '320px'} sx={{ transition: '0.2s'}}>
                    <Paper sx={{ padding: '10px', position: 'sticky', top: positionTop }}>
                        <Typography textAlign="center">
                            <IconButton onClick={() => setHideText((v) => !v)}>
                                {
                                    !hideText &&
                                    <ChevronLeft />
                                }

                                {
                                    hideText &&
                                    <ChevronRight />
                                }
                            </IconButton>
                            {
                                !hideText && 'Eléménts'
                            }
                        </Typography>
                        <Toolbox hideText={hideText} />
                    </Paper>
                </Box>
            }
            
            <Box flex={ !enabled ? '1' : "0.75" } maxWidth={!enabled ? 'unset' : '70%' } ml="5px">
                {
                    enabled &&
                    <Box mb={5}>
                        <Paper style={{ position: "sticky", top: positionTop, zIndex: 99 }}>
                            <ScreenBreakpoint />
                        </Paper>
                    </Box>
                }
                {
                    wrapContainer(
                        <CraftEditorTheme theme={theme}>
                            <Frame {...props}>
                                <Element 
                                    is={Container as any} 
                                    padding={30} 
                                    style={{ margin: 0 }}
                                    container={{ flexDirection: 'column', minHeight: "60vh"  }}
                                    width="100%"
                                    isHoverable={false}
                                    canvas>
                                </Element>
                            </Frame>
                        </CraftEditorTheme>
                    )
                }
            </Box>

            {
                enabled &&
                <Box flex="0.25" maxWidth="320px" ml="5px">
                    <Paper style={{ position: "sticky", top: positionTop,  maxHeight: "80vh", overflowY: "auto" }}>
                        <Box p={2}>
                            <Property />
                        </Box>
                    </Paper>
                </Box>
                
            }
        </Box>
    )
}

type Props = FrameProps & {
    theme?: ThemeOptions
    enabled?: boolean
}

const positionTop = 140

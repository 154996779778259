import React from 'react'
import { Box, Tab, Tabs } from "@mui/material";
import { useScreenBreakPoint } from "components/craft-editor/hooks/useScreenBreakPoint";
import { useScreenBreakpointOptions } from "./hooks/useScreenBreakpointOptions";

export const ScreenBreakpoint = () => {

    const options = useScreenBreakpointOptions()
    const [breakpoint, setBreakpoint] = useScreenBreakPoint()

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setBreakpoint(newValue)
    };

    return (
        <Box width="100%">
            <Tabs
                value={breakpoint || 'xl'} 
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="basic tabs example">
                {
                    options.map((item, index) => (
                        <Tab key={index} {...item} iconPosition="start" {...a11yProps(index)} />
                    ))
                }
            </Tabs>
        </Box>
    )
}

function a11yProps(index: number) {
    return {
        id: `property-breakpoint-tab-${index}`,
        'aria-controls': `property-breakpoint-tabpanel-${index}`,
    };
}

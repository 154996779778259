import { ButtonProps } from "@mui/material"
import { useMemo } from "react"

export const useButtonVariants = () => {

    return useMemo((): Array<Props> => ([
        {
            label: 'Contenu',
            variant: 'contained'
        },
        {
            label: 'Avec bordure',
            variant: 'outlined'
        },
        {
            label: 'Text',
            variant: 'text'
        },
    ]), [])
}


type Props = {
    label: string
    variant: ButtonProps['variant']
}


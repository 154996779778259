import React from "react";
import { Box } from "@mui/material"

export const PropertyItems = (props: PropertyItemsProps) => {

    return (
        <Box display="flex" flexDirection="column" justifyContent="stretch" gap="15px">
            { props.children }
        </Box>
    )
}

type PropertyItemsProps = {
    children: React.ReactNode
}

import { useEphattaQuery } from "hooks/utils"
import { useConversationServices } from ".."

export const useGetAllConversation = (userId : string) =>{
    const services = useConversationServices()
    return useEphattaQuery(
        ['Conversation.GetAll.ByUserId', userId],
        () => services.getAllConversationByUserId(userId) ,
        {enabled: !!userId}
    )
}
import React from "react";
import { ContainerProperty } from "./ContainerProperty";
import { PropertyItemTitle } from "./PropertyItemTitle";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useListTypes } from "components/craft-editor/hooks/useListTypes";
import { useNode } from "@craftjs/core";

export const ListProperty = () => {

    const {
        actions: { setProp },
        type,
    } = useNode((node) => ({
        type: node.data.props?.type,
    }));

    const types = useListTypes()

    return (
        <>
            <ContainerProperty 
                hideDirection
                hideSwiper
                />

            <PropertyItemTitle>Type</PropertyItemTitle>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="row"
                value={type}
                onChange={(e, value) => setProp((props: any) => props.type = value)}>
                {
                    types.map((item, index) => (
                        <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                    ))
                }
            </RadioGroup>
        </>
    )
}



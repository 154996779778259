import { useNode } from "@craftjs/core";
import _ from "lodash"
import { useCallback } from "react"
import { useScreenBreakPoint } from "./useScreenBreakPoint"


export const useBreakpoint = () => {

    const {
        actions: { setProp },
        props,
    } = useNode((node) => ({
        props: node.data.props,
    }));
    const [breakpoint] = useScreenBreakPoint()

    const getValue = useCallback((key: string) => {
        if (breakpoint && breakpoint !== 'xl' && _.get(props[breakpoint] || {}, key)) {
            return _.get(props[breakpoint] || {}, key)
        }
        return _.get(props, key)
    }, [breakpoint, props])

    const setValue = useCallback((key: string | SetPropFn, value?: any) => {

        setProp?.((props: any) => {
            if (breakpoint && breakpoint !== 'xl') {
                if (props[breakpoint] === undefined) {
                    props[breakpoint] = { }
                }

                if (typeof key === 'function') {
                    props[breakpoint] = key(props[breakpoint])
                    return props
                }
                
                props[breakpoint][key] = value
                return props
            }

            if (typeof key === 'function') {
                props = key(props)
                return props
            }
            
            props[key] = value
            return props
        })
    }, [breakpoint, setProp])

    return {
        getValue,
        setValue,
        setProp
    }
}

type SetPropFn = (cb: any, throttleRate?: number) => void

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'


const GeocodingSearch = ({ placeholder, currentEstablishmentAdress, onResultChange, onChange, containerId = 'geocoder-container', query, ...props }: Props) => {

    const classes = useStyle()
    const [geocoder, setGeocoder] = useState<MapboxGeocoder>()
    const [result, setResults] = useState<any>()
    
    useEffect(() => {
        setGeocoder(new MapboxGeocoder({
            accessToken: process.env.REACT_APP_MAPBOX_GL_TOKEN!,
            placeholder: currentEstablishmentAdress ? `${currentEstablishmentAdress?.street}, ${currentEstablishmentAdress?.zipCode} ${currentEstablishmentAdress?.city}` : placeholder,
            types: "country,region,place,postcode,locality,neighborhood,address",
        }))
    }, [])

    useEffect(() => {
        if (geocoder) {
            geocoder.addTo(`#${containerId}`);
            // @ts-ignore
            geocoder._inputEl.value = query || ''
            geocoder.on("result", function (e) {
                setResults(e.result);
            });
            geocoder.on("clear", function () {
                setResults(undefined);
            });
        }
    }, [geocoder])

    useEffect(() => {
        if (geocoder && query) {
            // @ts-ignore
            geocoder._inputEl.value = query || ''
        }
    }, [query])

    useEffect(() => {
        onResultChange?.(result)

        const value: GeocodingSearchResultValue = {
            address: result?.text || '',
            longitude: result?.geometry.coordinates[0] || 0,
            latitude: result?.geometry.coordinates[1] || 0,
        }
        result?.context?.forEach(function (element: any) {
            if (element.id.startsWith("postcode")) {
                value.zipCode = element.text
            }
            if (element.id.startsWith("place")) {
                value.city = element.text
            }
            if (element.id.startsWith("region")) {
                value.region = element.text
            }
            if (element.id.startsWith("country")) {
                value.country = element.text
            }
        });

        onChange?.(value)
            
    }, [result])

    return (
        <div {...props} className={`${classes.inputGeocodingComponent} ${props.className}`}>
            <div id={containerId} className="geoCodingContainer" />
        </div>
    );
};

export type GeocodingSearchResultValue = {
    address: string
    longitude: number
    latitude: number
    zipCode?: string
    city?: string
    region?: string
    country?: string
}

export default GeocodingSearch

const useStyle = makeStyles((theme) => ({
    inputGeocodingComponent: {
        '& .mapboxgl-ctrl-geocoder--icon-search, .mapboxgl-ctrl-geocoder--icon': {
            margin: '10px'
        },
        '& .mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder': {
            width: '100%',
            boxShadow: 'none',
            padding: '10px',
            background: 'transparent',
            border: '1px solid #bcbdc0',
            borderRadius: '5px',
            font: 'inherit',
            maxWidth: 'unset',
            '&::focus': {
                border: `1px solid ${theme.palette.primary.main}`
            }
        },
        '& .mapboxgl-ctrl-geocoder--input': {
            outline: 'none'
        }
    }
}))

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>  & {
    placeholder?: string
    currentEstablishmentAdress?: GeocodingAdress
    containerId?: string
    options?: Partial<MapboxGeocoder.GeocoderOptions>
    query?: string
    onResultChange?: (result?: any) => void
    onChange?: (value: GeocodingSearchResultValue) => void
}

export type GeocodingAdress = {
    street?: string
    city?: string
    country?: string
    zipCode?: number
    latitude?: number
    longitude?: number
}

import { useNode } from "@craftjs/core";
import { MenuItem, Select, TextField } from "@mui/material"
import { useBorderStyles } from "components/craft-editor/hooks/useBorderStyles";
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint";
import { ColorPicker } from "components/form";
import React, { useCallback, useEffect } from "react"
import { PropertyItemTitle } from "../property/PropertyItemTitle"
import { PropertyContainer } from "./PropertyContainer";
import { SquareProperty } from "./SquareProperty";

export const BorderProperty = ({ propName }: BorderPropertyProps) => {

    const {
        actions: { setProp },
        props,
    } = useNode((node) => ({
        props: node.data.props,
    }));

    const borderStyles = useBorderStyles()
    const { getValue, setValue } = useBreakpoint()

    useEffect(() => {

        if (!props[propName]?.border) {
            setProp((props: any) => {
                if (!props[propName]) {
                    props[propName] = {}
                }
                props[propName].border = {}
            })
        }
    }, [])

    const handleBorderChange = useCallback((key: string, value?: any) => {
        setValue((props: any) => {
            if (props[propName] === undefined) {
                props[propName] = {}
            }
    
            if (props[propName]?.border === undefined) {
                props[propName].border = {}
            }
    
            props[propName].border[key] = value
            return props
        })
        
    }, [])

    const getPropName = useCallback((name: string) => {
        return `${propName ? `${propName}.` : ''}${name}`
    }, [propName])

    return (
        <PropertyContainer title="Bordure">
            
            <PropertyItemTitle variant="body1" fontWeight="normal">Largeur</PropertyItemTitle>
            <TextField 
                fullWidth 
                value={getValue(getPropName('border.borderWidth'))}
                onChange={(e) => handleBorderChange('borderWidth', e.target.value)}
                />

            <PropertyItemTitle variant="body1" fontWeight="normal">Style</PropertyItemTitle>
            <Select
                fullWidth
                value={getValue(getPropName('border.borderStyle'))}
                onChange={(e) => handleBorderChange('borderStyle', e.target.value)}>
                {
                    borderStyles.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{ item.label }</MenuItem>
                    ))
                }
            </Select>

            <PropertyItemTitle variant="body1" fontWeight="normal">Couleur</PropertyItemTitle>
            <ColorPicker 
                color={getValue(getPropName('border.borderColor'))}
                onChange={(color) => handleBorderChange('borderColor', `${color.hex}`)}
                />

            <PropertyItemTitle variant="body1" fontWeight="normal">Rondeur du bordure</PropertyItemTitle>
            <SquareProperty
                value={getValue(getPropName('border.borderRadius'))}
                onChange={(value) => handleBorderChange('borderRadius', value)}
                />
        </PropertyContainer>
    )
}

export type BorderPropertyProps = {
    propName: string
}

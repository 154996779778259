export const COLORS = {
    aztecGold: '#CA9B52',
    chinaRose: '#AD5068',
    white: '#FFFFFF',
    black: '#111111',
    cream: '#FAF7F4',
    gray: '#9098B1',
    red: '#971033',
    grayBlue: '#EBF0FF',
    grayBlueLight: '#F2F2F2',
    redMain: '#9E0303',
    philippineGray: '#898A8D'
};

import React from "react";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container"
import YouTube from 'react-youtube';
import { VideoYoutubeProperty } from "../property/VideoYoutubeProperty";
import { makeStyles } from "@mui/styles";
import { BreakpointProperty } from "../property/BreakpointProperty";

export const VideoYoutube = ({ videoId, ...props }: VideoYoutubeProps) => {

    const classes = useStyle()

    return (
        <Container container={{
            ...props.container,
        }} padding={10} style={{ display: 'flex' }}>
            <YouTube
                videoId={videoId}
                opts={{
                    width: '100%',
                    height: '100%',
                }}
                className={classes.videoYoutubeContainer}
                containerClassName={classes.videoYoutube}
            />
        </Container>
    )
}

const useStyle = makeStyles(() => ({
    videoYoutube: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flex: 1
    },
    videoYoutubeContainer: {
        flex: 1
    }
}))

VideoYoutube.craft = {
    displayName: 'Video Youtube',
    props: {
        videoId: '',
        container: ContainerDefaultProps
    },
    related: {
        toolbar: VideoYoutubeProperty,
        breakpoint: BreakpointProperty
    },
};

export type VideoYoutubeProps = {
    videoId: string
    
    container?: ContainerProps['container']
}
